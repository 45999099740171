import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_AXIOS_LINK
    //baseURL: 'https://gestuftelernhilfen.diluna.ch'
    //baseURL: 'http://localhost:8888'
    //baseURL: 'http://diluna:8888'

});

export default instance;