import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initState = {
    userEmail: "",
    userAge: "",
    userGroup: "",
    userLoginTime: "",
    error: null,
    loading: false,
    authRedirectPath: '/',
    isSignup: false
}


const authStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });        
} 

const authSuccess = (state, action) => {
    return updateObject(state, { 
        token: action.idToken,
        userEmail: action.userEmail,
        userGroup: action.userGroup,
        error: null,
        loading: false
     });
}

const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    })
}

const prepareSignup = (state, action) => {
    return updateObject(state, {
        isSignup: true,
        loading: false
    })
}

const authLogout = (state, action) => {
    return updateObject(state, {
        token: null,
        isSignup: false,
    });
}

// const setAuthRedirectPath = (state, action) => {
//     return updateObject(state, {authRedirectPath: action.path})
// }

const reducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state,  action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.AUTH_PREPARE_SIGNUP: return prepareSignup(state, action);
        //case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state, action)
        default: return state;
    }
}

export default reducer;