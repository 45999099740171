import React, { useEffect} from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux';

import './App.sass';
import Layout from './containers/Layout/Layout';

import Auth from './containers/Auth/Auth';
import Exercises from './containers/Exercises/Exercises';
import * as actions from './store/actions'
import Logout from './containers/Auth/Logout/Logout';
import Infos from './components/Pages/Infos'
import ContactInfo from './components/Pages/ContactInfo'
import Admin from './containers/Admin/Admin'

const App = props => {

  useEffect(() => {
    const version = '3.1.82';
    if (!(localStorage.getItem('version') === version)) {
      localStorage.setItem('version', version)
      window.location.reload(true); // not supported everywhere but just in case.. 
    } else {
      console.log(`running diLuna on verison ${version}`);
    }
    props.onTryAutoSignup();  
  }, []);


  return (
    <div className="App">
      <Layout>
          <Switch>
            <Route path="/infos" component={Infos} />
            <Route path="/contact" component={ContactInfo} />
            <Route path="/admin" component={Admin} />
            <Route path="/logout" component={Logout} />
            <Route path="/login" component={Auth} exact/>
            
            {props.isAuthenticated && <Route path="/exercises" component={Exercises} />}
            {!props.isAuthenticated && <Route path="/exercises" component={Auth} />}
            
            {(localStorage.getItem('exStep') && props.isAuthenticated) &&
              <Redirect to={`/exercises`} />
            }
            <Route path="/" component={Infos} exact />
          
          </Switch>
      </Layout>
    </div>
  );
}

const mapStateToProps = state => {
  return {
      isAuthenticated: state.auth.token !== null
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
