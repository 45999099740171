import classes from './Pager.module.sass';

const Pager = (props) => {
    return (
        <div className={classes.PagerContainer + ""}>
            <div className={classes.Pager}>
                {props.prevActive &&
                <button  onClick={props.prevPage}
                    className={"btn btn-primary m-1 " + (props.pagerActive ? '' : classes.Disabled)}>
                    {props.textPrev}</button>}
                
                <button  onClick={props.nextPage}
                    className={"btn btn-primary m-1 " + (props.pagerActive ? '' : classes.Disabled)}>
                    {props.textNext}</button>
            </div>
        </div>
    )
}

export default Pager;