import React, {useState} from 'react'
import { Switch, Route } from "react-router-dom";

import Logo from '../../../Logo/Logo';
import Menu from './Menu/Menu'
import classes from './SideDrawer.module.sass';
import Backdrop from '../../../UI/Backdrop/Backdrop';



const SideDrawer = props => {

    let attachedClasses = [classes.SideDrawer, classes.Close]
    if(props.open) {
        attachedClasses = [classes.SideDrawer, classes.Open]
    }
    
    return(
        <>
            <Backdrop show={props.open} clicked={props.closed} />
            <div className={attachedClasses.join(' ')}>
                <div className={classes.Logo}>
                    <Logo />
                </div>
                <nav>
                    <Menu isAuth={props.isAuth} close={props.closed}/>
                </nav>
            </div>
        </>
    );
}

export default SideDrawer;