import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { uploadImage } from '../../../shared/api-calls'
import useFullPageLoader from '../../../hooks/useFullPageLoader'

const ImageUpload = ({path, name, onUpload}) => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const onDrop = useCallback(acceptedFiles => {
    showLoader();
    //uploadImage(acceptedFiles[0], '../public/'+path, name) // dev
    //uploadImage(acceptedFiles[0], '../'+path, name) // live
    uploadImage(acceptedFiles[0], process.env.REACT_APP_UPLOAD_PATH_LINK +path, name)
      .then(() => {
          hideLoader();
          onUpload();
      });
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    <div {...getRootProps()} className="p-2 rounded"
      style={isDragActive ? {border: '2px dashed blue'}
      :{border: '2px dashed #deded9'}}
    >
      {loader}
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Jetzt neues Bild ablegen ...</p> :
          <p>Neues Bild zum hochladen hier ablegen</p>
      }
    </div>
  )
}
export default ImageUpload
