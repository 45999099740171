import React from 'react'
import classes from './RadioRow.module.sass'

const radioOptions = [
    "stimmt gar nicht",
    "stimmt eher nicht",
    "weder/noch",
    "stimmt eher",
    "stimmt genau"
]

const RadioRow = ({question, changeRadio, selectedValue}) => {
    return (
        <div className={classes.QuestionRow}>
            {localStorage.getItem('userAuthCode').includes("debug") && <span style={{backgroundColor: '#f1f1f1',fontSize: '12px', fontWeight: 'bold'}}>DebugMode: [ {question.name} ]</span>}
            <div className={classes.QuestionText} children={question.text} />
            <div className={classes.QuestionAnswers}>
                {radioOptions.map((radio, i) => 
                    <label key={i} className="radio_container">
                        {radio}
                        <input checked={selectedValue == i} onChange={changeRadio} type="radio" name={question.name} value={i} />
                        <span className="checkmark"></span>
                    </label> 
                )}
            </div>
        </div>
    )
}

export default RadioRow