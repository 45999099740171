import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { USERCODES } from '../../data/usercodes'
import classes from './Auth.module.sass';
import Input from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import Spinner from '../../components/UI/Spinner/Spinner';
import * as actions from '../../store/actions/index';
import { checkValidity } from '../../shared/utility';
import { RiTreasureMapFill } from 'react-icons/ri';

//class Auth extends Component {
const Auth = (props) => {


    const [userForm, setUserForm] = useState({
        email: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Mail Address'
            },
            value: '',
            validation: {
                required: true,
                isEmail: true
            },
            valid: false,
            touched: false,
            signUp: false
        },
        emailConfirm: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Mail Address'
            },
            value: '',
            validation: {
                required: true,
                isEmail: true,
                isSameAs: 'email',
            },
            valid: false,
            touched: false,
            signUp: true
        },
        age: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Dein Alter'
            },
            value: '',
            validation: {
                required: true,
                minLength: 2,
                maxLength: 2,
                isNumeric: true,
                minValue: 12,
                maxValue: 99
            },
            valid: false,
            touched: false,
            signUp: true
        }, 
        gender: {
            elementType: 'select',
            elementConfig: {
                options: [
                    {value: '-', displayValue: 'bitte wählen'},
                    {value: 'w', displayValue: 'weiblich'},
                    {value: 'm', displayValue: 'männlich'},
                    {value: 'd', displayValue: 'divers'},

            ]},
            value: '-', 
            validation: {
                required: true,
                isNotValue: "-"
            },
            valid: false,
            signUp: true
        },
        authCode: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Anmeldungs-Code'
            },
            value: '',
            validation: {
                required: true,
                isToken: USERCODES
            },
            valid: false,
            touched: false,
            signUp: false
        },
        agreement: {
            elementType: 'check',
            elementConfig: {
                type: 'checkbox',
                label: 'Ich habe das Einverständnis zur Studie <strong>nicht</strong> erhalten beziehungsweise nicht gegeben.'
            },
            value: 1,
            validation: {
                required: false, 
            },
            valid: true,
            touched: false,
            signUp: true
        },
    });
    
    const [formIsValid, setFormIsValid] = useState(false);
    //const [formAuthCode, setFormAuthCode] = useState(null);


    useEffect(() => {
        setFormIsValid(false);  
    }, [props.isSignup]);

    const inputChangedHandler = (event, fieldName) => {
        let value = event.target.value;
        if(userForm[fieldName].elementType === 'check') {
            value = event.target.checked ? 0 : 1
        }
        
        let compareValue = null;
        if(userForm[fieldName].validation.isSameAs) {
            compareValue = userForm[userForm[fieldName].validation.isSameAs].value;
        }

        const updatedForm = {
            ...userForm,
            [fieldName]: {
                ...userForm[fieldName],
                value: value,
                valid: checkValidity(value, userForm[fieldName].validation, compareValue),
                touched: true
            }
        };

        let formValidCheck = true;
        for (let inputIdentifier in updatedForm) {
            if(!userForm[inputIdentifier].signUp || userForm[inputIdentifier].signUp === props.isSignup) {
                formValidCheck = updatedForm[inputIdentifier].valid && formValidCheck
            }
        }
        setFormIsValid(formValidCheck);
        setUserForm(updatedForm);
        console.log(formIsValid);
    }

    const submitHandler = (event) => {
        event.preventDefault();
        if(formIsValid) {
            if(!props.isSignup) {
                props.onAuth(userForm.email.value, userForm.authCode.value) // anmelden
            } else {
                props.onSignup(userForm.email.value, userForm.age.value, userForm.gender.value, userForm.agreement.value, userForm.authCode.value)
            }
        }
    }

    const formElementsArray = [];
    for (let key in userForm ) {
        const formVisible = !userForm[key].signUp || userForm[key].signUp === props.isSignup
    
        if(!userForm[key].signUp || userForm[key].signUp === props.isSignup) {
            formElementsArray.push({
                id: key,
                config: userForm[key]
            });
        }
    }

    let form = formElementsArray.map(formElement => (
        <Input 
            key={formElement.id}
            pasteable={false}
            elementType={formElement.config.elementType} 
            label={formElement.config.elementConfig.label}
            elementConfig={formElement.config.elementConfig} 
            value={formElement.config.value} 
            invalid={!formElement.config.valid}
            shouldValidate={formElement.config.validation}
            touched={formElement.config.touched}
            changed={(event) => inputChangedHandler(event, formElement.id)} />
    ))
    if(props.loading) {
        form = <Spinner />
    }

    let errorMessage = null;
    if(props.error) {
        errorMessage = (
        <p>{props.error.message}</p>
        )
    }
    let authRedirect = null;
    if(props.isAuthenticated) {
        authRedirect = <Redirect to="/exercises" />;
    }

    return (
        <div className={classes.Auth + " col-6 container mt-4"} >
            {authRedirect}
            {errorMessage}
            <h1>diLuna<br />Lernplattform</h1>
            
            <form onSubmit={submitHandler} className="login-form">
                {form}
                <button disabled={!formIsValid} className="btn btn-primary btn-md mt-4 float-right">
                    Anmelden 
                </button>
            </form>
        </div>
    );
}

// -> redux
const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.token !== null,
        isSignup: state.auth.isSignup
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (email, authCode) => dispatch(actions.auth(email, authCode)),
        onSignup: (email, age, gender, tracking, authCode) => dispatch(actions.signup(email, age, gender, tracking, authCode))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
