import React, {useState, useEffect} from 'react'
import fb from '../../../../../../axios-fb'
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {DiCodeBadge} from 'react-icons/di';
import {BiSave} from 'react-icons/bi';
import {parseText} from '../../../../../../shared/utility';
import ImageUpload from '../../../../ImageUpload/ImageUpload';
import ImgToChange from'./../../../../ImageUpload/ImgToChange';
import TextBlockUpdate from'./../../../../TextBlockUpdate/TextBlockUpdate';

const DataExSubStep = ({node, data, nr, category}) => {

    return (
        <div className="substep border-bottom ml-4">
            <div className="ml-4">
                <div className=""><u>Substep {nr+1}:</u></div>
                    {data && Object.keys(data).map((dt, i) => {
                        if(dt === 'text' || dt === 'hiddenText') {
                            return <TextBlockUpdate 
                                    key={i}
                                    type={dt}
                                    text={data[dt]} 
                                    //node={`${node}/${dt}`} />
                                    node={`${node}`} />
                        }
                    })}
                    <div>
                        Bilder:
                        {Object.keys(data.images).map((img, i) =>
                            <ImgToChange 
                                key={i} 
                                nr={i+1}
                                name={img} 
                                path={`${process.env.REACT_APP_FILE_FOLDER}/exercises/${category}/${data.images[img]}`}
                            />                            
                        )}
                    </div>
                </div>
            </div>
    )
}

export default DataExSubStep
