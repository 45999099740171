import React, {useState, useEffect} from 'react'
import fb from '../../../../../../axios-fb'
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {DiCodeBadge} from 'react-icons/di';
import {BiSave} from 'react-icons/bi';
import ImgToChange from '../../../../ImageUpload/ImgToChange'

const HelpContentSubSub = ({node, data, name, type, images, category}) => {
    console.log("HelpContentSubSu Node: ", node)
    const [textData, setTextData] = useState("")
    const [replaceArr, setReplaceArr] = useState([])
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [showHtml, setShowHtml] = useState(false)
    const [textChanged, setTextChanged] = useState(false)
    
    useEffect(() => {
        setTextData(data)   
        const contentBlock = htmlToDraft(parseText(data));
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState)
        }
    }, []);

    const handleTextChange = (e) => {
        setTextData(e.target.value)
    }

    const saveData = () => {  
        console.log("SAVE TO Node: ", node)  
        let saveText = reverseParseText(draftToHtml(convertToRaw(editorState.getCurrentContent())))
        saveText = saveText.replace(/\n/g, '')
        fb.patch(node+".json", {[type]: saveText})
            .then(response => {
                setTextChanged(false)
            }).catch(err => console.log("error saving text", err))
    }

    const parseText = (txt) => {
        const ownHtml = [
            {start: "<div ", end: ">", placeholder: "##DIV##"},
            {start: "</div", end: ">", placeholder: "##/DIV##"},
            {start: "<img", end: ">", placeholder: "##IMG##"},
            {start: "<figcaption", end: ">", placeholder: "##Caption##"},
            {start: "</figcaption", end: ">", placeholder: "##/Caption##"},
        ]

        const newArray = []
        for(let i=0; i<ownHtml.length; i++) {
            const start = ownHtml[i].start
            const end = ownHtml[i].end
            //let placeholder = ownHtml[i].placeholder
            var reg = new RegExp(start,"g");
            let count = (txt.match(reg) || []).length
            console.log("NewArray", newArray)
            for(let i2=0; i2<count; i2++) {
                let iStart = txt.indexOf(start);
                if(iStart != -1) {
                    let iEnd = txt.indexOf(end, iStart+1)
                    const placeholder = ownHtml[i].placeholder.replace(new RegExp('##$'), i2+'##');
                    newArray.push({
                        placeholder: "<p>"+placeholder+"</p>",
                        html: txt.substring(iStart, iEnd+1)});
                    txt = txt.substring(0, iStart) + "<p>"+placeholder+"</p>" + txt.substring(iEnd+1);
                }
            }  
        }
        setReplaceArr(newArray)
        return txt
    }

    const reverseParseText = (txt) => {
        for(let i=0; i<replaceArr.length; i++) {
            txt = txt.replace(replaceArr[i].placeholder, replaceArr[i].html)
        }
        return txt
    }

    const onEditorStateChange = (editorState) => {
        setTextChanged(true)
        setEditorState(editorState)
    };

    const onShowHtml = () => {
        setTextData(reverseParseText(draftToHtml(convertToRaw(editorState.getCurrentContent()))))
        setShowHtml(!showHtml)
    }

    return (
        <div className="substep border-bottom ml-4 mt-2">
            <div className=""><u>{name}:</u></div>
                <div className="ml-4">
                    <div>
                        Angezeigter Text: <DiCodeBadge onClick={onShowHtml}/>
                        {showHtml && <textarea 
                            className="form-control" style={{height: '150px'}}  
                            onChange={handleTextChange} 
                            value={textData}>
                        </textarea>}
                        <Editor
                            //toolbarHidden
                            editorState={editorState}
                            toolbar= {{
                                options: [
                                    'inline', 'fontSize', 'colorPicker', 'list', 'link', 'history'
                                ]
                            }}
                            toolbarClassName="editorToolbar"
                            wrapperClassName="editorWrapper"
                            editorClassName="editorClass"
                            onEditorStateChange={onEditorStateChange}
                        />
                    </div>
                    <div>
                    Bilder:
                    {/* {images && Object.keys(images).map((img, i) =>
                        <div key={i} className="d-flex">
                            <div className="mr-2">{i+1}.</div>
                            <div className="mr-4">Name: {img}</div>
                            <div className="mr-4">Pfad: {images[img]}</div>
                        </div>
                    )} */}
                    {console.log("Help center img:: ", node, data, name, type, images)}
                    {images && Object.keys(images).map((img, i) =>
                            <ImgToChange 
                                key={i} 
                                nr={i+1}
                                name={img} 
                                path={`${process.env.REACT_APP_FILE_FOLDER}/exercises/help_img/${category}/${images[img]}`}
                            />                            
                        )}
                    </div>
                    </div>
                    <button 
                        className="btn btn-secondary btn-sm "  
                        onClick={saveData}>
                        {textChanged && <BiSave/> }Save
                    </button>
            </div>
    )
}

export default HelpContentSubSub
