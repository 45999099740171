import React from 'react'
import Input from '../../../components/UI/Input/Input'

const AdminAuth = (props) => {
    return (
        <div className="row d-flex align-items-center">
            <div className="col-6">
                <Input elementType="input" changed={props.changed} value={props.authInput}  elementConfig={{placeholder: "Admin Passwort"}}/>
                <button onClick={props.onAuth} className="btn btn-primary btn-md mt-4 float-right">Verifizieren</button>
            </div>
        </div>
    )
}

export default AdminAuth
