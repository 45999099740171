import React, {useState, useEffect} from 'react'

const Questions = ({data, substep, changeStep}) => {
    const [inputData, setInputData] = useState({})
    const [allTouched, setAllTouched] = useState(false)

    useEffect(() => {
        setInputData(null)
        setAllTouched(false)
    }, [data]);

    useEffect(() => {
        if(inputData) {
            if(Object?.keys(inputData).length === data?.questions.length) {
               setAllTouched(true)
            }
        }
    }, [inputData]);

    const changeRadio = (e) => {
        const newInputData = {...inputData, [e.target.name]: e.target.value}
        setInputData(newInputData)
    }

    return (
        <div>
            {substep === 0 && 
                <div style={{marginBottom: '20px'}} dangerouslySetInnerHTML={{__html: data?.description}}></div>
            }
            {data?.questions.map((q, i) => (
                <div key={i} style={{marginBottom: '40px'}}>
                    {localStorage.getItem('userAuthCode').includes("debug") && <span style={{backgroundColor: '#f1f1f1',fontSize: '12px', fontWeight: 'bold'}}>DebugMode: [ {q.name} ]</span>}
                    <strong style={{float: 'left', fontSize:'20px', lineHeight:'30px', marginRight: '10px', marginTop: '5px'}}>{i+1}</strong>
                    <div dangerouslySetInnerHTML={{__html: q.text}}></div>
                    {q.answers.map((a, i) => 
                        <label key={i} className="radio_container">
                            {a.text}
                            <input checked={inputData && inputData[q.name] == i+1} type="radio" name={q.name} value={a.correct} onChange={changeRadio}/>
                            <span className="checkmark"></span>
                        </label> 
                    )}
                </div>
            ))}
            <button 
                disabled={localStorage.getItem('userAuthCode').includes("debug") ? false: !allTouched} 
                className="btn btn-secondary float-right" onClick={() => changeStep(inputData)}>Weiter</button>
        </div>
    )
}

export default Questions
