import React from 'react'
import { find } from 'lodash';
import classes from './PlanningTable.module.sass';
import { MdAddCircleOutline, MdRemoveCircleOutline } from 'react-icons/md';
import Tooltip from '@material-ui/core/Tooltip';

const PlanningTable = (props) => {
    const selectText = (e) => {
        e.target.select();
    }

    let optionSetupData = Object.keys(props.planningData.vars).map(key=> {
        const varData = props.planningData.vars[key]
        const optionData = find(props.planningData.options, function(o) { return (o.types.includes(key)) });
        const newVarObj = {
            type: key,
            group: varData.group,
            title: varData.title,
            units: [...optionData.units],
            values: {...optionData.values}
        }
        return newVarObj;
    })

    let repCount = [classes.InputStatus]

    let definitionRenderArray = [[],[],[]];
    props.planVariables.map(pv => {
        const change = 'type';
        const id = pv.id;
        
        let jsxContent = (
            <div key={id} className="row">
                {!props.displayOnly ? 
                <Tooltip title="Variable entfernen" arrow><div>
                <MdRemoveCircleOutline onClick={() => props.onRemoveVarClick(id)} className={"stepRemoveVar " + classes.DeleteButton + " col-md-auto"} /></div></Tooltip> : null}
                
                <select key={id+1} onChange={!props.displayOnly ? (e) =>props.onValueChange(e, id, change) : null} value={pv.type} className="form-control form-control-sm col stepDefinitionSelect">
                <option disabled value="undef">-</option>
                {Object.keys(props.planningData.vars).map((v,i) => {
                        return <option 
                            key={i} 
                            value={v}
                            data-group={props.planningData.vars[v].group}
                            name={v}>
                                {props.planningData.vars[v].title}
                        </option>
                })}
                </select>
            </div>
        )
        definitionRenderArray[pv.group]?.push(jsxContent)
    })
    
    let optionsRenderArray = [[],[],[]];
    props.planVariables.map((key, i) => {
        const change = 'option';
        const id = key.id;
        
        const varValues = find(optionSetupData, function(o) { return o.type === key.type});
              
        let jsxContent = ( 
            <select key={i}
                onChange={!props.displayOnly ? (e) =>props.onValueChange(e, id, change) : null} value={key.option} className="form-control form-control-sm stepOptionSelect">
                    <option disabled value="undef">-</option>
                    {varValues!==undefined && Object.values(varValues.values).map((val, i2) => {                        
                        return(
                            <option 
                                key={i2}
                                name={val.type} 
                                value={val.type}
                                data-group={"nochange"}>
                                    {val.name}
                            </option>
                        )
                    })}
            </select>
        )
        optionsRenderArray[key.group]?.push(jsxContent)
    });

    let unitsRenderArray = [[],[],[]];
    props.planVariables.map((key, i) => {
        const change = 'unit';
        const id = key.id;
        const varValues = find(optionSetupData, function(o) { return o.type === key.type}); 
        
        let limit = [];
        let isCorrect = '';
        if(varValues) {
            const unitMatch = find(varValues.values, function(v) {return v.type === key.option });
            if(unitMatch) {
                limit = unitMatch.limit ? limit = [...unitMatch.limit] : []
                isCorrect = unitMatch.correct && (isCorrect = unitMatch.unit)
            }
        }
        
        let jsxContent = ( 
            <select key={i}
                onChange={!props.displayOnly ? (e) => props.onValueChange(e, id, change) : null} value={key.unit} className="form-control form-control-sm stepUnitSelect">
                    <option disabled value="undef">-</option>
                    {(varValues!==undefined && limit.length == 0) ? 
                        varValues.units.map((val, i2) => {
                        if(val.name) { return null }
                        return(
                           <option
                            key={i2} name={val} value={val} 
                            data-correct={isCorrect === val} 
                            data-group={"nochange"}>
                                {val}</option>
                        )
                    })
                    : limit.map((un, i2) => {
                   
                        let un_vars = find(varValues.units, function(v) { return v.name === un })
                      
                        return <option key={i2} name={un_vars.name} value={un_vars.name} data-preset={un_vars.preset} data-correct={isCorrect} data-group={"nochange"}>{un_vars.name}</option>    
                    })
                }
            </select>
        );
        unitsRenderArray[key.group]?.push(jsxContent)
    });

    let allTubesRenderArray = [];
    props.planTubes.map((tubeCol, index) => {
        let tubeRenderArray = [[],[],[]];
        let tabindex = props.planTubes.length;
        Object.keys(tubeCol).map((tube, i) => {
            const rowIsAbhVar = find(props.planVariables, function(o) { return o.id === tube}).type === 'abh'; 
            const tubeGroup= find(props.planVariables, ['id', tube]).group;
            let jsxContent = (
                <input
                    key={i}
                    type={!rowIsAbhVar ? 'number' :'text'}
                    tabIndex={tabindex+i} 
                    disabled={rowIsAbhVar}
                    className="form-control form-control-sm" 
                    value={!rowIsAbhVar ? tubeCol[tube] : "Wird gemessen"} 
                    onChange={!props.displayOnly ? (event) => props.onTubesChange(event, index, tube) : null}
                    onClick={selectText}
                />
            )
            tubeRenderArray[tubeGroup]?.push(jsxContent)
        })
        allTubesRenderArray?.push(tubeRenderArray)
    });

    return (
        <form>
            <div className={classes.Row + " row"}>
                <div className={classes.Col + " col-6"}>
                    <div className={classes.HeadRow + " row " + (props.planVariables.length>0 && classes.Show)} >
                        
                        <div className="col-5"></div>
                        <div className="col-4">
                            <strong>Faktor</strong>
                        </div>
                        <div className="col-3">
                            <strong>Einheit</strong>
                        </div>
                    </div>
                    {!props.displayOnly ?
                        <Tooltip title="Variable hinzufügen" arrow>
                        <span 
                            ref={props.addVarRef} 
                            className={classes.AddVars + " " + (props.planVariables.length>0 && classes.ButtonClicked)} 
                            onClick={props.onAddFirstVar}>
                            <MdAddCircleOutline className="stepAddVar" />
                        </span></Tooltip> : null}
                    <div className="row planVars">
                        <div className="col-5">
                            {definitionRenderArray.map((group,i) => {
                                return (
                                    <div key={i} className={classes.PlanVarGroup + " g_"+(i+1)}>
                                        {group.map(groupContent => {
                                            return groupContent
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                        <div className="col-4">
                            {/* {optionsRender} */}
                            {optionsRenderArray.map((group,i) => {
                                return (
                                    <div key={i} className={classes.PlanVarGroup + " g_"+(i+1)}>
                                        {group.map(groupContent => {
                                            return groupContent
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                        <div className="col-3">
                            {/* {unitsRender} */}
                            {unitsRenderArray.map((group,i) => {
                                return (
                                    <div key={i} className={classes.PlanVarGroup + " g_"+(i+1)}>
                                        {group.map(groupContent => {
                                            return groupContent
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className={classes.Col + " col-6 planTubes"}>
                    <div className={(props.planTubes.length > 3 && classes.MoreTubes) + " row"}>
                        <div className="col-12">
                            <div className={classes.HeadRow + " row " + (props.planTubes.length>0 && classes.Show)} >
                                <div><strong style={{marginLeft: '16px'}}>Werte pro Reagenzglas</strong></div>
                            </div>
                            <div ref={props.scrollContainerRef} style={{overflowX: "scroll"}} className={classes.VerticalScroll + " row"}>
                                {allTubesRenderArray.map((tubeGroups, index) => {
                                    let tabindex = props.planTubes.length;
                                    return (
                                        <div key={index}  className={
                                            (props.fadeInTubeId===index ? classes.FadeIn : props.fadeOutTubeId===index ?  classes.FadeOut 
                                            : props.fadeInit===index ? classes.FadeInit : tabindex <= 4 ? " col-"+(12/tabindex) : " col-3") + " stepTubes " + classes.TubeCol}>
                                            {tubeGroups.map((tube, i) => {
                                                return (
                                                    <div key={i} className={classes.PlanVarGroup + " g_"+(i+1)}>
                                                        {tube}
                                                    </div>
                                                )
                                            })}
                                            {!props.displayOnly ?
                                                <Tooltip title="Reagenzglass entfernen" arrow>
                                                    <div className={classes.RemoveTubeWrap}>
                                                    <MdRemoveCircleOutline 
                                                        onClick={() => props.onRemoveTubeClick(index)} 
                                                        className={classes.DeleteButton + " "+ classes.DeleteTubeButton+ " stepRemoveTube"}/>
                                                    </div>
                                                </Tooltip>
                                                : null }
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    {!props.displayOnly ?
                        <Tooltip title="Reagenzglass hinzufügen" arrow>
                        <span ref={props.addTubeRef} 
                        className={classes.AddTubes + " addTubeButton " + (props.planTubes.length>0 && classes.ButtonClicked)} 
                        onClick={props.onAddFirstTube} >
                        <MdAddCircleOutline className="stepAddTube" /></span></Tooltip>
                    : null}
                </div>
            </div>
            <div className="row">
                <div className="col-6">Wie oft soll das Experiment wiederholt werden?
                    <textarea className={repCount.join(" ") + " form-control"} 
                        value={props.planRepetitionCount} 
                        onChange={!props.displayOnly ? props.onRepetitonCountChange : null} 
                    />
                </div>
                <div className="col-6">Beschreibe, wie du die Wiederholungen umsetzt:
                    <textarea className="form-control" 
                        value={props.planRepetitionDesc}
                        onChange={!props.displayOnly ? props.onRepetitonDescChange : null} 
                    />
                </div>
            </div>
        </form>
    )
}

export default PlanningTable
