import classes from './Header.module.sass'
import Logo from '../Logo/Logo';
import DrawerToggle from './Navigation/SideDrawer/DrawerToggle/DrawerToggle'


const Header = (props) => {
    
    let userEmail = null
    if(props.isAuth) { 
        userEmail = (<p className={classes.Welcome}>Angemeldet als {props.userEmail}</p>)
    }
    return (
        <header className={classes.Header}>
                <Logo />
                {userEmail}
                <DrawerToggle clicked={props.toggleClicked} />
        </header>   
    );
};

export default Header;