import React, { useState } from 'react'
import PDFDocument from '../PDFDocument'
import { pdf } from '@react-pdf/renderer'
import { saveAs } from 'file-saver';
import { convertPlanData } from '../../../shared/utility';

import classes from './PDFDownloadButton.module.sass'
import { MdFileDownload } from 'react-icons/md';

const PDFDownloadButton = (props) => {
    const [download, setDownload] = useState(false);
    
    const onDownloadPlanData = async () => {
        setDownload(true);
        const doc = <PDFDocument 
            planVars={convertPlanData(props.planVariables)} 
            tubes={props.planTubes}
            repetitionC={props.planRepetitionCount}
            repetitionD={props.planRepetitionDesc}
            title={props.title}
            />;
        const asPdf = pdf(); 
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        saveAs(blob, props.documentName);  
        setTimeout(() => {
            setDownload(false);
        }, 1000)
    }

    return (
        <>
            <MdFileDownload 
                className={classes.SaveButton + ' ' + (download && classes.Saving)} 
                onClick={onDownloadPlanData} />
        </>
    )
}

export default PDFDownloadButton
