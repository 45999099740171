import * as actionTypes from './actionTypes';
import api from '../../axios-api';
import { getFormData } from '../../shared/utility';

export const selectExercise = (type, exPos) => {
    const token = localStorage.getItem('token');

    const data = {userID: token, exType: type, exPos}
    let dataToSend = getFormData(data);
    api.post("/api/start_exercise.php", dataToSend)
    .then(response => {
        console.log("Started Exercise (inserted to DB)", response)
        if(response.data.exID) {
            localStorage.setItem('exID', response.data.exID)
            localStorage.setItem('exType', response.data.exType)
            localStorage.setItem('currExPos', response.data.exPos)
            localStorage.setItem('exStep', '01_intro');
        }
    });
    return {
        type: actionTypes.SELECT_EXERCISE,
        exSelected: type,
        exPos: exPos,
        exStep: '01_intro'
    };    
}

export const updateExData = (activeEx) => {
    return {
        type: actionTypes.LOAD_ACTIVE_EXERCISE,
        exSelected: activeEx.exType,
        exPos: activeEx.exPos,
        exStep: activeEx.exStep,
        userResult: activeEx.exUserResult,
        userExpl: activeEx.exUserExpl,
        userInterpret: activeEx.exUserInterpret
    }
}

export const loadActiveExercise = (exID) => {
    return dispatch => {
        const data = { 
            table: 'TUserExercises',
            conditionField: 'exId',
            condition: exID
        }
        let dataToSend = getFormData(data);
        api.post("/api/fetch_data.php", dataToSend)
            .then(response => {
                console.log( "exercise active return", response.data);
                const activeEx = response.data[0]
                return dispatch(updateExData(activeEx));
        }).catch(err=> {
            console.log("error when loading active exercise", err);
        });
    }
}

export const showTopicInfo = (state) => {
    return {
        type: actionTypes.SHOW_TOPIC_INFO,
        topicInfoVisible: state
    }
}
export const changeExerciseStep = (step) => {
    return {
        type: actionTypes.CHANGE_EXERCISE_STEP,
        exStep: step
    }
}

export const changeResult = (event) => {
    return {
        type: actionTypes.CHANGE_RESULT,
        value: event.target.value
    }
}

export const changeInterpretation = (event) => {
    return {
        type: actionTypes.CHANGE_INTERPRETATION,
        value: event.target.value
    }
}

export const changeExplenation = (event) => {
    return {
        type: actionTypes.CHANGE_EXPLENATION,
        value: event.target.value
    }
}

export const changeAdditionalInput = (event, name) => {
    return {
        type: actionTypes.CHANGE_ADDITIONAL_INPUT,
        name: name,
        value: event.target.value
    }
}

export const completePlaning = (exName, success, input) => {
    let userInput = JSON.stringify(input)
    const token = localStorage.getItem('token');
    const data = {
        userID: token, 
        exPlanSuccess: success ? 'ja' : 'nein', 
        planData: userInput
    }
    let dataToSend = getFormData(data);
    
    api.post("/api/end_planing.php", dataToSend)
    .then(response => {
      console.log("ended the planing", response);
    }).catch(err=> {
        console.log("error with api call to END planing", err);
    });

    return {
        type: actionTypes.COMPLETE_PLANING,
        exercise: exName,
        success: success
    }
}

export const completeExercise = (exName, addiInput) => {
    const token = localStorage.getItem('token');
    const data = {
        userID: token,
        exUserResult: addiInput.result,
        exUserInterpret: addiInput.interpretation,
        exUserExpl: addiInput.explanation
    }

    let dataToSend = getFormData(data);

    api.post("/api/end_exercise.php", dataToSend)
    .then(response => {
      console.log("ended exercise", response);
      localStorage.setItem('completed', exName + ";");
    }).catch(err=> {
        console.log("error with api call to end exercise", err);
    });

    return {
        type: actionTypes.COMPLETE_EXERCISE,
        exercise: exName,
    }
}

