import RangeSlider from '../../../../../components/UI/RangeSlider/RangeSlider';

const HelpReviewSlider = (props) => {    
    return (
        <div>
            <p><strong>Wie passend</strong> war für dich die soeben genutzte <strong>Hilfe</strong>? Ziehe den Schieber so, wie es auf dich zutrifft.</p>
            <RangeSlider 
                min="0" 
                max="10" 
                value={props.fitting} 
                desc="passend"
                moved={props.fittingMoved}
                change={(e) => props.changeSlider(e, 'fitting')}/>
            <p><strong>Wie verständlich</strong> war für dich die soeben genutzte <strong>Hilfe</strong>? Ziehe den Schieber so, wie es auf dich zutrifft.</p>
            <RangeSlider 
                min="0" 
                max="10" 
                value={props.understandable} 
                desc="verständlich"
                moved={props.understandableMoved}
                change={(e) => props.changeSlider(e, 'understand')}/>
        <p><strong>Wie sehr</strong> hat dich die <strong>Hilfe weiter gebracht</strong>? Ziehe den Schieber so, wie es auf dich zutrifft.</p>
            <RangeSlider 
                min="0" 
                max="10" 
                value={props.use} 
                moved={props.useMoved}
                desc="weiter gebracht"
                change={(e) => props.changeSlider(e, 'use')}/>
        </div>
    )
}

export default HelpReviewSlider
