import React, {useState} from 'react'
import {AiOutlinePlus, AiOutlineMinus} from 'react-icons/ai'
import HelpContentSubSub from './HelpContentSubSub/HelpContentSubSub'
import HelpContentTypes from './HelpContentTypes/HelpContentTypes'

//import Slideshow from '../../../../../components/UI/Slideshow/Slideshow'
const HelpContentSub = ({type, data, node, category}) => {
    const [extended, setExtended] = useState(null)
     console.log(data)   
    return (
        <div>
            <div style={{cursor: 'pointer'}} onClick={() => setExtended(!extended)}><strong>
                <span>{extended ? <AiOutlineMinus/> : <AiOutlinePlus/>}</span>{data.title}</strong>
            </div>
            {extended && 
                <>
                    <HelpContentTypes type={"Hinweis"} category={category} data={data.hint} node={node + "/hint"}/>
                    <HelpContentTypes type={"Beispiel"} category={category} data={data.example} node={node + "/example"}/>
                    <HelpContentTypes type={"Lösung"} category={category} data={data.solution} node={node + "/solution"}/>
                </>
            }
            {/* {data && extended && Object.keys(data).map((type, i) => {
                console.log("node to types?: ", node + "/" + type)
                return <HelpContentTypes data={data[type]} node={node + "/" + type}/>
            })} */}
        </div>
    )
}

export default HelpContentSub
