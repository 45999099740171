import React, {useState, useEffect} from 'react'
import DataExSteps from './DataExSteps/DataExSteps'
import DataExHelpCenter from './DataExHelpCenter/DataExHelpCenter'

import fb from '../../../../axios-fb'

const DataExercise = ({data, node}) => {
    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        setIsActive(data.active)
    }, []);

    const changeHandler = (e) => {
        console.log(e.target.checked)
        fb.patch(node+'.json', {active: e.target.checked})
        .then(response => {
            console.log(response)
            setIsActive(!isActive)
        });
    }
    return (
        <div className="mb-4">
            <div className="d-flex justify-content-between">
                <h4 
                    className={"border-bottom flex-grow-1 " + (!isActive && "text-black-50")} >
                    {data.title}</h4>
                <label className="switch mr-2">
                    <input onChange={changeHandler} type="checkbox" checked={isActive}/>
                    <span className="slider round"></span>
                </label>
            </div>
            <div>
                <h6>Steps</h6>
                    {data.steps && Object.keys(data.steps).map(st => {
                        return (
                            <DataExSteps 
                                key={st}
                                category={data.type} 
                                type={st} 
                                node={node+"/steps/"+st} 
                                data={data.steps[st]} />
                        )
                    })}
                <h6 style={{marginTop: '10px'}}>HelpManager</h6>
                    {data.exHelpCenterData && Object.keys(data.exHelpCenterData).map(pos => {
                        console.log(data.exHelpCenterData[pos])
                       return (
                            <DataExHelpCenter 
                                key={pos}
                                category={data.type} 
                                type={pos} 
                                node={node+"/exHelpCenterData/"+pos} 
                                data={data.exHelpCenterData[pos]} />
                        )
                    })}
            </div>
        </div>
    )
}

export default DataExercise
