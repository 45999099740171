import React, { useState, useEffect } from 'react'
import useFb from '../../../hooks/use-fb'
import classes from './ExQuestions.module.sass'
import RadioRow from '../RadioRow/RadioRow'
import { insertExQA } from '../../../shared/api-calls'

const ExQuestions = ({exPos, closeTest, onFinish}) => {
    const [questionData, setQuestionData] = useState(null);
    const {loader, error, sendRequest} = useFb();
    const [step, setStep] = useState(0);

    const [allTouched, setAllTouched] = useState(false)
    const [inputData, setInputData] = useState(null)


    useEffect(() => {
        sendRequest(`/exerciseData/exercises/${exPos}/exQuestions.json`, setQuestionData);
    }, []);

    useEffect(() => {
        if(inputData) {
            if(Object?.keys(inputData).length === questionData[step].questions.length) {
               setAllTouched(true)
             }
         }
    }, [inputData]);

    const changeRadio = (e) => {
        const newInputData = {...inputData, [e.target.name]: e.target.value}
        setInputData(newInputData)
    }

    const saveDataToDB = () => {
        insertExQA(localStorage.getItem('token'), localStorage.getItem('exID'), inputData)
            .then(result=> { console.log("api: ", result)})
        setInputData(null);
    }

    const changeStep = () => {
        saveDataToDB()
        setAllTouched(false)
        if(step+1 !== questionData.length) {
            setStep(step+1)
        } else {
            console.log("END EXQ TEST")
            closeTest();
            onFinish();
        }
    }

    return (
        <div>
            {loader}
            {questionData && <div className={classes.LoadedText} dangerouslySetInnerHTML={{__html: questionData[step].description}}></div> }
            {questionData && questionData[step].questions.map((q, i) => 
                <RadioRow key={i} question={q} changeRadio={changeRadio} selectedValue={inputData ? inputData[q.name] : undefined}/>
            )}
            <button 
                disabled={localStorage.getItem('userAuthCode').includes("debug") ? false : !allTouched} 
                className="btn btn-primary btn-sm m-1 float-right" onClick={changeStep}>Weiter</button>
        </div>
    )
}

export default ExQuestions
