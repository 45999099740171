import React, {useState} from 'react'
import {AiOutlinePlus, AiOutlineMinus} from 'react-icons/ai'
import DataExSubStep from './DataExSubStep/DataExSubStep'

const DataExSteps = ({type, data, node, category}) => {
    console.log("find: ", type)
    const [extended, setExtended] = useState(null)
        
    return (
        <div>
            <div style={{cursor: 'pointer'}} onClick={() => setExtended(!extended)}><strong>
                <span>{extended ? <AiOutlineMinus/> : <AiOutlinePlus/>}</span>{data.title}</strong>
            </div>
            {data.substeps && extended && data.substeps.map((sust, i) => {
                console.log(data)
                return <DataExSubStep 
                    key={i} 
                    node={node +"/substeps/"+ i} 
                    data={sust} 
                    nr={i}
                    category={category} />
            })}
        </div>
    )
}

export default DataExSteps
