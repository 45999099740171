import api from '../../axios-api';
import * as actionTypes from './actionTypes';
import { cyrb53, getFormData } from '../../shared/utility';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
}

export const authSuccess = (token, email, group) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        idToken: token,
        userEmail: email,
        userGroup: group,
        isSignup: false
    };
}

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
}

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('email');
    localStorage.removeItem('group');
    localStorage.removeItem('exID');
    localStorage.removeItem('gender');
    localStorage.removeItem('exStep');
    localStorage.removeItem('exType');
    localStorage.removeItem('currExercise');
    localStorage.removeItem('currExPos');
    localStorage.removeItem('completed');
    localStorage.removeItem('userTracking');
    localStorage.removeItem('userAuthCode');

    return {
        type: actionTypes.AUTH_LOGOUT,
    }
}

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        // setTimeout(() => {
        //     dispatch(logout());
        // }, expirationTime * 1000); // turn minutes to milliseconds 
    }
}

export const auth = (email, authCode) => {
    return dispatch => {
        dispatch(authStart());
        const key = cyrb53(email.toLowerCase());
        const data = { userID: key }
        let dataToSend = getFormData(data);
        
        api.post("api/login_check.php", dataToSend)
        .then(response => {
            if(response.data) {
                console.log("check login: ", response)
                const expirationDate = new Date(new Date().getTime() + 3600 * 1000)
               
                localStorage.setItem('token', key);
                localStorage.setItem('expirationDate', expirationDate);
                localStorage.setItem('email', email);
                localStorage.setItem('group', response.data.group);
                localStorage.setItem('gender', response.data.gender);
                localStorage.setItem('userTracking', response.data.userTracking);
                //localStorage.setItem('userAuthCode', response.data.userAuthCode);
                localStorage.setItem('userAuthCode', authCode);
                if(response.data.exID) {
                    localStorage.setItem('exID', response.data.exID);
                    localStorage.setItem('exType', response.data.exType);
                    localStorage.setItem('exStep', response.data.exStep);
                }
                dispatch(authSuccess(key, email, response.data.group));
                dispatch(checkAuthTimeout(3600 * 6));
            } else {
                console.log("login failed, prepare Signup")
                dispatch(prepareSignup())
            }
        })
        .catch(err => {
            console.log("error on auth", err);
            dispatch(authFail(email));
        })
    };
};

export const prepareSignup = () => {
    return {
        type: actionTypes.AUTH_PREPARE_SIGNUP
    };
}

export const signup = (email, age, gender, tracking, authCode) => {
    return dispatch => {
        const key = cyrb53(email.toLowerCase());
        const data = { 
            userID: key,
            userAge: age,
            userGender: gender,
            userTracking: tracking,
            userAuthCode: authCode
        }
        let dataToSend = getFormData(data);
        api.post("/api/add_user.php", dataToSend)
        .then(response => {
            console.log("added new user:", response.data);
            const expirationDate = new Date(new Date().getTime() + 3600 * 1000 * 2)
            localStorage.setItem('token', key);
            localStorage.setItem('expirationDate', expirationDate);
            localStorage.setItem('email', email.toLowerCase());
            localStorage.setItem('group', response.data);
            localStorage.setItem('userTracking', tracking);
            localStorage.setItem('userAuthCode', authCode);
            
            dispatch(authSuccess(key, email.toLowerCase(), response.data));
            dispatch(checkAuthTimeout(3600*6));

        }).catch(err=> {
            console.log(err);
        });
    }
}

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if(!token) {
            dispatch(logout());
        } else {
            const email = localStorage.getItem('email');
            const expirationDate = new Date(localStorage.getItem('expirationDate')) // convert string to date
            if(expirationDate <= new Date()) {
                dispatch(logout());
            } else {
                dispatch(authSuccess(token, email));
                dispatch(checkAuthTimeout((expirationDate.getTime()-new Date().getTime()) / 1000));
            }
        }
    };
}