import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';

import classes from './ExercisesOverview.module.sass'
import Modal from '../../../components/UI/Modal/Modal'

const ExerciseOverview = props => {  
   const [enableExStart, setEnableExStart] = useState(false);
   const [showFinalMessage, setShowFinalMessage] = useState(false);

    useEffect(() => {
        setEnableExStart(true) 
    }, []);

    useEffect(() => {
        props.allFinished && setShowFinalMessage(true)
    }, [props.allFinished]);

    const exerciseInfo = props.topicInfo.map((topicInfo, i) => {
        return (
                <Link key={i}
                    to={'/exercises/info/'+topicInfo.type} 
                    className={classes.ExerciseBox + " shadow p-3 mb-5 bg-white col rounded"}>
                    <h4 dangerouslySetInnerHTML={{ __html: topicInfo.info.title}} ></h4>
                    <div className={classes.ImageContainer}>
                        <img alt="Ein Vorschaubild der Aufgabe" src={process.env.PUBLIC_URL + `/files/exercises/${topicInfo.type}/topicInformation/${topicInfo.info.mainImg}`} />
                        <span>
                            {topicInfo.info.moreInfos}
                        </span>
                    </div>
                </Link>
        )
    });
    
    const selectExercise = (type, exPos) => {
        if(enableExStart) {
            setEnableExStart(false);
            props.onExSelect(type, exPos);
            console.log("type", type)
            if(type === 'phvalue') {
                props.onResetPlanData(2);
            } else if(type === 'activation_energy') {
                props.onResetPlanData(3);
            } else if(type === 'inhibition') {
                props.onResetPlanData(4);
            } else {
                props.onResetPlanData(1);
            }
        }
    }

    const exOverview = props.exercisesPreview.map(ex => {
        let exClasses = [classes.ExerciseBox];
        const compledExerciseString = localStorage.getItem('completed');
        //let completed = compledExerciseString ? compledExerciseString.split(';').includes(ex.type) : null
        
        //completed = compledExerciseString ? compledExerciseString.split(';').includes(ex.type)
        //: props.exCompleted ? props.exCompleted.includes(ex.type) : ""
        
        if(!ex.active ) { //|| completed) {
            exClasses.push(classes.ExerciseDisabled);
        }

        if(ex.exPos < props.isNextEx) {
            exClasses.push(classes.ExerciseCompleted);
        }
        if(ex.exPos !== props.isNextEx) {
            exClasses.push(classes.ExerciseDisabled);
        }
                  
        //if(ex.active && !completed && ex.exPos === props.isNextEx) {
        if(ex.active && ex.exPos === props.isNextEx) {
            return (
                <Link 
                    key={ex.type} 
                    to={'/exercises/'+ex.type+"/01_intro"} 
                    onClick={() => selectExercise(ex.type, ex.exPos)} 
                    className={exClasses.join(" ") + " shadow p-3 mb-5 bg-white col rounded"}>
                    <h4 dangerouslySetInnerHTML={{ __html: ex.title}} ></h4>
                    <div className={classes.ImageContainer}>
                        <img alt="Das Hauptbild der Aufgabe" src={process.env.PUBLIC_URL + `/files/exercises/${ex.type}/${ex.mainImg}`} />
                    </div>
                    <span className={classes.NextExercise}>Jetzt starten</span>
                </Link>
            )
        } else {
            return (
                <div key={ex.type} className={exClasses.join(" ") + " shadow p-3 mb-5 bg-white col rounded"} >
                    <h4 dangerouslySetInnerHTML={{ __html: ex.title}} ></h4>
                    <div className={classes.ImageContainer}>
                        <img alt="Das Hauptbild der Aufgabe" src={process.env.PUBLIC_URL + `/files/exercises/${ex.type}/${ex.mainImg}`} />
                    </div>
                    <span>
                        {(ex.exPos < props.isNextEx) ?
                            "Abgeschlossen" 
                        : !ex.active ? "Bald verfügbar"
                        : `Zuerst die ${props.isNextEx}. Aufgabe beenden `}
                    </span>
                </div>
            )
        }
    })

    return (
        <>
            <div className={classes.ExerciseOverview}>
                <h2>Themenbezogene Informationen</h2>
                <div className="row">
                    {exerciseInfo}
                </div>
                <h2>Übersicht der Aufgaben</h2>
                {!props.allFinished ?
                    <div className="row">
                        {exOverview}
                    </div>

                :    <div className={classes.allFinishedMessage}>
                        <p>Du hast alle Aufgaben abgeschlossen. Ich bedanke mich herzlich für Deine Teilnahme! Für Feeback oder Anregungen, kannst du dich gerne an mich wenden: <a href="mailto:andreadenise.luescher@fhnw.ch">andreadenise.luescher@fhnw.ch</a></p></div>
                }
            </div>
            <Modal width="40%" show={showFinalMessage} modalClosed={() => setShowFinalMessage(false)} >
                <img width="100%" src="./files/general/Dank.png" alt="Andrea sagt, vielen lieben Dank für deine Teilnahme."/>
            </Modal>
        </>
    )
}
// -> redux
const mapStateToProps = state => {
    return {
        exSelected: state.exercise.exSelected,
        exCompleted: state.exercise.exCompleted
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onExSelect: (type, exPos) => dispatch(actions.selectExercise(type, exPos)),
        onResetPlanData: (template) => dispatch(actions.resetPlanData(template)) 
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExerciseOverview);