import React, { useEffect, useState } from 'react'

import Moment from 'react-moment'
import classes from './PanelHelpUsed.module.sass'
import api from '../../../../axios-api'
import { getFormData, convertNames } from '../../../../shared/utility'
import { CSVLink } from 'react-csv'


const PanelHelpSession = (props) => {
    const [sessionMode, setSessionMode] = useState();
    const [usedData, setUsedData] = useState(null)

    useEffect(() => {
        const data = { 
            table: 'THelpUse', 
            conditionField: 'helpEntID', 
            condition: props.helpEntID
        }    
        
        let dataToSend = getFormData(data);
        api.post('/api/fetch_all_data.php', dataToSend)
        .then(response => {
            console.log(response.data)
            if(response.data) {
                setUsedData(response.data)
            }
        }).catch(err => {
            console.log("error when fetching users in the backend", err)
        })
        console.log("DEBUG DATA: ", data.condition)
    }, [props.helpEntID])
   
    console.log("usedData: " , usedData)
    return (
        <>  
        <div className="row col-12">
            <h4>Gebrauchte Hilfen ({usedData?.length})</h4>
        </div>
            {/* <strong>Help Session-ID: {props.helpEntID}</strong> */}
            {usedData?.map(hu => 
                <div className={classes.HelpSessionRow + " row col-12"} key={hu.helpUseID}>
                    <div className="col-6">
                        Typ: {convertNames(hu.helpUseType)}<br/>
                        Lösungsart: {convertNames(hu.helpUseSubType)}<br/>
                        ID: {hu.helpUseID}
                    </div>
                    <div className="col-6">
                        <strong>Dauer:</strong> <Moment duration={hu.helpUseOpen}>{hu.helpUseClose}</Moment><br/>
                        <strong>Start:</strong> <Moment format={"H:m:s"}>{hu.helpUseOpen}</Moment><br />
                        <strong>End:</strong> <Moment format={"H:m:s"}>{hu.helpUseClose}</Moment>
                    </div>
                    {console.log(hu)}
                    {/* <CSVLink filename={`helpUsed-${hu.helpUseID}.csv`} data={hu}>Download {hu.helpUseID}</CSVLink> */}
                </div>
            )}
            {console.log("used Data: ",usedData)}
            {usedData && 
                <div className="d-flex justify-content-center">
                <CSVLink className={classes.DownloadButton} filename={`helpUsed-ofEnt-${props.helpEntID}.csv`} data={usedData}>Download {props.helpEntID}</CSVLink></div>
            }
        </>
        )
        
}

export default PanelHelpSession
