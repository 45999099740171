import React, {useState} from 'react'
import {AiOutlinePlus, AiOutlineMinus} from 'react-icons/ai'
import DataExHelpPos from './DataExHelpPos/DataExHelpPos'

const DataExSteps = ({type, data, node, category}) => {
    console.log("find: ", category)
    const [extended, setExtended] = useState(null)
        
    return (
        <div>
            <div style={{cursor: 'pointer'}} onClick={() => setExtended(!extended)}><strong>
                <span>{extended ? <AiOutlineMinus/> : <AiOutlinePlus/>}</span>{data.title}</strong>
            </div>
            {console.log("exhelpcenter", data)}
            {(data && extended) && Object.keys(data).map((sust, i) => {
                console.log("exhelpcenter data", data)
                if(sust !=="desc" && sust !=="title") {
                    return <DataExHelpPos 
                    key={i} 
                    node={node +"/"+sust} 
                    data={data[sust]} 
                    type={type}
                    subType={sust}
                    category={category} />
                }
            })}
        </div>
    )
}

export default DataExSteps
