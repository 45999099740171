import React, { useEffect, useState } from 'react'

import Moment from 'react-moment'
import classes from './PanelHelpSession.module.sass'
import api from '../../../../axios-api'
import { getFormData } from '../../../../shared/utility'
import PanelHelpUsed from '../PanelHelpUsed/PanelHelpUsed'

const PanelHelpSession = (props) => {
    const [sessionMode, setSessionMode] = useState();
    const [sessionData, setSessionData] = useState(null)

    useEffect(() => {
        const data = { 
            table: 'THelpEntries', 
            conditionField: 'exID', 
            condition: props.exID
        }    
        
        let dataToSend = getFormData(data);
        api.post('/api/fetch_all_data.php', dataToSend)
        .then(response => {
            console.log(response.data)
            if(response.data) {
                setSessionData(response.data)
            }
        }).catch(err => {
            console.log("error when fetching users in the backend", err)
        })
    }, [])
    const exButtons = [
        {name: "Hilfen", value: "h"}
    ]
   
    const changeMode = (mode) => {
        setSessionMode(mode)
    }
    
    console.log("helpSession: ", sessionData)
    return (
        <div className="row">
            <div className="col-5">
                <h4>Geöffnete Hilfe-Sessions ({sessionData?.length})</h4>
                {sessionData?.map(hs => 
                    <div key={hs.helpEntID} 
                        className={classes.HelpSessionRow + " row " + (sessionMode === hs.helpEntID && classes.Active) }
                        onClick={() => changeMode(hs.helpEntID)}>
                        <div className="col-4">
                            ID: {hs.helpEntID}
                        </div>
                        <div className="col-6">
                            <strong>Dauer:</strong> <Moment duration={hs.helpEntOpen}>{hs.helpEntClose}</Moment><br/>
                            <strong>Start:</strong> <Moment format={"H:m:s"}>{hs.helpEntOpen}</Moment><br />
                            <strong>End:</strong> <Moment format={"H:m:s"}>{hs.helpEntClose}</Moment>
                        </div>
                    </div>
                )}
            </div>
            <div className="col-7">
                {sessionMode &&
                    <PanelHelpUsed helpEntID={sessionMode}/>
                }
            </div>
        </div>
        )
        
        {/* <div className="col-4">
            {exButtons.map(b => {
                return <button 
                    onClick={() => changeMode(b.value)} 
                    className={""}>
                        {b.name}</button>
                }
            )} */}
        {/* </div> */}
    
}

export default PanelHelpSession
