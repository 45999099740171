
// Authenticate
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT'; 
export const AUTH_PREPARE_SIGNUP = 'AUTH_PREPARE_SIGNUP'; 

// Exercise
export const SELECT_EXERCISE = 'SELECT_EXERCISE'; 
export const CHANGE_EXERCISE_STEP = 'CHANGE_EXERCISE_STEP';
export const CHANGE_RESULT = 'CHANGE_RESULT';
export const CHANGE_INTERPRETATION = 'CHANGE_INTERPRETATION';
export const CHANGE_EXPLENATION = 'CHANGE_EXPLENATION';
export const CHANGE_ADDITIONAL_INPUT = 'CHANGE_ADDITIONAL_INPUT';
export const SHOW_TOPIC_INFO = 'SHOW_TOPIC_INFO';
export const LOAD_ACTIVE_EXERCISE = 'LOAD_ACTIVE_EXERCISE';
//export const SET_EXERCISE_ID = 'SET_EXERCISE_ID';


//Planning
export const PLAN_ADD_VAR = 'PLAN_ADD_VAR'; 
export const PLAN_REMOVE_VAR = 'PLAN_REMOVE_VAR'; 
export const PLAN_ADD_TUBE = 'PLAN_ADD_TUBE';
export const PLAN_REMOVE_TUBE = 'PLAN_REMOVE_TUBE'; 
export const PLAN_CHECK_RESULT = 'PLAN_CHECK_RESULT'; 
export const PLAN_CHANGE_VALUE = 'PLAN_CHANGE_VALUE'; 
export const PLAN_CHANGE_TUBE_VALUE = 'PLAN_CHANGE_TUBE_VALUE';
export const PLAN_CHANGE_REPETITION_COUNT = 'PLAN_CHANGE_REPETITION_COUNT';
export const PLAN_CHANGE_REPETITION_DESC = 'PLAN_CHANGE_REPETITION_DESC';
export const PLAN_FIRST_VISIT = 'PLAN_FIRST_VISIT';
export const COMPLETE_PLANING = 'COMPLETE_PLANING';
export const COMPLETE_EXERCISE = 'COMPLETE_EXERCISE';
export const CLOSE_HELP_TUTORIAL = 'CLOSE_HELP_TUTORIAL';
export const SET_PLAN_DATA = 'SET_PLAN_DATA';
export const RESET_PLAN_DATA = 'RESET_PLAN_DATA';




