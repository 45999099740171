export const USERCODES = [
    7095264747794260, // diLuna2021
    557395528894555, // debug
    5764669474163459, // debug2
    5337732683952894, // testuser
    5376204298008397, // som
    5092971099680082, // andrea
    2478724660980260, // julia
    5182931458737881, // markus
    6713915909405684, // SG00121
    8220162275527374, // SG00221
    5606405358097973, // SG00321
    7926431393483209, // SG00421
    6708325486332066, // SG00521
    1821793075481981, // SG00621
    5118220904892968, // SG00721
    1822202285914644, // SG00821
    1638670727049010, // SG00921
    122802176528975, // SG01021
    5253122356986697, // SG01121
    3644230577989305, // SG01221
    2649474333008332, // SG01321
    2815421284879383, // SG01421
    4257126546286152, // SG01521
    4731374256677314, // SG01621
    4073444482455681, // SG01721
    5940025947292657, // SG01821
    2894850624244160, // SG01921
    1804972615592375, // SG02021
    4344766002267133, // SG02121
    3513889460180504, // SG02221
    2296232066019144, // SG02321
    3928622123468257, // SG02421
    4232342603095751, // SG02521
    3901093698053390, // SG02621
    2602798058018798, // SG02721
    2524167529031061, // SG02821
    1326487170102638, // SG02921
    1719326212108474, // SG03021
    7960948395716101, // SG03121
    7451569635141160, // SG03221
    4578777811796389, // SG03321
    7620839500572593, // SG03421
    3720571855666350, // SG03521
    4350707526280478, // SG03621
    387853021046164, // SG03721
    7927720711815061, // SG03821
    8245890211166968, // SG03921
    3853366482265879, // SG04021
]