import React, {useState} from 'react'
import classes from './Admin.module.sass'
import AdminAuth from './AdminAuth/AdminAuth'
import { cyrb53 } from '../../shared/utility'
import AdminPanel from './AdminPanel/AdminPanel'
import DataOverview from './DataOverview/DataOverview'
import HelpDataOverview from './HelpDataOverview/HelpDataOverview'
import { MdYoutubeSearchedFor } from 'react-icons/md'

const modes = [
    {val: "exercise", name: "Aufgabe"},
    {val: "users", name: "Users"},
    {val: "help", name: "Hilfen"}
]

const Admin = () => {
    const [adminAuth, setAdminAuth] = useState(false)
    const [authInput, setAuthInput] = useState("")
    const [mode, setMode] = useState(null);

    const onCheckAdminAuth = () => {
        if (cyrb53(authInput) ===  7629795758251481) {
            setAdminAuth(true)
        }
    }

    const onChangeAuthInput = (e) => {
        setAuthInput(e.target.value)
    }

    let adminContent = null
    if (!adminAuth) {
        adminContent = <AdminAuth 
            authInput={authInput} 
            changed={onChangeAuthInput}
            onAuth={onCheckAdminAuth} 
        />
    }  else {
        mode === 'users' && (adminContent = <AdminPanel />)
        mode === 'exercise' && (adminContent = <DataOverview />)
        mode === 'help' && (adminContent = <HelpDataOverview />)
    }
    let modeButtons = null
    modeButtons = modes.map(m => {
        return  <button 
            key={m.val}
            onClick={() => setMode(m.val)} 
            className="btn btn-secondary mr-2 mb-5">
                {m.name}
            </button>
    })

    return (
        <div>
            <h1>Admin Bereich</h1>
            {modeButtons}
            {adminContent}
        </div>
    )
}

export default Admin
