import React, {useState, useEffect} from 'react'
import useFb from '../../../../hooks/use-fb'
import HelpGroup from './HelpGroup/HelpGroup'

const HelpTutorials = ({node}) => {
    const [data, setData] = useState(null);
    const {loader, error, sendRequest} = useFb()
    
    useEffect(() => {
        sendRequest(`/helpTutorialGroups.json`, setData);
        console.log('requested')
    }, [sendRequest])

    let tutorialGroups = null;
    tutorialGroups = data && (
        Object.keys(data).map(helpGroup => {
            return (
                <HelpGroup 
                    key={helpGroup} 
                    type={helpGroup} 
                    node={`helpTutorialGroups/${helpGroup}`} 
                    data={data[helpGroup]} />
                )
            })
        )

    return (
        <div className="mb-4">
            {loader}
            <div className="d-flex justify-content-between">
                <h4 className="border-bottom flex-grow-1">
                    Hilfe Gruppen
                </h4>
            </div>
            <div>
                <h6></h6>
                {tutorialGroups}
            </div>
        </div>
    )
}

export default HelpTutorials
