import { find } from 'lodash';


export const c_temperature = (planVariables, planTubes) => {
    console.log("------ VALIDATE THE PLANING TEMP ----------\n--------------- T E M P --------------")

    const config = {
        tubesNeeded: 3,
        count: {
            abh: 1,
            unabh: 1,
            dist: 2,
            messure: 3
        }
    }

    let errorLeftSide = "";
    let errorUnabh = "";
    let errorDist = "";
    let errorMessure = "";
    let validate = {}
    let c_abh = 0;
    let c_unabh = 0;
    let c_dist = 0;
    let c_messure = 0;
    
    planVariables.map(va => {        
        // check abh vars (left side)  
        console.log("all va options:", va);
        if(va.type === 'abh') {
            c_abh++;
            if(
                ((va.option === 'enzymakt' && va.unit === 'pH-Wert: Zeitpunkt des Farbumschlags in s')
            ||  (va.option === 'fetts' && va.unit === 'Schaumhöhe in mm'))             
            //&& va.unitCorrect == 'true'
            && !validate[va.option]) {
                console.log("abh true")
                console.log(va.option)
                validate = {...validate, [va.option]: [va.type, va.option, va.unit, va.id]}
                console.log(validate)
            } else {
                errorLeftSide += "Wrong abh var or wrong unit ("+va.option+")\n"
            }
        }
        // check unabh vars (left side)
        if(va.type === 'unabh') {
            c_unabh++;
            if(va.option === 'temp' && va.unit === '°C' 
            && !validate[va.option]) {
                validate = {...validate, [va.option]: [va.type, va.option, va.unit, va.id]}
            } else {
                errorLeftSide += "Wrong unabh var or wrong unit ("+va.option+")\n"
            }
        }  
        // check unabh vars (left side)
        if(va.type === 'dist') {      
            c_dist++;       
            if(va.option === 'lipase' || va.option === 'kondens'
            && va.unit === 'ml') {
                if(validate.hasOwnProperty(va.option)) { // check if this prop already exists (no doublles)
                    errorLeftSide +=  va.option + " allready used, no doubbles ("+va.option+") \n"
                } else {
                    validate = {...validate, [va.option]: [va.type, va.option, va.unit, va.id]}
                }
            } else {
                errorLeftSide += "Wrong dist var or wrong unit ("+va.option+")\n"
            }
        } 
        // check messures (left side)
        if(va.type === 'messure') {    
            c_messure++;            
            if(va.option === 'start' || va.option === 'dauer'
            || va.option === 'interval') {
                if(validate.hasOwnProperty(va.option)) {
                    errorLeftSide += va.option + " allready used, no doubbles ("+va.option+")\n"
                } else {
                    //console.log(va.unit)
                    //console.log(validate)
                    if(va.option==='start') { //&& (va.unit === 'bei Mischung') || va.unit === '1 min vor Mischung') {
                        if(va.unit === 'bei Mischung' || va.unit === '1 min vor Mischung') {
                            validate = {...validate, [va.option]: [va.type, va.option, va.unit, va.id]}
                        } else { errorLeftSide += va.option + " used with wrong unit ("+va.unit+")\n"}
                    } else if((va.option==='dauer' || va.option === 'interval') 
                            && va.unit === 'min') {
                        validate = {...validate, [va.option]: [va.type, va.option, va.unit, va.id]}
                    } else { errorLeftSide += va.option + " used with wrong unit ("+va.unit+")\n"} 
                }
            } else {
                errorLeftSide += `Leere Felder vorhanden, bitte überprüfe bei [${va.type}]\n`;
            }
        } 
    });
        
    if(!(c_abh === config.count.abh && c_unabh === config.count.unabh && c_dist >= config.count.dist && c_messure === config.count.messure)) {
        errorLeftSide += `Anzahl nicht korrekt: ${c_abh}-${c_unabh}-${c_dist}-${c_messure}\n`;
    }

    console.log('----- E R R O R   F I R S T   P A R T -----\n'+errorLeftSide+'------------------');
    
    if(errorLeftSide === "") { // START TUBES IF OTHER VALIDATION IS OK
        // prepare and store in format {ID1607511543208: ["unabh", "option", "3", "4"]}
        let tubesValidate = {}
        if(planTubes.length < config.tubesNeeded) {
            errorLeftSide = "Not enough Tubes, please add at least 3 \n"
            console.log(planTubes.length)
            console.log(errorLeftSide)
        }
        planTubes.map(tube => {
            Object.keys(tube).map((tubeVar,index) => {    
                const varObj = find(validate, function(obj) { return (obj.includes(tubeVar)) });
                let tubeArray = tubesValidate[tubeVar] ? tubesValidate[tubeVar].concat([tube[tubeVar]]) : [varObj[0],varObj[1],tube[tubeVar]];
                tubesValidate = {...tubesValidate, [tubeVar]: tubeArray}
            })
        });
        
        Object.keys(tubesValidate).map(tube=>{
            if(tubesValidate[tube][0] === 'abh') {
                console.log("abh var will not have value")
            } else if (tubesValidate[tube][0] === 'unabh') {
                console.log("unabh var ")
                let values = tubesValidate[tube].slice(2) // get values without var-type
                let interval = values[values.length-1]-values[values.length-2] // interval berechnen
                // this would be interval check -> deactivated since v 3.1.0
                // if(interval < 10) { errorUnabh += `Intervall zu tief, muss mind 10 sein, jetzt [${interval}]\n` }
                
                //einzelne werte der tube überprüfen 
                let inRangeCounter = 0; // counts values that are in range because if 3 are in range its also fine..
                let hasOneInRange = false;
                let usedValues = [];
                values.map((val, i) => {
                    val = parseInt(val)
                    const isFarEnough = usedValues.every((v) => {                       
                        //console.log(`check if ${v} >= ${val+5} OR ${v} <= ${val-5}`)
                        return ((v >= val+5 || v <= val-5))
                    })
                    console.log("usedValues", usedValues)
                    // const isNotTooClose = usedValues.some(v => (v + 5) <= val && (v - 5) >= val)
                    usedValues.push(val);
                    //console.log("is it far enough?", isFarEnough);
                    if(!isFarEnough) {
                        errorUnabh += "Values must differ at least 5 units from each other"
                    }

                    if(val >= 30 && val <= 45) { hasOneInRange = true }
                    
                    // if(val >= 0 && val <= 50) { inRangeCounter++ } // -- deactivated since 3.1.0
                    console.log("check:", val);

                    //check for the intervall -- deactivated since 3.1.0
                    // if(i!==0) {
                    //     (val - interval == values[i-1]) ? errorUnabh += "" // don't use === here
                    //     : errorUnabh += `wrong intervall on ${val} - ${interval}, must result in prev val of ${values[i-1]}\n`
                    // }
                })
                if(!hasOneInRange) {
                    errorUnabh += "one tube must be between 35 - 45";
                }
                // if(inRangeCounter < 3 ) {
                //     errorUnabh += "less than 3 values are in the given range of 0 - 50";
                // }
            } else if (tubesValidate[tube][0] === 'dist') {
                let values = tubesValidate[tube].slice(2) // get values without var-type
                values[0] <= 0 && (errorDist += `wähle eine höhere anzahl: ${tubesValidate[tube][0]} / 0\n`); 
                values.map((val, i) => {
                    if(i!==0) {
                        !(val == values[i-1]) &&  (errorDist += `eingaben sollen konstant sein, überprüfe: ${values[i-1]} / ${val}\n`);
                    }
                });
            } else if (tubesValidate[tube][0] === 'messure') {
                console.log("tubesValidate: ", tubesValidate);
                if(tubesValidate[tube][1] === 'start') {
                    console.log('       -- validate start')
                    let values = tubesValidate[tube].slice(2) // get values without var-type
                    values.map((startVal, i) => {
                        (startVal != values[0]) && (errorMessure += "Alle Messungen müssen den gleichen Sartpunkt haben\n");
                        if(startVal != 0 && startVal != -1) { // don't use ===
                            errorMessure += "Startpunkt muss zwischen 0 und -60 sekunden liegen\n"
                        }
                        // (startVal > 0 || startVal < -60 ) && 
                        //     (errorMessure += "Startpunkt muss zwischen 0 und -60 sekunden liegen\n");
                    });
                
                } else if(tubesValidate[tube][1] === 'dauer') {
                    let values = tubesValidate[tube].slice(2) // get values without var-type
                    const varInterval = find(tubesValidate, function(obj) { return (obj.includes("interval")) })[3];
                    (values[0] <= 0) && (errorMessure += "Dauer erhöhen, soll grösser als 0 sein\n");
                    (values[0] < varInterval*2) && (errorMessure += "Dauer muss mind. 2xIntervall sein\n");
                    values.map((startVal, i) => {
                        (startVal != values[0]) && (errorMessure += "Alle Messungen müssen die gleiche Dauer haben\n");
                    });
                } else if(tubesValidate[tube][1] === 'interval') {
                    console.log('       -- validate interval')
                    let values = tubesValidate[tube].slice(2) // get values without var-type
                    values.map((startVal, i) => {
                        (startVal != values[0]) && (errorMessure += "Alle Messungen müssen den gleichen Intervall haben\n");
                    });
                }
            
            } 
        });

        console.log('----- E R R O R   U N A B H -----\n'+errorUnabh+'------------------');
        console.log('----- E R R O R   D I S T  -----\n'+errorDist+'------------------');
        console.log('----- E R R O R   M E S S U R E  -----\n'+errorMessure+'------------------');
    } // END TUBES
    //console.log(errorLeftSide==="" && errorUnabh ==="" && errorDist === "" && errorMessure === "");
    if(errorLeftSide==="" && errorUnabh ==="" && errorDist === "" && errorMessure === "") {
        console.log("[CORRECT: Aufgabe TEMPERATURE panning was correct]")
    } else {console.log("[VALIDATE ERR: Aufgabe TEMPERATURE panning was not correct]")}
    
    return (errorLeftSide==="" && errorUnabh ==="" && errorDist === "" && errorMessure === "")
    
    // VALIDATION OF TEMPERATURE END           
}

export const c_phvalue = (planVariables, planTubes) => {
    console.log("------ VALIDATE THE PLANING [PHVALUE] ----------\n--------------- P H V A L U E --------------")

    console.log("PLAN  VARIABLE OBJ", planVariables)
    console.log("PLAN  TUBES OBJ", planTubes)

    const config = {
        tubesNeeded: 3,
        abh: {option: 'enzymakt', unit: 'Schaumhöhe in cm'},
        unabh: {option: 'phwertloesung', unit: 'pH-Wert'},
        messDau: {min: 5, max: 15},
        messInt: {min: 0.5, max: 3},
        corrDist: [
            {option: "kartoffel", unit: "ml", must: true},
            {option: "wasserstoff", unit: "ml", must: true},
            {option: "temperature", unit: "°C", must: false}
        ],
        count: {
            abh: 1,
            unabh: 1,
            dist: 2,
            messure: 3
        }
    }

    let errorLeftSide = "";
    let errorUnabh = "";
    let errorDist = "";
    let errorMessure = "";
    let validate = {}
    let c_abh = 0;
    let c_unabh = 0;
    let c_dist = 0;
    let c_messure = 0;
    
    planVariables.map(va => {        
        // check abh vars (left side)  
        console.log("all va options:", va);
        if(va.type === 'abh') {
            c_abh++;
            if((va.option === config.abh.option && va.unit === config.abh.unit)            
            //&& va.unitCorrect == 'true'
            && !validate[va.option]) {
                console.log("abh true")
                console.log(va.option)
                validate = {...validate, [va.option]: [va.type, va.option, va.unit, va.id]}
                console.log(validate)
            } else {
                errorLeftSide += "Wrong abh var or wrong unit ("+va.option+")\n"
            }
        }
        // check unabh vars (left side)
        if(va.type === 'unabh') {
            c_unabh++;
            if(va.option === config.unabh.option && va.unit === config.unabh.unit 
            && !validate[va.option]) {
                validate = {...validate, [va.option]: [va.type, va.option, va.unit, va.id]}
            } else {
                errorLeftSide += "Wrong unabh var or wrong unit ("+va.option+")\n"
            }
        }  
        // check unabh vars (left side)
        if(va.type === 'dist') {      
            config.corrDist.map(dist => {
                if(dist.option === va.option) {
                    (dist.unit !== va.unit) && (errorLeftSide = `Wrong unit for dist var ${va.option}`);
                    (dist.must) && c_dist++
                } 
            })
            if(validate.hasOwnProperty(va.option)) { // check if this prop already exists (no doubles)
                errorLeftSide +=  va.option + " allready used, no doubbles ("+va.option+") \n"
            } else {
                validate = {...validate, [va.option]: [va.type, va.option, va.unit, va.id]}
            }
        } 
      
        // if(va.type === 'dist') {      
        //     c_dist++;       
        //     if(va.option === 'kartoffel' || va.option === 'wasserstoff'
        //     && va.unit === 'ml') {
        //         if(validate.hasOwnProperty(va.option)) { // check if this prop already exists (no doublles)
        //             errorLeftSide +=  va.option + " allready used, no doubbles ("+va.option+") \n"
        //         } else {
        //             validate = {...validate, [va.option]: [va.type, va.option, va.unit, va.id]}
        //         }
        //     } else {
        //         errorLeftSide += "Wrong dist var or wrong unit ("+va.option+")\n"
        //     }
        // }
console.log("VALIDATE : ----- >", validate )
        // check messures (left side)
        if(va.type === 'messure') {    
            c_messure++;            
            if(va.option === 'start' || va.option === 'dauer'
            || va.option === 'interval') {
                if(validate.hasOwnProperty(va.option)) {
                    errorLeftSide += va.option + " allready used, no doubbles ("+va.option+")\n"
                } else {
                    //console.log(va.unit)
                    //console.log(validate)
                    if(va.option==='start') { //&& (va.unit === 'bei Mischung') || va.unit === '1 min vor Mischung') {
                        if(va.unit === 'bei Mischung' || va.unit === '1 min vor Mischung') {
                            validate = {...validate, [va.option]: [va.type, va.option, va.unit, va.id]}
                        } else { errorLeftSide += va.option + " used with wrong unit ("+va.unit+")\n"}
                    } else if((va.option==='dauer' || va.option === 'interval') 
                            && va.unit === 'min') {
                        validate = {...validate, [va.option]: [va.type, va.option, va.unit, va.id]}
                    } else { errorLeftSide += va.option + " used with wrong unit ("+va.unit+")\n"} 
                }
            } else {
                errorLeftSide += `Leere Felder vorhanden, bitte überprüfe bei [${va.type}]\n`;
            }
        } 
    });
        
    if(!(c_abh === config.count.abh && c_unabh === config.count.unabh && c_dist >= config.count.dist && c_messure === config.count.messure)) {
        errorLeftSide += `Anzahl nicht korrekt: ${c_abh}-${c_unabh}-${c_dist}-${c_messure}\n`;
    }

    console.log('----- E R R O R   F I R S T   P A R T -----\n'+errorLeftSide+'------------------');
    
    if(errorLeftSide === "") { // START TUBES IF OTHER VALIDATION IS OK
        // prepare and store in format {ID1607511543208: ["unabh", "option", "3", "4"]}
        let tubesValidate = {}
        if(planTubes.length < config.tubesNeeded) {
            errorLeftSide = `Not enough Tubes, please add at least ${config.tubesNeeded} \n`
            console.log(planTubes.length)
            console.log(errorLeftSide)
        }
        planTubes.map(tube => {
            Object.keys(tube).map((tubeVar,index) => {    
                const varObj = find(validate, function(obj) { return (obj.includes(tubeVar)) });
                let tubeArray = tubesValidate[tubeVar] ? tubesValidate[tubeVar].concat([tube[tubeVar]]) : [varObj[0],varObj[1],tube[tubeVar]];
                tubesValidate = {...tubesValidate, [tubeVar]: tubeArray}
            })
        });
        
        Object.keys(tubesValidate).map(tube => {
            if(tubesValidate[tube][0] === 'abh') {
                console.log("abh var will not have value")
            } else if (tubesValidate[tube][0] === 'unabh') {
                console.log("unabh var ")
                let values = tubesValidate[tube].slice(2) // get values without var-type
                //let interval = values[values.length-1]-values[values.length-2] // interval berechnen
                //if(interval < 10) { errorUnabh += `Intervall zu tief, muss mind 10 sein, jetzt [${interval}]\n` }
                
                // !(values[0] >= 10) && (errorUnabh += `Startpunkt zu tief, muss mind 10 sein, jetzt [${values[0]}]\n`)
                // intervalle check (not used for piloting on 7.1.2021)
                //!(interval <= ((50 - values[0])/values.length)) // maxWert - ersterWert / anzahl Tubes 
                //    && (errorUnabh += `intervall not possible (${(50 - values[1])/values.length})\n`);
                
                //einzelne werte der tube überprüfen 
                let valMatch1 = false; // used for checking if a value is mached
                let valMatch2 = false;
                let valMatch3 = false;
                values.map((val, i) => {
                    console.log("check:", val);
                    if(val >= 1 && val <=5)  { valMatch1 = true }
                    if(val >= 6 && val <=8)  { valMatch2 = true }
                    if(val >= 9 && val <=14) { valMatch3 = true }
                })
                if(!(valMatch1 && valMatch2 && valMatch3)) {
                    errorUnabh += "there have to be a values in each range (0-6/7/8-14)";
                }
            } else if (tubesValidate[tube][0] === 'dist') {
                let values = tubesValidate[tube].slice(2) // get values without var-type
                values[0] <= 0 && (errorDist += `wähle eine höhere anzahl: ${tubesValidate[tube][0]} / 0\n`); 
                values.map((val, i) => {
                    if(val < 1 && val > 5) {
                        errorDist += `dist val muss zwischen 1-5 sein. gewählt: ${val}\n`
                    }
                    if(i!==0) {
                        !(val == values[i-1]) &&  (errorDist += `eingaben sollen konstant sein, überprüfe: ${values[i-1]} / ${val}\n`);
                    }
                });
            } else if (tubesValidate[tube][0] === 'messure') {
                console.log("tubesValidate: ", tubesValidate);
                if(tubesValidate[tube][1] === 'start') {
                    console.log('       -- validate start')
                    let values = tubesValidate[tube].slice(2) // get values without var-type
                    values.map((startVal, i) => {
                        (startVal != values[0]) && (errorMessure += "Alle Messungen müssen den gleichen Sartpunkt haben\n");
                        if(startVal != 0 && startVal != -1) { // don't use ===
                            errorMessure += "Startpunkt muss zwischen 0 und -60 sekunden liegen\n"
                        }
                        // (startVal > 0 || startVal < -60 ) && 
                        //     (errorMessure += "Startpunkt muss zwischen 0 und -60 sekunden liegen\n");
                    });
                
                } else if(tubesValidate[tube][1] === 'dauer') {
                    let values = tubesValidate[tube].slice(2) // get values without var-type
                    const varInterval = find(tubesValidate, function(obj) { return (obj.includes("interval")) })[3];
                    (values[0] <= 0) && (errorMessure += "Dauer erhöhen, soll grösser als 0 sein\n");
                    if(values[0] < config.messDau.min && values[0] > config.messDau.max) {
                        errorMessure += `Dauer muss zwischen ${config.messDau.min} und ${config.messDau.max}\n`
                    }
                    (values[0] < varInterval*2) && (errorMessure += "Dauer muss mind. 2xIntervall sein\n");
                    values.map((startVal, i) => {
                        (startVal != values[0]) && (errorMessure += "Alle Messungen müssen die gleiche Dauer haben\n");
                    });
                } else if(tubesValidate[tube][1] === 'interval') {
                    console.log('       -- validate interval')
                    let values = tubesValidate[tube].slice(2) // get values without var-type
                    values.map((startVal, i) => {
                        if(startVal < config.messInt.min && startVal > config.messInt.max) {
                            errorMessure += `Intervall muss zwischen ${config.messInt.min} und ${config.messInt.max}\n`
                        }
                        (startVal != values[0]) && (errorMessure += "Alle Messungen müssen den gleichen Intervall haben\n");
                    });
                }
            
            } 
        });

        console.log('----- E R R O R   U N A B H -----\n'+errorUnabh+'------------------');
        console.log('----- E R R O R   D I S T  -----\n'+errorDist+'------------------');
        console.log('----- E R R O R   M E S S U R E  -----\n'+errorMessure+'------------------');
    } // END TUBES
    //console.log(errorLeftSide==="" && errorUnabh ==="" && errorDist === "" && errorMessure === "");
    if(errorLeftSide==="" && errorUnabh ==="" && errorDist === "" && errorMessure === "") {
        console.log("[CORRECT: Aufgabe PHVALUE panning was correct]")
    } else {console.log("[VALIDATE ERR: Aufgabe PHVALUE panning was not correct]")}
    
    return (errorLeftSide==="" && errorUnabh ==="" && errorDist === "" && errorMessure === "")
    
    // VALIDATION OF PHVALUE END           
}

export const c_activation_energy = (planVariables, planTubes) => {
    console.log("------ VALIDATE THE PLANING [ACTIVATION ENERGY] ----------\n--------------- P H V A L U E --------------")

    console.log("PLAN  VARIABLE OBJ", planVariables)
    console.log("PLAN  TUBES OBJ", planTubes)

    const config = {
        tubesNeeded: 2,
        abh: {option: 'ammoniak', unit: 'pH-Wert: Farbumschlag von Gelb nach Blau'},
        unabh: {option: 'urease', unit: 'ml'},
        corrDist: [
            {option: "harnstoff", unit: "ml", must: true},
            {option: "temp", unit: "°C", must: true},
            {option: "bromthymolblau", unit: "ml", must: false},
        ],
        messCorrValues: [
            "bei Zugabe der Ureaselösung",
            "nach Zugabe der Ureaselösung"
        ],
        messDau: {min: 2, max: 15},
        messInt: {min: 0.5, max: 2},
        count: {
            abh: 1,
            unabh: 1,
            dist: 2,
            messure: 1,
        }
    }
    
    let errorLeftSide = "";
    let errorUnabh = "";
    let errorDist = "";
    let errorMessure = "";
    let validate = {}
    let c_abh = 0;
    let c_unabh = 0;
    let c_dist = 0;
    let c_messure = 0;
    
    planVariables.map(va => {        
        // check abh vars (left side)  
        console.log("all va options:", va);
        if(va.type === 'abh') {
            c_abh++;
            if((va.option === config.abh.option && va.unit === config.abh.unit)            
            //&& va.unitCorrect == 'true'
            && !validate[va.option]) {
                console.log("abh true")
                console.log(va.option)
                validate = {...validate, [va.option]: [va.type, va.option, va.unit, va.id]}
                console.log(validate)
            } else {
                errorLeftSide += "Wrong abh var or wrong unit ("+va.option+")\n"
            }
        }
        // check unabh vars (left side)
        if(va.type === 'unabh') {
            c_unabh++;
            if(va.option === config.unabh.option && va.unit === config.unabh.unit 
            && !validate[va.option]) {
                validate = {...validate, [va.option]: [va.type, va.option, va.unit, va.id]}
            } else {
                errorLeftSide += "Wrong unabh var or wrong unit ("+va.option+")\n"
            }
        }  
        // check unabh vars (left side)
        if(va.type === 'dist') {      
            config.corrDist.map(dist => {
                if(dist.option === va.option) {
                    (dist.unit !== va.unit) && (errorLeftSide = `Wrong unit for dist var ${va.option}`);
                    (dist.must) && c_dist++
                    console.log(dist.must)
                } 
            })
            if(validate.hasOwnProperty(va.option)) { // check if this prop already exists (no doubles)
                errorLeftSide +=  va.option + " allready used, no doubbles ("+va.option+") \n"
            } else {
                validate = {...validate, [va.option]: [va.type, va.option, va.unit, va.id]}
            }
        } 
      
       
console.log("VALIDATE : ----- >", validate )
        // check messures (left side)
        if(va.type === 'messure') {    
            c_messure++;            
            if(va.option === 'start' || va.option === 'dauer'
            || va.option === 'interval') {
                if(validate.hasOwnProperty(va.option)) {
                    errorLeftSide += va.option + " allready used, no doubbles ("+va.option+")\n"
                } else {
                    //console.log(va.unit)
                    //console.log(validate)
                    if(va.option==='start') { //&& (va.unit === 'bei Mischung') || va.unit === '1 min vor Mischung') {
                        //if(va.unit === config.messCorrValues[0] || va.unit === config.messCorrValues[1]) {
                        if(config.messCorrValues.includes(va.unit)) {
                            validate = {...validate, [va.option]: [va.type, va.option, va.unit, va.id]}
                        } else { errorLeftSide += va.option + " used with wrong unit ("+va.unit+")\n"}
                    } else if((va.option==='dauer' || va.option === 'interval') 
                            && va.unit === 'min') {
                        validate = {...validate, [va.option]: [va.type, va.option, va.unit, va.id]}
                    } else { errorLeftSide += va.option + " used with wrong unit ("+va.unit+")\n"} 
                }
            } else {
                errorLeftSide += `Leere Felder vorhanden, bitte überprüfe bei [${va.type}]\n`;
            }
        } 
    });
        
    if(!(c_abh === config.count.abh && c_unabh === config.count.unabh && c_dist >= config.count.dist && c_messure === config.count.messure)) {
        errorLeftSide += `Anzahl nicht korrekt: ${c_abh}-${c_unabh}-${c_dist}-${c_messure}\n`;
    }

    console.log('----- E R R O R   F I R S T   P A R T -----\n'+errorLeftSide+'------------------');
    
    if(errorLeftSide === "") { // START TUBES IF OTHER VALIDATION IS OK
        // prepare and store in format {ID1607511543208: ["unabh", "option", "3", "4"]}
        let tubesValidate = {}
        if(planTubes.length < config.tubesNeeded) {
            errorLeftSide = `Not enough Tubes, please add at least ${config.tubesNeeded} \n`
            console.log(planTubes.length)
            console.log(errorLeftSide)
        }
        planTubes.map(tube => {
            Object.keys(tube).map((tubeVar,index) => {    
                
                const varObj = find(validate, function(obj) { return (obj.includes(tubeVar)) });
                
                let tubeArray = tubesValidate[tubeVar] ? tubesValidate[tubeVar].concat([tube[tubeVar]]) : [varObj[0],varObj[1],tube[tubeVar]];
                tubesValidate = {...tubesValidate, [tubeVar]: tubeArray}
            })
        });
        
        Object.keys(tubesValidate).map(tube => {
            if(tubesValidate[tube][0] === 'abh') {
                console.log("abh var will not have value")
            } else if (tubesValidate[tube][0] === 'unabh') {
                console.log("unabh var ")
                let values = tubesValidate[tube].slice(2) // get values without var-type
                //let interval = values[values.length-1]-values[values.length-2] // interval berechnen
                //if(interval < 10) { errorUnabh += `Intervall zu tief, muss mind 10 sein, jetzt [${interval}]\n` }
                
                // !(values[0] >= 10) && (errorUnabh += `Startpunkt zu tief, muss mind 10 sein, jetzt [${values[0]}]\n`)
                // intervalle check (not used for piloting on 7.1.2021)
                //!(interval <= ((50 - values[0])/values.length)) // maxWert - ersterWert / anzahl Tubes 
                //    && (errorUnabh += `intervall not possible (${(50 - values[1])/values.length})\n`);
                
                //einzelne werte der tube überprüfen 
                let valMatch1 = false; // used for checking if a value is mached
                let valMatch2 = false;
                values.map((val, i) => {
                    console.log("check:", val);
                    if(val >= 0.5 && val <=5)  { valMatch1 = true }
                    if(val == 0)             { valMatch2 = true }
                })
                if(!(valMatch1 && valMatch2)) {
                    errorUnabh += "there have to be a values in each range (0/0.5-5)";
                }
            } else if (tubesValidate[tube][0] === 'dist') {
                let values = tubesValidate[tube].slice(2) // get values without var-type
                // values[0] <= 0 && (errorDist += `wähle eine höhere anzahl: ${tubesValidate[tube][0]} / 0\n`); 
                console.log("SEE TUBES HERE: ", tubesValidate[tube]);
               
                values.map((val, i) => {
                    if(tubesValidate[tube][1] === 'temp') { // check if temp has the right range
                        console.log("-- check temp --")
                        if(val < 15 || val > 35) {
                            errorDist += `dist val für temp muss zwischen 15-35. gewählt: ${val}\n`
                        }
                    }

                    if(tubesValidate[tube][1] === 'bromthymolblau') { // check if temp has the right range
                        console.log("-- check bromthymolblau --")
                        if(val < 0.01 || val > 5) {
                            errorDist += `dist val für bromthymolblau muss zwischen 0.01-5. gewählt: ${val}\n`
                        }
                    }

                    if(i!==0) {
                        !(val == values[i-1]) &&  (errorDist += `eingaben sollen konstant sein, überprüfe: ${values[i-1]} / ${val}\n`);
                    }
                });
            } else if (tubesValidate[tube][0] === 'messure') {
                console.log("tubesValidate: ", tubesValidate);
                if(tubesValidate[tube][1] === 'start') {
                    console.log('       -- validate start')
                    let values = tubesValidate[tube].slice(2) // get values without var-type
                    values.map((startVal, i) => {
                        (startVal != values[0]) && (errorMessure += "Alle Messungen müssen den gleichen Sartpunkt haben\n");
                        // if(startVal != 0 && startVal != -1) { // don't use ===
                        //     errorMessure += "Startpunkt muss zwischen 0 und -60 sekunden liegen\n"
                        // }
                        // (startVal > 0 || startVal < -60 ) && 
                        //     (errorMessure += "Startpunkt muss zwischen 0 und -60 sekunden liegen\n");
                    });
                
                } else if(tubesValidate[tube][1] === 'dauer') {
                    let values = tubesValidate[tube].slice(2) // get values without var-type
                    const varInterval = find(tubesValidate, function(obj) { return (obj.includes("interval")) })[3];
                    (values[0] <= 0) && (errorMessure += "Dauer erhöhen, soll grösser als 0 sein\n");
                    if(values[0] < config.messDau.min && values[0] > config.messDau.max) {
                        errorMessure += `Dauer muss zwischen ${config.messDau.min} und ${config.messDau.max}\n`
                    }
                    (values[0] < varInterval*2) && (errorMessure += "Dauer muss mind. 2xIntervall sein\n");
                    values.map((startVal, i) => {
                        (startVal != values[0]) && (errorMessure += "Alle Messungen müssen die gleiche Dauer haben\n");
                    });
                } else if(tubesValidate[tube][1] === 'interval') {
                    console.log('       -- validate interval')
                    let values = tubesValidate[tube].slice(2) // get values without var-type
                    values.map((startVal, i) => {
                        if(startVal < config.messInt.min && startVal > config.messInt.max) {
                            errorMessure += `Intervall muss zwischen ${config.messInt.min} und ${config.messInt.max}\n`
                        }
                        (startVal != values[0]) && (errorMessure += "Alle Messungen müssen den gleichen Intervall haben\n");
                    });
                }
            
            } 
        });

        console.log('----- E R R O R   U N A B H -----\n'+errorUnabh+'------------------');
        console.log('----- E R R O R   D I S T  -----\n'+errorDist+'------------------');
        console.log('----- E R R O R   M E S S U R E  -----\n'+errorMessure+'------------------');
    } // END TUBES
    //console.log(errorLeftSide==="" && errorUnabh ==="" && errorDist === "" && errorMessure === "");
    if(errorLeftSide==="" && errorUnabh ==="" && errorDist === "" && errorMessure === "") {
        console.log("[CORRECT: Aufgabe ACTIVATION ENERGY panning was correct]")
    } else {console.log("[VALIDATE ERR: Aufgabe ACTIVATION ENERGY panning was not correct]")}
    
    return (errorLeftSide==="" && errorUnabh ==="" && errorDist === "" && errorMessure === "")
    
    // VALIDATION OF ACTIVATION ENERGY END         
}

export const c_inhibition = (planVariables, planTubes) => {
    console.log("------ VALIDATE THE PLANING [INHIBITION] ----------\n--------------- I N H I B I T I O N--------------")

    console.log("PLAN  VARIABLE OBJ", planVariables)
    console.log("PLAN  TUBES OBJ", planTubes)

    const config = {
        tubesNeeded: 2,
        abh: {option: 'stkonz', unit: 'Färbung (gelb-bräunlich/blau-violett)'},
        unabh: {option: 'hemmstoff', unit: 'ml'},
        corrDist: [
            {option: "amylase", unit: "ml", must: true},
            {option: "stloesung", unit: "ml", must: true},
            {option: "temp", unit: "°C", must: true},
            {option: "lugol", unit: "ml", must: false},
        ],
        stlRange: [1, 6],
        amylaseRange: [1, 6],
        lugolRange: [0.1, 3],
        tempRange: [20, 45],
        messCorrValues: [
            "nach Zugabe der Stärkelösung",
        ],
        messDau: {min: 3, max: 20},
        messInt: {min: 0.5, max: 2},
        count: {
            abh: 1,
            unabh: 1,
            dist: 3,
            messure: 1
        }
    }

    let errorLeftSide = "";
    let errorUnabh = "";
    let errorDist = "";
    let errorMessure = "";
    let validate = {}
    let c_abh = 0;
    let c_unabh = 0;
    let c_dist = 0;
    let c_messure = 0;
    
    planVariables.map(va => {        
        // check abh vars (left side)  
        console.log("all va options:", va);
        if(va.type === 'abh') {
            c_abh++;
            if((va.option === config.abh.option && va.unit === config.abh.unit)            
            //&& va.unitCorrect == 'true'
            && !validate[va.option]) {
                console.log("abh true")
                console.log(va.option)
                validate = {...validate, [va.option]: [va.type, va.option, va.unit, va.id]}
                console.log(validate)
            } else {
                errorLeftSide += "Wrong abh var or wrong unit ("+va.option+")\n"
            }
        }
        // check unabh vars (left side)
        if(va.type === 'unabh') {
            c_unabh++;
            if(va.option === config.unabh.option && va.unit === config.unabh.unit 
            && !validate[va.option]) {
                validate = {...validate, [va.option]: [va.type, va.option, va.unit, va.id]}
            } else {
                errorLeftSide += "Wrong unabh var or wrong unit ("+va.option+")\n"
            }
        }  
        // check unabh vars (left side)
        if(va.type === 'dist') {      
            config.corrDist.map(dist => {
                if(dist.option === va.option) {
                    (dist.unit !== va.unit) && (errorLeftSide = `Wrong unit for dist var ${va.option}`);
                    (dist.must) && c_dist++
                    console.log(dist.must)
                } 
            })
            if(validate.hasOwnProperty(va.option)) { // check if this prop already exists (no doubles)
                errorLeftSide +=  va.option + " allready used, no doubbles ("+va.option+") \n"
            } else {
                validate = {...validate, [va.option]: [va.type, va.option, va.unit, va.id]}
            }
        } 
      
       
console.log("VALIDATE : ----- >", validate )
        // check messures (left side)
        if(va.type === 'messure') {    
            c_messure++;            
            if(va.option === 'start' || va.option === 'dauer'
            || va.option === 'interval') {
                if(validate.hasOwnProperty(va.option)) {
                    errorLeftSide += va.option + " allready used, no doubbles ("+va.option+")\n"
                } else {
                    //console.log(va.unit)
                    //console.log(validate)
                    if(va.option==='start') { //&& (va.unit === 'bei Mischung') || va.unit === '1 min vor Mischung') {
                        if(config.messCorrValues.includes(va.unit)) { //|| va.unit === '1 min vor Mischung' || va.unit === '1 min nach Mischung') {
                            validate = {...validate, [va.option]: [va.type, va.option, va.unit, va.id]}
                        } else { errorLeftSide += va.option + " used with wrong unit ("+va.unit+")\n"}
                    } else if((va.option==='dauer' || va.option === 'interval') 
                            && va.unit === 'min') {
                        validate = {...validate, [va.option]: [va.type, va.option, va.unit, va.id]}
                    } else { errorLeftSide += va.option + " used with wrong unit ("+va.unit+")\n"} 
                }
            } else {
                errorLeftSide += `Leere Felder vorhanden, bitte überprüfe bei [${va.type}]\n`;
            }
        } 
    });
    
    if(!(c_abh === config.count.abh && c_unabh === config.count.unabh && c_dist >= config.count.dist && c_messure === config.count.messure)) {
        errorLeftSide += `Anzahl nicht korrekt: ${c_abh}-${c_unabh}-${c_dist}-${c_messure}\n`;
    }

    console.log('----- E R R O R   F I R S T   P A R T -----\n'+errorLeftSide+'------------------');
    
    if(errorLeftSide === "") { // START TUBES IF OTHER VALIDATION IS OK
        // prepare and store in format {ID1607511543208: ["unabh", "option", "3", "4"]}
        let tubesValidate = {}
        if(planTubes.length < config.tubesNeeded) {
            errorLeftSide = `Not enough Tubes, please add at least ${config.tubesNeeded} \n`
            console.log(planTubes.length)
            console.log(errorLeftSide)
        }
        planTubes.map(tube => {
            Object.keys(tube).map((tubeVar,index) => {    
                const varObj = find(validate, function(obj) { return (obj.includes(tubeVar)) });
                let tubeArray = tubesValidate[tubeVar] ? tubesValidate[tubeVar].concat([tube[tubeVar]]) : [varObj[0],varObj[1],tube[tubeVar]];
                tubesValidate = {...tubesValidate, [tubeVar]: tubeArray}
            })
        });
        
        Object.keys(tubesValidate).map(tube => {
            if(tubesValidate[tube][0] === 'abh') {
                console.log("abh var will not have value")
            } else if (tubesValidate[tube][0] === 'unabh') {
                console.log("unabh var ")
                let values = tubesValidate[tube].slice(2) // get values without var-type
                //let interval = values[values.length-1]-values[values.length-2] // interval berechnen
                //if(interval < 10) { errorUnabh += `Intervall zu tief, muss mind 10 sein, jetzt [${interval}]\n` }
                
                // !(values[0] >= 10) && (errorUnabh += `Startpunkt zu tief, muss mind 10 sein, jetzt [${values[0]}]\n`)
                // intervalle check (not used for piloting on 7.1.2021)
                //!(interval <= ((50 - values[0])/values.length)) // maxWert - ersterWert / anzahl Tubes 
                //    && (errorUnabh += `intervall not possible (${(50 - values[1])/values.length})\n`);
                
                //einzelne werte der tube überprüfen 
                let valMatch1 = false; // used for checking if a value is mached
                let valMatch2 = false;
                values.map((val, i) => {
                    console.log("check:", val);
                    if(val >= 0.1 && val <=5)  { valMatch1 = true }
                    if(val == 0)             { valMatch2 = true }
                })
                if(!(valMatch1 && valMatch2)) {
                    errorUnabh += "there have to be a values in each range (0/0.1-5)";
                }
            } else if (tubesValidate[tube][0] === 'dist') {
                let values = tubesValidate[tube].slice(2) // get values without var-type
                // values[0] <= 0 && (errorDist += `wähle eine höhere anzahl: ${tubesValidate[tube][0]} / 0\n`); 
                console.log("SEE TUBES HERE: ", tubesValidate[tube]);
               
                values.map((val, i) => {
                    if(tubesValidate[tube][1] === 'stloesung') { // check if stloesung has the right range
                        console.log("-- check stloesung --")
                        if(val < config.stlRange[0] || val > config.stlRange[1]) {
                            errorDist += `dist val für stloesung muss zwischen ${config.stlRange[0]} - ${config.stlRange[1]}. gewählt: ${val}\n`
                        }
                    }

                    if(tubesValidate[tube][1] === 'temp') { // check if temp has the right range
                        console.log("-- check temp --")
                        if(val < config.tempRange[0] || val > config.tempRange[1]) {
                            errorDist += `dist val für temp muss zwischen ${config.tempRange[0]} - ${config.tempRange[1]} gewählt: ${val}\n`
                        }
                    }

                    if(tubesValidate[tube][1] === 'lugol') { // check if temp has the right range
                        console.log("-- check lugol --")
                        if(val < config.lugolRange[0] || val > config.lugolRange[1]) {
                            errorDist += `dist val für lugol muss zwischen ${config.lugolRange[0]} - ${config.lugolRange[1]} gewählt: ${val}\n`
                        }
                    }
                   
                    if(tubesValidate[tube][1] === 'amylase') { // check if temp has the right range
                        console.log("-- check amylase --")
                        if(val < config.amylaseRange[0] || val > config.amylaseRange[1]) {
                            errorDist += `dist val für amylase muss zwischen ${config.amylaseRange[0]} - ${config.amylaseRange[1]} gewählt: ${val}\n`
                        }
                    }                   

                    if(i!==0) {
                        !(val == values[i-1]) &&  (errorDist += `eingaben sollen konstant sein, überprüfe: ${values[i-1]} / ${val}\n`);
                    }
                });
            } else if (tubesValidate[tube][0] === 'messure') {
                console.log("tubesValidate: ", tubesValidate);
                if(tubesValidate[tube][1] === 'start') {
                    console.log('       -- validate start')
                    let values = tubesValidate[tube].slice(2) // get values without var-type
                    values.map((startVal, i) => {
                        (startVal != values[0]) && (errorMessure += "Alle Messungen müssen den gleichen Sartpunkt haben\n");
                        // if(startVal != 0 && startVal != -1) { // don't use ===
                        //     errorMessure += "Startpunkt muss zwischen 0 und -60 sekunden liegen\n"
                        // }
                        // (startVal > 0 || startVal < -60 ) && 
                        //     (errorMessure += "Startpunkt muss zwischen 0 und -60 sekunden liegen\n");
                    });
                
                } else if(tubesValidate[tube][1] === 'dauer') {
                    let values = tubesValidate[tube].slice(2) // get values without var-type
                    const varInterval = find(tubesValidate, function(obj) { return (obj.includes("interval")) })[3];
                    (values[0] <= 0) && (errorMessure += "Dauer erhöhen, soll grösser als 0 sein\n");
                    if(values[0] < config.messDau.min && values[0] > config.messDau.max) {
                        errorMessure += `Dauer muss zwischen ${config.messDau.min} und ${config.messDau.max}\n`
                    }
                    (values[0] < varInterval*2) && (errorMessure += "Dauer muss mind. 2xIntervall sein\n");
                    values.map((startVal, i) => {
                        (startVal != values[0]) && (errorMessure += "Alle Messungen müssen die gleiche Dauer haben\n");
                    });
                } else if(tubesValidate[tube][1] === 'interval') {
                    console.log('       -- validate interval')
                    let values = tubesValidate[tube].slice(2) // get values without var-type
                    values.map((startVal, i) => {
                        if(startVal < config.messInt.min && startVal > config.messInt.max) {
                            errorMessure += `Intervall muss zwischen ${config.messInt.min} und ${config.messInt.max}\n`
                        }
                        (startVal != values[0]) && (errorMessure += "Alle Messungen müssen den gleichen Intervall haben\n");
                    });
                }
            
            } 
        });

        console.log('----- E R R O R   U N A B H -----\n'+errorUnabh+'------------------');
        console.log('----- E R R O R   D I S T  -----\n'+errorDist+'------------------');
        console.log('----- E R R O R   M E S S U R E  -----\n'+errorMessure+'------------------');
    } // END TUBES
    //console.log(errorLeftSide==="" && errorUnabh ==="" && errorDist === "" && errorMessure === "");
    if(errorLeftSide==="" && errorUnabh ==="" && errorDist === "" && errorMessure === "") {
        console.log("[CORRECT: Aufgabe INHIBITION panning was correct]")
    } else {console.log("[VALIDATE ERR: Aufgabe INHIBITION panning was not correct]")}
    
    return (errorLeftSide==="" && errorUnabh ==="" && errorDist === "" && errorMessure === "")
    
    // VALIDATION OF INHIBITION END 
}