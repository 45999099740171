import React, { useState, useMemo, useCallback } from 'react'
import FullPageLoader from '../components/UI/FullPageLoader/FullPageLoader'

const useFullPageLoader = () => {
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    return [
        useMemo(() => loading ? <FullPageLoader success={success} /> : null, [loading, success]),
        useCallback(() => setLoading(true),[]),
        useCallback(() => {
            setSuccess(true)
            setTimeout(() => {
                setLoading(false) 
            }, 550)
        }, [])
    ]
}

export default useFullPageLoader
