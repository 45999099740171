import React, {useState, useEffect} from 'react'
import api from '../../../../axios-api'
import { CSVLink, CSVDownload } from 'react-csv'
import Moment from 'moment';
// import { json2csv } from 'json2csv';

const DownloadButtons = () => {
    const [userData, setUserData] = useState(null);
    useEffect(() => {
        api.get('/backend/select_combine.php')
        .then(response => {
            let uData = response.data.users
            let csvData = []
            Object.keys(uData).map(key => {
                let obj = {User: key}
                uData[key].map((u, i) => {
                    let a = Moment(u.helpUseOpen);
                    let b = Moment(u.helpUseClose);
                    var diffTime = b.diff(a)
                    diffTime = Moment.utc(diffTime).format('mm:s');
                    console.log("uData[key]", uData[key] )
                    const sliderFB = u.helpUseSliderFb ? u.helpUseSliderFb.split(',') : ['', '', '']
                    let inObj = {
                        ...obj[u.helpUseType],
                        [u.helpUseSubType +"_"+ i]: {
                            [i+"_1_helpID"]: u.helpUseID,
                            // [i+"_2_Art"]: u.helpUseType,
                            // [i+"_3_Unterart"]: u.helpUseSubType,
                            [i+"_4_Start"]: u.helpUseOpen,
                            [i+"_5_Ende"]: u.helpUseClose,
                            [i+"_5_Dauer"]: diffTime,
                            //[i+"_6_Slider"]: u.helpUseSliderFb,
                            [i+"_6_Slider1"]: sliderFB[0].trim(),
                            [i+"_6_Slider2"]: sliderFB[1].trim(),
                            [i+"_6_Slider3"]: sliderFB[2].trim(),
                            [i+"_7_Buttons"]: u.helpUseButtonFb
                        }
                    }
                    obj[u.helpUseType] = inObj;
                })
                csvData.push(obj);
            })
            let keys = ['abh', 'unabh', 'dist', 'messure', 'repetitions']
            let arrayContainer = []
            csvData.map(user => {
                //console.log("user:", csvData)
                //console.log("user: ", user)
                let arrayInside = [user.User]
                keys.map(key => {
                    const empty = ['', '', '', '', '', '', '', '']
                    const subType = [empty, empty, empty]
                    if(user[key]) {
                        Object.keys(user[key]).map((st, i) => {
                            if(i < 3) { 
                                if(st.includes('hint')) {
                                    //subType[0] = user[key][st]
                                    subType[0] = Object.values(user[key][st])
                                } else if(st.includes('sample')) {
                                    subType[1] = Object.values(user[key][st])
                                } else if(st.includes('solution')) {
                                    subType[2] = Object.values(user[key][st])
                                }
                            } else {
                                // has addional fields 
                                subType.push(empty, empty, empty)
                                if(st.includes('hint')) {
                                    subType[3] = Object.values(user[key][st])
                                } else if(st.includes('sample')) {
                                    subType[4] = Object.values(user[key][st])
                                } else if(st.includes('solution')) {
                                    subType[5] = Object.values(user[key][st])
                                }
                            }
                        })
                    } 
            
                    arrayInside.push(subType);
                })
                arrayContainer.push(flatten(arrayInside))
            })
            //const flatten = (arrayContainer) =>  arrayContainer.reduce((flat, next) => flat.concat(next), []);

            //arrayContainer = flatten(arrayContainer);
            console.log("data for csv:", csvData)
            console.log("Array for csv:", arrayContainer)
            //console.log("Flattened Array Data:", flatten)

            setUserData(arrayContainer);

        })
    }, []);
        
    const flatten = function(arr, result = []) {
        for (let i = 0, length = arr.length; i < length; i++) {
          const value = arr[i];
          if (Array.isArray(value)) {
            flatten(value, result);
          } else {
            result.push(value);
          }
        }
        return result;
      };

    let content = null
    if(userData) {
        const headers_1 = [
            'User ID', 
                'Abhängige Variable', 
                    //hint    
                    '','','','','','','',
                    //sample
                    '','','','','','','','',
                    //solution
                    '','','','','','','','',
                'Unabhängige Variable', 
                    //hint    
                    '','','','','','','',
                    //sample
                    '','','','','','','','',
                    //solution
                    '','','','','','','','',
                'Störvariable', 
                    //hint    
                    '','','','','','','',
                    //sample
                    '','','','','','','','',
                    //solution
                    '','','','','','','','',
                'Messungen', 
                    //hint    
                    '','','','','','','',
                    //sample
                    '','','','','','','','',
                    //solution
                    '','','','','','','','',
                'Wiederholung', 
                    //hint    
                    '','','','','','','',
                    //sample
                    '','','','','','','','',
                    //solution
                    '','','','','','','','',
        ]
        const headers_2 = [
            '', 
                'Hinweis', '','','','','','','',
                'Beispiel','','','','','','','',
                'Lösung','','','','','','','',
                'Hinweis', '','','','','','','',
                'Beispiel','','','','','','','',
                'Lösung','','','','','','','',
                'Hinweis', '','','','','','','',
                'Beispiel','','','','','','','',
                'Lösung','','','','','','','',
                'Hinweis', '','','','','','','',
                'Beispiel','','','','','','','',
                'Lösung','','','','','','','',
                'Hinweis', '','','','','','','',
                'Beispiel','','','','','','','',
                'Lösung','','','','','','','',
        ]
        const headers_3 = [
            '', 
                //hint    
                'ID','Start','Ende','Dauer','Slider 1','Slider 2','Slider 3', 'Button',
                //sample
                'ID','Start','Ende','Dauer','Slider 1','Slider 2','Slider 3', 'Button',
                //solution
                'ID','Start','Ende','Dauer','Slider 1','Slider 2','Slider 3', 'Button',
                //hint    
                'ID','Start','Ende','Dauer','Slider 1','Slider 2','Slider 3', 'Button',
                //sample
                'ID','Start','Ende','Dauer','Slider 1','Slider 2','Slider 3', 'Button',
                //solution
                'ID','Start','Ende','Dauer','Slider 1','Slider 2','Slider 3', 'Button',
                //hint    
                'ID','Start','Ende','Dauer','Slider 1','Slider 2','Slider 3', 'Button',
                //sample
                'ID','Start','Ende','Dauer','Slider 1','Slider 2','Slider 3', 'Button',
                //solution
                'ID','Start','Ende','Dauer','Slider 1','Slider 2','Slider 3', 'Button',
                //hint    
                'ID','Start','Ende','Dauer','Slider 1','Slider 2','Slider 3', 'Button',
                //sample
                'ID','Start','Ende','Dauer','Slider 1','Slider 2','Slider 3', 'Button',
                //solution
                'ID','Start','Ende','Dauer','Slider 1','Slider 2','Slider 3', 'Button',
                //hint    
                'ID','Start','Ende','Dauer','Slider 1','Slider 2','Slider 3', 'Button',
                //sample
                'ID','Start','Ende','Dauer','Slider 1','Slider 2','Slider 3', 'Button',
                //solution
                'ID','Start','Ende','Dauer','Slider 1','Slider 2','Slider 3', 'Button',
        ]
        const csvArray = [headers_1, headers_2, headers_3,...userData]
        console.log("headers", headers_1)

        console.log("FINAL ARRAY FOR CSV", csvArray)
        content = <CSVLink 
                    filename={"diLuna_all_help_used.csv"}
                    className="btn btn-secondary btn-xs"
                    data={csvArray}>Liste mit allen Hilfen
                  </CSVLink>
    }
       

    return (
        <div>
            {
                
            
            }
            <h4>CSV Files generieren</h4>
            {content}
        </div>
    )
}

export default DownloadButtons
