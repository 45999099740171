import React, { useState, useCallback, useEffect } from 'react';
import api from '../../../../axios-api';

import { getFormData } from '../../../../shared/utility';
import HelpReviewSlider from './HelpReviewSlider/HelpReviewSlider';
import HelpReviewButtons from './HelpReviewButtons/HelpReviewButtons';

const answZielOr = [
    "… ich dachte, dass ich dadurch neues Wissen zum Planen von Experimenten bekomme.",
    "… ich dachte, dass ich so das Planen des Experiments besser schaffen kann als andere.",
    "… ich mich nicht durch falsche Ergebnisse blamieren möchte.",
    "… ich dachte, dass ich dadurch so viel wie möglich über das Planen von Experimenten lerne.",
    "… ich dachte, dass ich so zeignen kann, dass ich im Planen des Experiments gut bin.",
    "… niemand merken soll, wenn ich das Planen des Experiments nicht verstehe.",
]

const answBedarfOr = [
    "… ich eine <strong>Idee</strong> habe und diese <strong>überpüfen</strong> möchte.",
    "… ich zwar eine Ahnung habe, aber mit den <strong>Begriffen nicht klar</strong> komme.",
    "… ich <strong>keine Ahnung</strong> habe und <strong>mit Hilfe eines Beispiels verstehen</strong> will, was zu tun ist.",
    "… ich <strong>keine Ahnung</strong> habe und <strong>nachschauen</strong> will, was zu tun ist.",
    "… ich <strong>trotz Hinweis nicht weiter komme</strong>.",
    "… ich <strong>trotz Beispiellösung nicht weiter komme</strong>.",
    "… ich die Lösung <strong>abschreiben</strong> will.",
    "… ich einfach <strong>neugierig bin</strong>.",
    "… mir die <strong>anderen Hilfen nicht helfen</strong>.",
    "… ich diese <strong>Hilfe schon einmal nutzte</strong> und noch einmal <strong>etwas nachschauen</strong> will.",
]

const answForNo = [
    "… um nicht zu zeigen, wenn mir das Planen von Experimenten schwerer fällt als den anderen.",
    "… weil andere Schülerinnen und Schüler nicht denken sollen, ich sei dumm.",
    "… um zu zeigen, dass ich im Planen von Experimenten gut bin.",
    "… um zu zeigen, dass ich das Planen von Experimenten beherrsche.",
    "… weil ich keine Hilfe brauchte, weil ich es selbst lösen konnte.",
    "… weil ich nicht wusste, welche Hilfe ich nutzen soll.",
    "… weil ich nicht so viel lesen wollte.",
]

const HelpReview = (props) => {
    const [enableNext, setEnableNext] = useState(false)
    const [reviewInput, setReviewInput] = useState(null);
    const [sliderFitting, setSliderFitting] = useState(5);
    const [sliderUnderstandable, setSliderUnderstandable] = useState(5);
    const [sliderUse, setSliderUse] = useState(5);

    const [fittingMoved, setFittingMoved] = useState(false);
    const [understandableMoved, setUnderstandableMoved] = useState(false);
    const [useMoved, setUseMoved] = useState(false);

    const onReviewButtonClick = useCallback((value) => {
        if(reviewInput !== '') {
            setEnableNext(true);
        }
        console.log("changed:", enableNext)
        setReviewInput(value);
    }, [enableNext, reviewInput]);

    const onReviewInputChanged = useCallback((e) => {
        if(reviewInput !== '') {
            setEnableNext(true);
        }
        console.log("changed:", enableNext)
        setReviewInput(e.target.value)
    }, [enableNext, reviewInput]);

    const changeSlider = (e, which) => {

        switch (which) {
            case 'fitting':
                setFittingMoved(true)
                setSliderFitting(e.target.value)
                break
            case 'understand':
                setUnderstandableMoved(true)
                setSliderUnderstandable(e.target.value)
                break
            case 'use':
                setUseMoved(true)
                setSliderUse(e.target.value)
                break
            default:
                break
        }
    }

    const onSendReview = (mode) => {
        const exID = localStorage.getItem('exID');
        const data = {
            exID: exID,
        }
        
        if(props.reviewOn === 'bedarf') {
            let buttonFeedback = reviewInput;
            data.buttonFeedback = buttonFeedback;
            let dataToSend = getFormData(data);
        
            api.post("/api/add_btn_feedback.php", dataToSend)
            .then(response => {
                console.log(response.data)
            }).catch(error => {
                console.log("error when adding 'bedarfsorientierung' to help type");
                console.log(error);
            });
        } else {
            // Zielorientierung
            let sliderFeedback = sliderFitting+", "+sliderUnderstandable+", "+sliderUse;

            if(props.reviewOn === 'helpType' || props.reviewOn === 'helpTypeDirect') {
                if(props.reviewOn === 'helpTypeDirect') { // check if help is closed direct
                    data.closeHelpSession = true;
                }
                data.sliderFeedback = sliderFeedback;
                let dataToSend = getFormData(data);
                console.log("----- review saved on help type close ------ ")
                api.post("/api/close_help_type.php", dataToSend)
                .then(response => {
                    console.log(response.data)
                }).catch(error => {
                    console.log("error when closing the HELP USE");
                    console.log(error);
                });
            } else if(props.reviewOn === 'exercise' || props.reviewOn === 'notUsed') { 
                let buttonFeedback = reviewInput;
                data.buttonFeedback = buttonFeedback;
                let dataToSend = getFormData(data);
                console.log("----- review saved on exercise ------ ")
                api.post("/api/add_ex_help_review.php", dataToSend)
                .then(response => {
                    console.log('close active session added');
                    props.closeReviewEndPlaning();
                }).catch(error => {
                    console.log("error when adding help review to ex: add_ex_help_review.php", error);
                });
            }
        }
        
        
        props.closeReview()
        setEnableNext(false);
        setFittingMoved(false);
        setUnderstandableMoved(false);
        setUseMoved(false);
        setReviewInput(null);
    }
    
    let content = null;
    switch (props.reviewOn) {
        case 'bedarf':
            content = (
                <div>
                    <p>Ich habe diese Hilfe gewählt, weil …</p>
                    <HelpReviewButtons
                        answers={answBedarfOr}
                        selectedValue={reviewInput}
                        reviewButtonClicked={onReviewButtonClick}
                        onReviewInputChanged={onReviewInputChanged}
                        enableNext={enableNext}
                    />
                     <hr />
                    <button
                        className="float-right btn btn-secondary m-1" 
                        onClick={(e) => onSendReview()}
                        disabled={!enableNext} >
                        Zur Hilfe
                    </button>
                </div>
            )
            break;
        case 'helpType':
        case 'helpTypeDirect':
            content = (
                <div>
                    <HelpReviewSlider 
                        fitting={sliderFitting}
                        understandable={sliderUnderstandable}
                        use={sliderUse}
                        fittingMoved={fittingMoved}
                        understandableMoved={understandableMoved}
                        useMoved={useMoved}
                        changeSlider={changeSlider}
                    />
                    <button 
                        disabled={!(fittingMoved && understandableMoved && useMoved)}
                        onClick={(e) => onSendReview()}
                        className="btn btn-secondary m-1">
                    Abschliessen</button>
                </div>
            )
            break
        case 'exercise':
            content = (
                <div>
                    <p>Ich habe die Hilfe(n) genutzt, weil …</p>
                    <HelpReviewButtons
                        answers={answZielOr}
                        selectedValue={reviewInput}
                        reviewButtonClicked={onReviewButtonClick}
                        onReviewInputChanged={onReviewInputChanged}
                        onNextStep={(e) => onSendReview()}
                        enableNext={enableNext}
                        hasButton
                    />
                    
                </div>
            )
            break
        case 'notUsed':
            content = (
                <div>
                    <p>Warum hast du dich entschieden, keine Hilfen zu nutzen?<br/>
                    Klicke die Aussage an, die auf dich zutrifft.</p>
                    <p>Ich habe keine Hilfe(n) genutzt, …</p>
                    <HelpReviewButtons
                            answers={answForNo}
                            selectedValue={reviewInput}
                            reviewButtonClicked={onReviewButtonClick}
                            onReviewInputChanged={onReviewInputChanged}
                            onNextStep={(e) => onSendReview()}
                            enableNext={enableNext}
                            hasButton
                        />
                </div>)
                break
        default: 
                content = "nothing"
                break
    }
    return (
        <>
            {content}
        </>
    )
}

export default HelpReview;
