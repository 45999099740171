import React, {useState, useEffect} from 'react'
import useFb from '../../../../hooks/use-fb'
import HelpContentSub from './HelpContentSub/HelpContentSub'

const HelpContent = () => {
    const [data, setData] = useState(null);
    const {loader, error, sendRequest} = useFb()
    
    useEffect(() => {
        sendRequest(`/helpCenterData.json`, setData);
        console.log('requested')
    }, [sendRequest])

    let content = null
    content = data && Object.keys(data).map(hd => {
        return <HelpContentSub key={hd} data={data[hd]} category={hd} node={'/helpCenterData/'+hd} />
    })

    return (
        <div className="mb-4">
            {loader}
            {content}
        </div>
    )
}

export default HelpContent
