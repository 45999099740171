import React, {useState} from 'react'
import classes from './PanelUser.module.sass'
import PanelExercises from '../PanelExercises/PanelExercises'
import { convertNames } from '../../../../shared/utility'
const PanelUser = (props) => {

    console.log("SELECTED MODES:", props.modes)
    let userClasses = [classes.User]
    let extendedContent = null
    const [selectedMode, setSelectedMode] = useState(null)

    console.log(selectedMode)
    if(props.extended) {
        userClasses.push(classes.Extended)
        // extendedContent = props.modes.map(m => {
        //     return <div>{m}</div>
        // }) 
    }

    const buttons = [
        {name: "Aufgaben", value: "e" },
        // {name: "Hilfsessions", value: "s" },
        // {name: "Hilfen", value: "h" }
    ]
    const changeMode = (mode) => {
        if(!props.extended) {
            props.clicked(props.id)
        }
        setSelectedMode(mode)
    }
    return (
        <div className={userClasses.join(" ") + " row"}>
            <div className={"col-6 " + (props.extended && classes.Bold)} onClick={props.clicked}>
               Alter: {props.age} / Geschlecht: {props.gender} / Gruppe: {convertNames(props.group)}
            </div>
            <div className="col-6">
                {buttons.map(b => 
                    <button 
                        key={b.value}
                        //onClick={() => props.modeClick(b.value)} 
                        onClick={() => changeMode(b.value)} 
                        //className={classes.LetterButton + " " + (props.modes?.includes(b.value) && classes.Active)}>
                        className={classes.LetterButton + " " + (selectedMode === b.value && classes.Active)}>
                        {b.name}</button>
                )}
            </div>
            {/* {props.extended } */}
            {selectedMode === 'e'
            ? <PanelExercises 
                userID={props.id}
                />
            : null }
        </div>
    )
}

export default PanelUser
