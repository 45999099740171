import classes from './Modal.module.sass';
import Backdrop from '../Backdrop/Backdrop';


const Modal = (props) => {
    let styles = [classes.Modal];
    if (props.noframe) {
        styles.push(classes.Borderless)
    }
    // shouldComponentUpdate(nextProps, nextState) {
    //     return nextProps.show !== this.props.show;
    // }
    return (
        <>
            <Backdrop 
                show={props.show} 
                clicked={props.modalClosed}/>
            <div 
                className={styles.join(' ')}
                style={{
                    width: props.width ? props.width : null,
                    transform: props.show ? 'translate(-50%, -50%)' : 'translateY(-100vh)',
                    pointerEvents: props.show ? 'all' : 'none',
                    zIndex: props.show ? '9999' : '-1',
                    opacity: props.show ? '1' : '0', 
                }}>
                {props.children}
            </div>
        </>
    );
}

export default Modal;