import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
import { AiTwotoneEnvironment } from 'react-icons/ai';

// const initState = {
//     planVariables: [],
//     tubes: [],
//     firstVisit: true
// }

// const initState = {
//     planVariables: [
//         {id: "sample", type: "abh", option: "enzymakt", unit: "pH-Wert"}
//     ],
//     tubes: [
//         {'sample': ''},
//         {'sample': ''},
//         {'sample': ''}
//     ],
//     firstVisit: true
// }


// PRDUCTION STATE

const initState = {
    planVariables: [
        {"id":"ID1607523018744","group":0,"type":"abh","option":"undef","unit":"undef"},
        {"id":"ID1607523022883","group":1,"type":"unabh","option":"undef","unit":"undef"},
        {"id":"ID1607523023548","group":1,"type":"dist","option":"undef","unit":"undef"},
        {"id":"ID1607523024156","group":1,"type":"dist","option":"undef","unit":"undef"},
        {"id":"ID1607523025617","group":2,"type":"messure","option":"start","unit":"undef"},
        {"id":"ID1607523025831","group":2,"type":"messure","option":"dauer","unit":"undef"},
        {"id":"ID1607523041268","group":2,"type":"messure","option":"interval","unit":"undef"}
    ],
    tubes: [
        {"ID1607523018744":0,"ID1607523022883":0,"ID1607523023548":0,"ID1607523024156":0,"ID1607523025617":"","ID1607523025831":"","ID1607523041268":""},
        {"ID1607523018744":0,"ID1607523022883":0,"ID1607523023548":0,"ID1607523024156":0,"ID1607523025617":"","ID1607523025831":"","ID1607523041268":""},
        {"ID1607523018744":0,"ID1607523022883":0,"ID1607523023548":0,"ID1607523024156":0,"ID1607523025617":"","ID1607523025831":"","ID1607523041268":""},
        {"ID1607523018744":0,"ID1607523022883":0,"ID1607523023548":0,"ID1607523024156":0,"ID1607523025617":"","ID1607523025831":"","ID1607523041268":""}

    ],
    repetitionCount: '',
    repetitionDesc: '',
    firstVisit: true,
    helpTutorial: true
}

// const initState_2tubes = {
//     planVariables: [
//         {"id":"ID1607523018744","group":0,"type":"abh","option":"undef","unit":"undef"},{"id":"ID1607523022883","group":1,"type":"unabh","option":"undef","unit":"undef"},{"id":"ID1607523023548","group":1,"type":"dist","option":"undef","unit":"undef"},{"id":"ID1607523024156","group":1,"type":"dist","option":"undef","unit":"undef"},{"id":"ID1607523025617","group":2,"type":"messure","option":"start","unit":"undef"},{"id":"ID1607523025831","group":2,"type":"messure","option":"dauer","unit":"undef"},{"id":"ID1607523041268","group":2,"type":"messure","option":"interval","unit":"undef"}
//     ],
//     tubes: [
//         {"ID1607523018744":0,"ID1607523022883":0,"ID1607523023548":0,"ID1607523024156":0,"ID1607523025617":0,"ID1607523025831":0,"ID1607523041268":0},
//         {"ID1607523018744":0,"ID1607523022883":0,"ID1607523023548":0,"ID1607523024156":0,"ID1607523025617":0,"ID1607523025831":0,"ID1607523041268":0},
//     ],
//     repetitionCount: '',
//     repetitionDesc: '',
//     firstVisit: true,
//     helpTutorial: true
// }

const initState_phvalue = {
    planVariables:[
        {"id":"ID1607523018744","group":0,"type":"abh","option":"undef","unit":"undef"},
        {"id":"ID1607523022883","group":1,"type":"unabh","option":"undef","unit":"undef"},
        {"id":"ID1633870345429","group":1,"type":"dist","option":"undef","unit":"undef"},
        {"id":"ID1633870346974","group":1,"type":"dist","option":"undef","unit":"undef"},
        {"id":"ID1633870347444","group":1,"type":"dist","option":"undef","unit":"undef"},
        {"id":"ID1633870360696","group":2,"type":"messure","option":"start","unit":"undef"},
        {"id":"ID1633870366276","group":2,"type":"messure","option":"dauer","unit":"undef"},
        {"id":"ID1633870375096","group":2,"type":"messure","option":"interval","unit":"undef"},
    ],
    tubes: [
        {"ID1607523018744":0,"ID1607523022883":0,"ID1633870345429":0,"ID1633870346974":0,"ID1633870347444":0,"ID1633870360696":"","ID1633870366276":"","ID1633870375096":""},
        {"ID1607523018744":0,"ID1607523022883":0,"ID1633870345429":0,"ID1633870346974":0,"ID1633870347444":0,"ID1633870360696":"","ID1633870366276":"","ID1633870375096":""},
        {"ID1607523018744":0,"ID1607523022883":0,"ID1633870345429":0,"ID1633870346974":0,"ID1633870347444":0,"ID1633870360696":"","ID1633870366276":"","ID1633870375096":""}
    ],
    repetitionCount: '',
    repetitionDesc: '',
    firstVisit: true,
    helpTutorial: true
}

const initState_activation = {
    planVariables:[
        {"id":"ID1607523018744","group":0,"type":"abh","option":"undef","unit":"undef"},
        {"id":"ID1607523022883","group":1,"type":"unabh","option":"undef","unit":"undef"},
        {"id":"ID1633870781315","group":1,"type":"dist","option":"undef","unit":"undef"},
        {"id":"ID1633870782136","group":1,"type":"dist","option":"undef","unit":"undef"},
        {"id":"ID1633870782610","group":1,"type":"dist","option":"undef","unit":"undef"},
        {"id":"ID1633870787799","group":2,"type":"messure","option":"start","unit":"undef"}
    ],
    tubes:[
        {"ID1607523018744":0,"ID1607523022883":0,"ID1633870781315":0,"ID1633870782136":0,"ID1633870782610":0,"ID1633870787799":""},
        {"ID1607523018744":0,"ID1607523022883":0,"ID1633870781315":0,"ID1633870782136":0,"ID1633870782610":0,"ID1633870787799":""},
    ],
    repetitionCount: '',
    repetitionDesc: '',
    firstVisit: true,
    helpTutorial: true
}

const initState_inhibition = {
    planVariables:[
        {"id":"ID1607523018744","group":0,"type":"abh","option":"undef","unit":"undef"},
        {"id":"ID1607523022883","group":1,"type":"unabh","option":"undef","unit":"undef"},
        {"id":"ID1633871166085","group":1,"type":"dist","option":"undef","unit":"undef"},
        {"id":"ID1633871169888","group":1,"type":"dist","option":"undef","unit":"undef"},
        {"id":"ID1633871177660","group":1,"type":"dist","option":"undef","unit":"undef"},
        {"id":"ID1633871183643","group":1,"type":"dist","option":"undef","unit":"undef"},
        {"id":"ID1633871189223","group":2,"type":"messure","option":"start","unit":"undef"}
    ],
    tubes:[
        {"ID1607523018744":0,"ID1607523022883":0,"ID1633871166085":0,"ID1633871169888":0,"ID1633871177660":0,"ID1633871183643":0,"ID1633871189223":""},
        {"ID1607523018744":0,"ID1607523022883":0,"ID1633871166085":0,"ID1633871169888":0,"ID1633871177660":0,"ID1633871183643":0,"ID1633871189223":""},
        {"ID1607523018744":0,"ID1607523022883":0,"ID1633871166085":0,"ID1633871169888":0,"ID1633871177660":0,"ID1633871183643":0,"ID1633871189223":""},
        {"ID1607523018744":0,"ID1607523022883":0,"ID1633871166085":0,"ID1633871169888":0,"ID1633871177660":0,"ID1633871183643":0,"ID1633871189223":""},
    ],
    repetitionCount: '',
    repetitionDesc: '',
    firstVisit: true,
    helpTutorial: true
}

// state for testing with the correct answer

// TEMPERATURE
// const initState = {
//     planVariables: [
//         {id: "ID1607523018744", group: 0, type: "abh", option: "enzymakt", unit: "pH-Wert"},
//         {id: "ID1607523022883", group: 1, type: "unabh", option: "temp", unit: "°C"},
//         {id: "ID1607523023548", group: 1, type: "dist", option: "kondens", unit: "ml"},
//         {id: "ID1607523024156", group: 1, type: "dist", option: "lipase", unit: "ml"},
//         {id: "ID1607523025617", group: 2, type: "messure", option: "start", unit: "s"},
//         {id: "ID1607523025831", group: 2, type: "messure", option: "dauer", unit: "min"},
//         {id: "ID1607523041268", group: 2, type: "messure", option: "interval", unit: "min"}
//     ],
//     tubes: [
//         {'ID1607523018744':0, 'ID1607523022883':"10", 'ID1607523023548':"1", 'ID1607523024156':"1", 'ID1607523025617':0, 'ID1607523025831':"2", 'ID1607523041268':"1"},
//         {'ID1607523018744':0, 'ID1607523022883':"20", 'ID1607523023548':"1", 'ID1607523024156':"1", 'ID1607523025617':0, 'ID1607523025831':"2", 'ID1607523041268':"1"},
//         {'ID1607523018744':0, 'ID1607523022883':"30", 'ID1607523023548':"1", 'ID1607523024156':"1", 'ID1607523025617':0, 'ID1607523025831':"2", 'ID1607523041268':"1"}
//     ],
//     repetitionCount: '',
//     repetitionDesc: '',
//     firstVisit: true,
//     helpTutorial: true
// }


// PHVALUE
// const initState = {
//     planVariables: [
//         {"id": "ID1607523018744","group": 0,"type": "abh","option": "enzymakt","unit": "Schaumhöhe in mm","unitCorrect": "true"},
//         {"id": "ID1607523022883","group": 1,"type": "unabh","option": "phwertloesung","unit": "pH-Wert","unitCorrect": "false"},
//         {"id": "ID1607523023548","group": 1,"type": "dist","option": "kartoffel","unit": "ml"},
//         {"id": "ID1607523024156","group": 1,"type": "dist","option": "wasserstoff","unit": "ml"},
//         {"id": "ID1607523025617","group": 2,"type": "messure","option": "start","unit": "bei Mischung"},
//         {"id": "ID1607523025831","group": 2,"type": "messure","option": "dauer","unit": "min"},
//         {"id": "ID1607523041268","group": 2,"type": "messure","option": "interval","unit": "min"},
//         {"id": "ID1628544140882","group": 1,"type": "dist","option": "temp","unit": "°C","unitCorrect": "false"}
//     ],
//     tubes: [
        // {"ID1607523018744": 0,"ID1607523022883": "2","ID1607523023548": "2","ID1607523024156": "5","ID1607523025617": "0","ID1607523025831": "15","ID1607523041268": "1","ID1628544140882": "37"},
        // {"ID1607523018744": 0,"ID1607523022883": "5","ID1607523023548": "2","ID1607523024156": "5","ID1607523025617": "0","ID1607523025831": "15","ID1607523041268": "1","ID1628544140882": "37"},
        // {"ID1607523018744": 0,"ID1607523022883": "7","ID1607523023548": "2","ID1607523024156": "5","ID1607523025617": "0","ID1607523025831": "15","ID1607523041268": "1","ID1628544140882": "37"},
        // {"ID1607523018744": 0,"ID1607523022883": "9","ID1607523023548": "2","ID1607523024156": "5","ID1607523025617": "0","ID1607523025831": "15","ID1607523041268": "1","ID1628544140882": "37"}
//     ],
//     repetitionCount: '',
//     repetitionDesc: '',
//     firstVisit: true,
//     helpTutorial: true
// }

// ACTIVATION ENERGY
// const initState = {
//     planVariables: [
        // {"id": "ID1607523018744","group": 0,"type": "abh","option": "ammoniak","unit": "pH-Wert: Zeitpunkt des Farbumschlags in s","unitCorrect": "true"},
        // {"id": "ID1607523022883","group": 1,"type": "unabh","option": "urease","unit": "ml","unitCorrect": "false"},
        // {"id": "ID1607523023548","group": 1,"type": "dist","option": "harnstoff","unit": "ml"},
        // {"id": "ID1607523024156","group": 1,"type": "dist","option": "temp","unit": "°C","unitCorrect": "false"},
        // {"id": "ID1607523025617","group": 2,"type": "messure","option": "start","unit": "bei Mischung"},
        // {"id": "ID1607523025831","group": 2,"type": "messure","option": "dauer","unit": "min"},
        // {"id": "ID1607523041268","group": 2,"type": "messure","option": "interval","unit": "min"},
        // {"id": "ID1628544140882","group": 1,"type": "dist","option": "bromthymolblau","unit": "ml","unitCorrect": "false"}
//     ],
//     tubes: [
        // {"ID1607523018744": 0, "ID1607523022883": "0", "ID1607523023548": "5", "ID1607523024156": "23", "ID1607523025617": "0", "ID1607523025831": "8", "ID1607523041268": "0.5", "ID1628544140882": "1"
        // },
        // { "ID1607523018744": 0, "ID1607523022883": "1", "ID1607523023548": "5", "ID1607523024156": "23", "ID1607523025617": "0", "ID1607523025831": "8", "ID1607523041268": "0.5", "ID1628544140882": "1"
        // }
//     ],
//     repetitionCount: '',
//     repetitionDesc: '',
//     firstVisit: false,
//     helpTutorial: false
// }

// INHIBITION

// const initState = {
//     planVariables: [
//         {"id": "ID1607523018744","group": 0,"type": "abh","option": "stkonz","unit": "Färbung (gelb-bräunlich/blau-violett)"},
//         {"id": "ID1607523022883","group": 1,"type": "unabh","option": "hemmstoff","unit": "ml"},
//         {"id": "ID1607523023548","group": 1,"type": "dist","option": "amylase","unit": "ml"},
//         {"id": "ID1607523024156","group": 1,"type": "dist","option": "stloesung","unit": "ml","unitCorrect": "false"},
//         {"id": "ID1607523025617","group": 2,"type": "messure","option": "start","unit": "bei Mischung"},
//         {"id": "ID1607523025831","group": 2,"type": "messure","option": "dauer","unit": "min"},
//         {"id": "ID1607523041268","group": 2,"type": "messure","option": "interval","unit": "min"},
//         {"id": "ID1628544140882","group": 1,"type": "dist","option": "temp","unit": "°C"},
//         {"id": "ID1628550188356","group": 1,"type": "dist","option": "lugol","unit": "ml","unitCorrect": "false"},
//     ],


//     tubes: [
//         {"ID1607523018744": 0,"ID1607523022883": "0","ID1607523023548": "1","ID1607523024156": "5","ID1607523025617": "0","ID1607523025831": "8","ID1607523041268": "0.5","ID1628544140882": "37","ID1628550188356": "0.5"},
//         {"ID1607523018744": 0,"ID1607523022883": "0.025","ID1607523023548": "1","ID1607523024156": "5","ID1607523025617": "0","ID1607523025831": "8","ID1607523041268": "0.5","ID1628544140882": "37","ID1628550188356": "0.5"},
//         {"ID1607523018744": "0","ID1607523022883": "0.05","ID1607523023548": "1","ID1607523024156": "5","ID1607523025617": "0","ID1607523025831": "8","ID1607523041268": "0.5","ID1628544140882": "37","ID1628550188356": "0.5"},
//         {"ID1607523018744": "0","ID1607523022883": "0.1","ID1607523023548": "1","ID1607523024156": "5","ID1607523025617": "0","ID1607523025831": "8","ID1607523041268": "0.5","ID1628544140882": "37","ID1628550188356": "0.5"},
//         {"ID1607523018744": "0","ID1607523022883": "0.15","ID1607523023548": "1","ID1607523024156": "5","ID1607523025617": "0","ID1607523025831": "8","ID1607523041268": "0.5","ID1628544140882": "37","ID1628550188356": "0.5"},
//         {"ID1607523018744": "0","ID1607523022883": "0.45","ID1607523023548": "1","ID1607523024156": "5","ID1607523025617": "0","ID1607523025831": "8","ID1607523041268": "0.5","ID1628544140882": "37","ID1628550188356": "0.5"
//         }
//     ],
//     repetitionCount: '',
//     repetitionDesc: '',
//     firstVisit: false,
//     helpTutorial: false
// }


const planFirstVisit = (state, action) => {
    return updateObject(state, {
        firstVisit: false
    });
}

const closeHelpTutorial = (state, action) => {
    return updateObject(state, {
        helpTutorial: false
    });
}

const addVar = (state, action) => {
    const newId = "ID" + Date.now();
    const newVar = {id: newId, group: 1, type: '', option: '', unit: ''}

    const newTubes = state.tubes.map(tube=> {
        console.log(tube)
        return {...tube, [newId]: ''}
    });
    return updateObject(state, {
       planVariables: state.planVariables.concat(newVar),
       tubes: newTubes
    });
}

const removeVar = (state, action) => {
    let newTubes = state.tubes.map(tube=> {
        const {[action.id]: remove, ...rest } = tube;
        return rest
    });
    //state.planVariables.length-1===0 && state.tubes[0].hasOwnProperty('sample') && (newTubes = [])
    state.planVariables.length-1===0 && (newTubes = [])
    return updateObject(state, {
        planVariables: state.planVariables.filter((i) => action.id !== i.id),
        tubes: newTubes
    });
}

const addTube = (state, action) => {
    let newTube = {} 
    state.planVariables.map(key => {
        newTube = {...newTube, [key.id]: ''}
    });
    return updateObject(state, {
        tubes: state.tubes.concat(newTube)
    });
}

const removeTube = (state, action) => {
    return updateObject(state, {
        tubes: [...state.tubes.slice(0,action.index),
            ...state.tubes.slice(action.index+1)]
    });
}

const planChangeValue = (state, action) => {
    const newPlanVariables = [...state.planVariables];
    const index = newPlanVariables.findIndex(pos => pos.id === action.idToChange);
    const newObject = {...newPlanVariables[index]};
    newObject[action.typeToChange] = action.newValue;
    
    newObject.unitCorrect = action.unitCorrect;
    if(action.newGroup !== "nochange") {
        newObject.group = +action.newGroup;
    }
    
    // check if there is preset
    let mutatedTubes = state.tubes;
    if(action.unitPreset) {
        console.log("UNIT PRESET: ", action.unitPreset)
        console.log(state.tubes)
        mutatedTubes = state.tubes.map(t => {
            return {...t, [action.idToChange]: action.unitPreset}
        })
        console.log("tubes new", mutatedTubes)
    }
    return updateObject(state, {
        planVariables:  [...state.planVariables.slice(0,index),
                        newObject,
                        ...state.planVariables.slice(index+1)],
        tubes: mutatedTubes
    })
}

const planChangeTubeValue = (state, action) => {
    console.log("action:",action)
    const newTubesArray = [...state.tubes];
    const newObject = {...newTubesArray[action.tubeIndex], [action.varId]: action.newValue}
    return updateObject(state, {
        tubes: [...state.tubes.slice(0, action.tubeIndex),
                newObject,
                ...state.tubes.slice(action.tubeIndex+1)]
    })
}

const planChangeRepetitionCount = (state, action) => {
    return updateObject(state, {
        repetitionCount: action.newValue
    })
}
const planChangeRepetitionDesc = (state, action) => {
    return updateObject(state, {
        repetitionDesc: action.newValue
    })
}

const setPlanData = (state, action) => {
    console.log("------ SET DATA_______")
    console.log(action.planData)
    return updateObject(state, {
        tubes: action.planData.planTubes,
        planVariables: action.planData.planVariable,
        repetitionCount: action.planData.planRepetition.repetitionCount,
        repetitionDesc: action.planData.planRepetition.repetitionDesc,
    })
}

const resetPlanData = (state, action) => {
    console.log(initState);
    if (action.template === 1) {
        return initState;
    }
    if(action.template === 2) {
        return initState_phvalue;
    }
    if (action.template === 3) {
        return initState_activation;
    }
    if (action.template === 4) {
        return initState_inhibition;
    }
}

const reducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.PLAN_ADD_VAR: return addVar(state, action);
        case actionTypes.PLAN_REMOVE_VAR: return removeVar(state, action);
        case actionTypes.PLAN_ADD_TUBE: return addTube(state, action);
        case actionTypes.PLAN_REMOVE_TUBE: return removeTube(state, action);
        case actionTypes.PLAN_CHANGE_VALUE: return planChangeValue(state, action);
        case actionTypes.PLAN_CHANGE_TUBE_VALUE: return planChangeTubeValue(state, action);
        case actionTypes.PLAN_CHANGE_REPETITION_COUNT: return planChangeRepetitionCount(state, action);
        case actionTypes.PLAN_CHANGE_REPETITION_DESC: return planChangeRepetitionDesc(state, action);
        case actionTypes.PLAN_FIRST_VISIT: return planFirstVisit(state, action);
        case actionTypes.CLOSE_HELP_TUTORIAL: return closeHelpTutorial(state, action);
        case actionTypes.SET_PLAN_DATA: return setPlanData(state, action);
        case actionTypes.RESET_PLAN_DATA: return resetPlanData(state, action);

      //  case actionTypes.PLAN_CHECK_RESULT: return checkResult(state, action);
        default: return state;
    }
}

export default reducer;