import React, {useState, useCallback} from 'react'
import fb from '../axios-fb'
import useFullPageLoader from './useFullPageLoader'

const useFb = () => {
    //const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [loader, showLoader, hideLoader] = useFullPageLoader();

    const sendRequest = useCallback (async (node, applyData) => {
        showLoader()
        //setIsLoading(true)
        setError(null)
        const response = await fb.get(node)
        applyData(response.data)
        //setIsLoading(false)
        hideLoader()
    }, [])

    return {
        //isLoading,
        loader,
        error,
        sendRequest,
    }
};

export default useFb;
