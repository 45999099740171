import classes from './FullPageLoader.module.sass';

const FullPageLoader = (props) => {
    let content = <div className={classes.FullPageLoader}></div>
    
    const classList = [classes.Overlay]
    // if(props.success) {
    //     classList.push(classes.Success)
    //     content = (
    //         <svg className={classes.Check} xmlns="http://www.w3.org/2000/svg" width="59" height="42"><path fill="none" fillRule="evenodd" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" d="M4.5 20.5l16.53 16.53L54.5 4.5"/></svg>
    //     )
    // }

    return (
        <div className={classList.join(" ")}>
            {content}
        </div>
    )
}

export default FullPageLoader
