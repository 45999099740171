import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import 'intro.js/introjs.css';
import './assets/fhnw.min.css';
import './index.sass';

import App from './App';
import reportWebVitals from './reportWebVitals';
import authReducer from './store/reducer/auth';
import excerciseReducer from './store/reducer/exercise';
import planningReducer from './store/reducer/planning';

const rootReducer = combineReducers({
  auth: authReducer,
  exercise: excerciseReducer,
  planning: planningReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk)
));

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(
  //<React.StrictMode>
    <>{app}</>,
  //</React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
