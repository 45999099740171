import React, { useState, useEffect } from 'react'
import classes from './AdminPanel.module.sass'
import { cloneDeep } from 'lodash'
import PanelUser from './PanelUser/PanelUser'
import api from '../../../axios-api'
import { getFormData } from '../../../shared/utility'
import GroupFilter from './GroupFilter/GroupFilter'
import GroupCheckFilter from './GroupCheckFilter/GroupCheckFilter'
import DownloadButtons from './DownloadButtons/DownloadButtons'



const AdminPanel = () => {
    const [filter, setFilter] = useState(null)
    const [groupFilterSel, setGroupFilterSel] = useState(["1","2"])
    const [selectedUser, setSelectedUser] = useState(null)
    const [selectedModes, setSelectedModes] = useState({})
    const [selectedMode, setSelectedMode] = useState(null)

    const [users, setUsers] = useState([]);
    const [selectedHelp, setSelectedHelp] = useState(null)

    const userGroups = ['KG1', 'EG1', 'EG2', 'EG3'];

    useEffect(() => {
        let data = { table: 'TUsers', conditionField: '', condition: '' }
        if(groupFilterSel.length > 0) {
            console.log("use filter: ", groupFilterSel)
            data = { 
                table: 'TUsers', 
                conditionField: 'userGroup', 
                condition: groupFilterSel}    
        }
        let dataToSend = getFormData(data);
        //api.post('/backend/convert_tables.php', dataToSend)
        api.post('/api/fetch_all_data.php', dataToSend)
        .then(response => {
            console.log(response.data)
            if(response.data) {
                setUsers(response.data)
            }
        }).catch(err => {
            console.log("error when fetching users in the backend", err)
        })
    }, [groupFilterSel]);

    const groupFilter = [
        {name: "KG1", value: '1'},
        {name: "EG1", value: '2'},
        {name: "EG2", value: '3'},
        {name: "EG3", value: '4'}
    ]

    const selectUser = (id) => {
        if(selectedUser!==id) {
            setSelectedUser(id)
        } else {
            setSelectedUser(null)
        }
    }  

    // const modeClick = (user, mode) => {
    //     if(user !== selectedUser) {
    //         setSelectedUser(user)
    //     }
    //     const newSelectedModes = cloneDeep(selectedModes)
    //     if(selectedModes[user]) {
    //         if(!selectedModes[user].includes(mode)) {
    //             newSelectedModes[user].push(mode)   
    //             setSelectedModes(newSelectedModes)
    //             console.log("selected", newSelectedModes)
            
    //         } else {
    //             newSelectedModes[user] = selectedModes[user].filter(m => mode !== m)
    //             setSelectedModes(newSelectedModes)
    //             console.log(newSelectedModes)
    //         }
    //     } else {
    //         selectedModes[user] = [mode]
    //     }
    // }

    const modeClick = (user, mode) => {
        if(user !== selectedUser) {
            setSelectedUser(user)
        }
        const newSelectedModes = cloneDeep(selectedModes)
        if(selectedModes[user]) {
            if(!selectedModes[user] === mode) {
                newSelectedModes[user] = mode   
                setSelectedModes(newSelectedModes)
                console.log("selected", newSelectedModes)
            
            } else {
                newSelectedModes[user] = selectedModes[user] = null
                setSelectedModes(newSelectedModes)
                console.log(newSelectedModes)
            }
        } else {
            selectedModes[user] = mode
        }
    }

    const changeFilter = (e) => {
        const f = e.target.value;
        if(groupFilterSel.includes(f)) {
            setGroupFilterSel(groupFilterSel.filter(p => p != f))
        } else {
            setGroupFilterSel([...groupFilterSel, f])
        }
    }
    return (
        <div>
            <h2>Admin Panel</h2>

            <DownloadButtons />

            <GroupCheckFilter 
                results={users.length}
                filter={groupFilter} 
                selected={groupFilterSel}
                changed={changeFilter}
            />
            {users.map((user, i) => {
                console.log("MODE: ", selectedModes[user.userID])
                return <PanelUser key={i} 
                    id={user.userID} 
                    age={user.userAge} 
                    gender={user.userGender}
                    group={user.userGroup} 
                    clicked={() => selectUser(user.userID)}
                    extended={user.userID === selectedUser} 
                    //modes={selectedModes[user.userID]}
                    //modeClick={(mode) => modeClick(user.userID, mode)}
                    >
                        {/* {selectedModes[user.userID] === 'e'
                        ? <PanelExercises 
                            userID={user.userID}
                            active={selectedModes[user.userID] === 'e'}
                            />
                        : null} */}
                        
                    </PanelUser>
            })}
        </div>
    )
}

export default AdminPanel
