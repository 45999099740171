import React from 'react'

const GroupCheckFilter = (props) => {
    return (
        <>
            <strong>Group Filter</strong>
            <div className="float-right">Resultate [<strong>{props.results}</strong>]</div>
            <div className="form-control">
                {props.filter.map(f => 
                    <div key={f.value} className="form-check form-check-inline">
                        <input className="form-check-input" 
                            onChange={props.changed}
                            checked={props.selected.includes(f.value)}
                            type="checkbox" id={f.value} value={f.value}/>
                        <label className="form-check-label" htmlFor={f.value}>{f.name}</label>
                    </div>
                )}

            </div>
        </>
    )
}

export default GroupCheckFilter
