import React, {useState} from 'react';
import { connect } from 'react-redux';

import classes from './Layout.module.sass'
import Header from '../../components/Header/Header';
import SideDrawer from '../../components/Header/Navigation/SideDrawer/SideDrawer';

const Layout = props => {
    const [showSideDrawer, setShowSideDrawer] = useState(false);
    
    const drawerToggleClicked = () => {
        setShowSideDrawer(!showSideDrawer);
    }
    return( 
        <>
            <Header 
                isAuth={props.isAuthenticated} 
                userEmail={props.userEmail} 
                toggleClicked={drawerToggleClicked} />
            <SideDrawer 
                open={showSideDrawer} 
                isAuth={props.isAuthenticated} 
                closed={drawerToggleClicked}/>
            <div className="container">
                <main className={classes.Content}>
                    {props.children}
                </main>
            </div>
        </>
    )
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        userEmail: state.auth.userEmail
    }
}

export default connect(mapStateToProps)(Layout);