import React from 'react';
import classes from './TopicInfos.module.sass';

const TopicInfoReader = ({type, topicInfo}) => { 
    
    let templateString = topicInfo.info.text;

    if(topicInfo.info.images.length > 0) {
        topicInfo.info.images.map(img => {
            const imgSrc = `../../files/exercises/${type}/topicInformation/${img.path}`;
            const imgDom = `<figure><img src="${imgSrc}" alt="${img.path}" /><figcaption>${img.caption}</figcaption></figure>`;
            
            templateString = templateString.replaceAll("<"+img.name+"_left>",
                imgDom.replaceAll('<figure>',`<figure class='${classes.LeftImg}'>`));
            templateString = templateString.replaceAll("<"+img.name+"_big>", 
                imgDom.replaceAll('<figure>',`<figure class='${classes.BigImg}'>`));
            templateString = templateString.replaceAll("<"+img.name+"_full>", 
                imgDom.replaceAll('<figure>',`<figure class='${classes.FullImg}'>`));
            templateString = templateString.replaceAll("<"+img.name+"_caption_right>", 
                imgDom.replaceAll('<figure>',`<figure class='${classes.CaptionRightSide}'>`));
            templateString = templateString.replaceAll("<"+img.name+"_right_30pct>", 
                imgDom.replaceAll('<figure>',`<figure class='${classes.Right30Pct}'>`));
        })
    }
           
    return (
        <div className={classes.ExerciseBody}>
            <h1 className="mt-5">{topicInfo.title}</h1>
            <h3>{topicInfo.subtitle ? topicInfo.subtitle : "Informationen" }</h3>
            <div dangerouslySetInnerHTML={{__html: templateString}}></div>
        </div>
    )
}

export default TopicInfoReader;
