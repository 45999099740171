import React, { useState, useRef, useEffect } from 'react'
import classes from './Slideshow.module.sass'

const Slideshow = ({slides, textPrev='prev', textNext='next', number=false}) => {
    const [activeSlide, setActiveSlide] = useState(0)
    const [sliderHeight, setSliderHeight] = useState(0);
    const [loaded, setLoaded] = useState(false);


    let sliderContent = null
    const elRef = useRef(null)

    useEffect(() => {
        if (elRef.current) {
            setSliderHeight(elRef.current.offsetHeight)
            console.log(elRef.current.offsetHeight)
        }
    }, [elRef, loaded, activeSlide]);

    sliderContent = slides.map((slide, i) => {
        return (
            <img 
                onLoad={() => setLoaded(true)}
                ref={activeSlide === i ? elRef : null}
                //ref={elRef}
                key={i} 
                className={activeSlide === i && classes.Active}
                src={slide} />
            )
    })

    const changeSlide = (direction) => {
        if(direction === 'next') {
            if(activeSlide+1<slides.length) {
                setActiveSlide(activeSlide+1)
            } else {
                setActiveSlide(0)
            }
        } else {
            if(activeSlide>0) {
                setActiveSlide(activeSlide-1)
            } else {
                setActiveSlide(slides.length-1)
            }
        }
    }

    return (
        <div className={classes.SliderWrapper} style={{height: sliderHeight + 'px'}}>
            {number && slides.length > 1 && <div className={classes.Nr}>{activeSlide + 1}/{slides.length} </div>}
            <div className={classes.Slides}>
                {sliderContent ? sliderContent : "Keine Bilder vorhanden."}
            </div>
            {slides.length > 1 &&
                <div className={classes.Control}>
                    <a className={classes.Prev} onClick={() => changeSlide('prev')}>{textPrev}</a>
                    <a className={classes.Next} onClick={() => changeSlide('next')}>{textNext}</a>
                </div>
            }
        </div>
    )
}

export default Slideshow
