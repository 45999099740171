import React, { useState, useEffect, useRef } from 'react';
import { Steps } from 'intro.js-react';

import api from '../../../../axios-api';

import { getFormData } from '../../../../shared/utility';
import PDFDownloadButton from '../../../../components/PDFDocument/PDFDownloadButton/PDFDownloadButton'
import classes from './Planning.module.sass'
import PlanningTable from './PlanningTable/PlanningTable'

import Modal from '../../../../components/UI/Modal/Modal';

import { MdContentPaste, MdSave } from 'react-icons/md';
import Tooltip from '@material-ui/core/Tooltip';

import * as actions from '../../../../store/actions';
import { connect } from 'react-redux';
import useFb from '../../../../hooks/use-fb'

const Planning = props => {
    const addVar = useRef(null);
    const addTube = useRef(null);
    const [fadeOutTubeId, setFadeOutTubeId] = useState(null);
    const [fadeInTubeId, setFadeInTubeId] = useState(null);
    const [fadeInit, setFadeInit] = useState(null);
    const [saveStatus, setSaveStatus] = useState(false);
    const scrollContainerRef = useRef(null);
    const [modalPlanInfos, setModalPlanInfos] = useState(false)
    const [planningData, setPlanningData] = useState(null)
    const [tutorialText, setTutorialText] = useState(null);

    useEffect(() => {
        props.onGetPlanData(localStorage.getItem('exID'))
    }, []);

    const {loader, error, sendRequest} = useFb()
    useEffect(() => {
        sendRequest(`/planingData/${props.exSelected}.json`, setPlanningData);
        sendRequest(`/exerciseData/exercises/${props.exPos-1}/tutorialText.json`, setTutorialText);
    }, []);    
    
    const stepsDefinition = [
        {
            element: '.hypothese',
            intro: tutorialText?.s01_hypothese,
            position: 'bottom',
        },
        {
            element: '.noElement',
            intro: tutorialText?.s02_importantNotesStep,
            position: 'top',
            tooltipClass: classes.Tooltipp + " " + classes.MoveToRight,
            highlightClass: classes.Highlight,
            disableInteraction: true
        },
        {
            element: classes.Planning,
            intro: tutorialText?.s03_infotext,
            position: 'left',
            tooltipClass: classes.Tooltipp,
            highlightClass: classes.Highlight,
            disableInteraction: true
        },
        {
            element: '.planVars .g_1:first-child select',
            intro: tutorialText?.s04_abh,
            position: 'top',
            disableInteraction: true
        },
        // {
        //     element: '.addTubeButton',
        //     intro: tutorialText?.s05a_tubes,
        //     position: 'left',
        //     disableInteraction: true
        // },
        // {
        //     element: '.stepRemoveTube',
        //     intro: tutorialText?.s05b_tubes,
        //     position: 'top',
        //     disableInteraction: true
        // },
        {
            element: '.planVars .g_2',
            intro: tutorialText?.s06_unabh,
            position: 'top',
            disableInteraction: true
        },
        {
            element: '.stepTubes:first-child .g_2',
            intro: tutorialText?.s07_tubeVal,
            position: 'top',
            disableInteraction: true
        },
        {
            element: '.stepTubes:first-child .g_3',
            intro: tutorialText?.s08_messure,
            position: 'top',
            disableInteraction: true
        }
        ,
        {
            element: '.text-button',
            intro: tutorialText?.s09_helpManager,
            position: 'right',
            disableInteraction: true
        },
    ]

    const specialStep = (index, el) => {
        let layerBox = document.querySelector('.introjs-helperLayer')
        layerBox.setAttribute('style', 'width: auto');
        layerBox.setAttribute('style', 'height: auto');
        if(index===3) {
            let size = document.querySelector('.stepPlanningBody').clientWidth - 80
            layerBox.setAttribute('style', `width: ${size}px !important`);
        } else if (index===6) {
            let size = document.querySelector('.planVars').clientWidth - 15
            layerBox.setAttribute('style', `width: ${size}px !important`);
        }
    }

    const adjustScrollStep = (index, el) => {
        setTimeout(() => {
            let obj = document.querySelector('.introjs-tooltip');
            obj.scrollIntoView({ block: 'center',  behavior: 'smooth' });
            let offsetTop =  obj.getBoundingClientRect().top
            if (offsetTop < 0 && obj.style.marginTop) {
                obj.style.marginTop = parseInt(obj.style.marginTop) + 100 + 'px'
                 
            }
        }, 600);
    }

     const onSavePlanData = () => {
        const rawPlanData = {
            planVariable: props.planVariables, 
            planTubes: props.planTubes,
            planRepetition: {
                repetitionCount: props.planRepetitionCount,
                repetitionDesc: props.planRepetitionDesc
            }
        }
        let userInputData = JSON.stringify(rawPlanData)
        const exID = localStorage.getItem('exID')
        const data = { exID: exID, planData: userInputData }
        let dataToSend = getFormData(data);
        api.post("/api/save_plan_data.php", dataToSend)
            .then(response => {
                console.log('plan data saved')
                setSaveStatus(true);
                setTimeout(() => {
                    setSaveStatus(false);
                }, 1000)
            }).catch(err => {
                console.log("error with api call to SAVE PLAN DATA", err);
            });
    }

    const onAddFirstVar = () => {
        addVar.current.classList.add(classes.ButtonClicked)
        props.onAddVar();
    }

    const onAddFirstTube = () => {
        addTube.current.classList.add(classes.ButtonClicked);

        setTimeout(() => {
            (props.planTubes.length > 3) && (scrollContainerRef.current.scrollLeft += 100)
        }, 300)
        
        props.onAddTube();

        setFadeInit(props.planTubes.length)
        const timer = props.planTubes.length > 3 ? 100 : 305
        setTimeout(() => {
            setFadeInit(null)
            setFadeInTubeId(props.planTubes.length)
        }, 10);
        setTimeout(() => {
            setFadeInTubeId(null)
        }, timer);
    }

    const onRemoveVarClick = (id) => {
        props.planVariables.length <= 1 && addVar.current.classList.remove(classes.ButtonClicked);
        props.onRemoveVar(id);
    }

    const onRemoveTubeClick = (index) => {
        setFadeOutTubeId(index)
        setFadeInTubeId(null)
        setTimeout(() => {
            setFadeOutTubeId(null)
            props.onRemoveTube(index);
            props.planTubes.length <= 1 && addTube.current.classList.remove(classes.ButtonClicked);
        }, 300);        
    }

    return (
        <div className={classes.PlanningBody + " stepPlanningBody"}>
            <h3>Plane das Experiment:</h3>
            <div className={classes.Tools}>
                    <Tooltip title="Wichtig für die Planung!" arrow>
                        <div className="impNoteStep"><MdContentPaste 
                            data-toggle="tooltip" data-placement="top" title="Tooltip on top"
                            className={classes.ToolButton}
                            onClick={() => setModalPlanInfos(true)} 
                        /></div>
                    </Tooltip>
                    <Tooltip title="Planung zwischenspeichern" arrow>
                    <div><MdSave 
                        style={{'marginLeft': '5px'}}
                        className={classes.SaveButton + ' ' + (saveStatus && classes.Saving)} 
                        onClick={onSavePlanData} />
                    </div></Tooltip>
                    <Tooltip title="Planung herunterladen" arrow>
                    <div><PDFDownloadButton 
                            documentName={'diLuna_Planung_' + localStorage.getItem('exType')+'.pdf'}
                            title={props.exTitle.replace('&shy;', '')}
                            planVariables={props.planVariables}
                            planTubes={props.planTubes}
                            planRepetitionCount={props.planRepetitionCount}
                            planRepetitionDesc={props.planRepetitionDesc}
                        />
                    </div></Tooltip>
            </div>
            {tutorialText &&
                <Modal show={modalPlanInfos} modalClosed={() => setModalPlanInfos(false)}>
                    <div
                        dangerouslySetInnerHTML={{__html: tutorialText?.importantNotes}}>
                    </div>
                </Modal>}
            {loader}
            {(planningData && tutorialText) &&
                <Steps
                    enabled={props.firstVisit}
                    steps={stepsDefinition}
                    options={{nextLabel: "Weiter", prevLabel: "Zurück", doneLabel: "Alles klar!", showBullets: false, hidePrev: true, exitOnEsc: false, exitOnOverlayClick: localStorage.getItem('userAuthCode').includes("debug")}}
                    initialStep={0}
                    onExit={props.onHidePlanInfo}
                    onChange={(index, el) => specialStep(index, el)}
                    onAfterChange={(index, el) => adjustScrollStep(index, el)}
                />}
            {planningData &&
                <PlanningTable 
                    planVariables={props.planVariables}
                    planTubes={props.planTubes}
                    planRepetitionDesc={props.planRepetitionDesc}
                    onRepetitonDescChange={props.onRepetitonDescChange}
                    planRepetitionCount={props.planRepetitionCount}
                    onRepetitonCountChange={props.onRepetitonCountChange}
                    planningData={planningData}
                    fadeOutTubeId={fadeOutTubeId}
                    fadeInTubeId={fadeInTubeId}
                    fadeInit={fadeInit}
                    addVarRef={addVar}
                    addTubeRef={addTube}
                    scrollContainerRef={scrollContainerRef}
                    onValueChange={(e, id, change) => props.onValueChange(e, id, change)}
                    onTubesChange={(event, index, tube) => props.onTubesChange(event, index, tube)}
                    onAddFirstVar={onAddFirstVar}
                    onAddFirstTube={onAddFirstTube}
                    onRemoveVarClick={onRemoveVarClick}
                    onRemoveTubeClick={onRemoveTubeClick}
                />
           }
        </div>
    )
}

const mapStateToProps = state => {
    return {
        exSelected: state.exercise.exSelected,
        exPos: state.exercise.exPos,
        planVariables: state.planning.planVariables,
        planTubes: state.planning.tubes,
        planRepetitionCount: state.planning.repetitionCount,
        planRepetitionDesc: state.planning.repetitionDesc,
        firstVisit: state.planning.firstVisit
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onPlanFirstVisit: () => dispatch(actions.planFirstVisit()),
        onAddVar: () => dispatch(actions.addVar()),
        onRemoveVar: (id) => dispatch(actions.removeVar(id)),
        onAddTube: () => dispatch(actions.addTube()),
        onRemoveTube: (index) => dispatch(actions.removeTube(index)),
        onValueChange: (event,id, which) => dispatch(actions.planChangeValue(event,id, which)),
        onTubesChange: (event, index, varId) => dispatch(actions.planChangeTubeValue(event, index, varId)) ,
        onRepetitonCountChange: (event) => dispatch(actions.planChangeRepetitionCount(event)),
        onRepetitonDescChange: (event) => dispatch(actions.planChangeRepetitionDesc(event)),
        onGetPlanData: (userID) => dispatch(actions.getPlanData(userID))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Planning);