import React, { useEffect, useState } from 'react'
import fb from '../../../axios-fb'
import useFb from '../../../hooks/use-fb'

import HelpContent from './HelpContent/HelpContent'
import HelpTutorials from './HelpTutorials/HelpTutorials'


const modes = [
    {val: "helpContent", name: "Hilfen Inhalt"},
    {val: "helpTutorials", name: "Hilfen Tutorials"},
]
const HelpDataOverview = () => {
    const [data, setData] = useState(null)
    const {loader, error, sendRequest} = useFb()
    const [mode, setMode] = useState(null);

    // useEffect(() => {
    //     sendRequest(`/helpData.json`, setData);
    // }, [])
    let modeButtons = null
    modeButtons = modes.map(m => {
        return  <button 
            key={m.val}
            onClick={() => setMode(m.val)} 
            className="btn btn-secondary mr-2 mb-5">
                {m.name}
            </button>
    })

    let helpContent = null
    if (mode) {
        mode === 'helpContent' && (helpContent = <HelpContent />)
        mode === 'helpTutorials' && (helpContent = <HelpTutorials />)
    }

    return (
        <div>
            {modeButtons}
            {helpContent}
        </div>
    )
}

export default HelpDataOverview
