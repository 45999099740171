import React,{useState, useEffect} from 'react'
import classes from './ExerciseStep.module.sass'
import Condition from './ExerciseStepCondition/ExerciseStepCondition'
import Input from '../../../../components/UI/Input/Input'
import SampleSolution from './SampleSolution/SampleSolution'

const ExerciseStep = (props) => {
    const [conditionValidate, setConditionValidate] = useState({})
    const [characterCount, setCharacterCount] = useState(0);
    const [characterMinimum, setCharaterMinimum] = useState(0)

    useEffect(() => {
        let conditions = {};

        if(props.exSubstep.inputs) {
            props.pagerStatus(true) // set to false to enable minimum characters in additional input
        }
        setCharacterCount(0)
        if((Object.keys(props.additionalInput).length > 0) && props.exSubstep.inputs) {
            if(props.exSubstep.inputs[0].name !== undefined) {
                if(props.additionalInput[props.exSubstep.inputs[0].name] !== undefined) {
                    setCharacterCount(props.additionalInput[props.exSubstep.inputs[0].name].length)
                    if(props.additionalInput[props.exSubstep.inputs[0].name].length >= characterMinimum) {
                        props.pagerStatus(true)
                    } else {
                        props.pagerStatus(false)
                    }
                }
            }
        } 

        if(props.exSubstep.conditions) {
            for(let i=0; i<props.exSubstep.conditions.length; i++) {
                conditions = {...conditions, ['c'+i]: false}
            }
            setConditionValidate(conditions)
        }
    }, [props.exSubstep]);

    useEffect(() => {
        // check if all conditions are true
        let validCount = 0;

        Object.keys(conditionValidate).map(c => {
            if(conditionValidate[c] === true) {
                validCount++;
            }
        })
        
        if(props.exSubstep.conditions) {
            if(validCount === props.exSubstep.conditions.length) {
                props.pagerStatus(true)
            } else {
                props.pagerStatus(false)
            }
        }
    }, [conditionValidate]);

    const onAdditionalInputChange = (e, fieldName) => {
        setCharacterCount(e.target.value.length);
        if (e.target.value.length >= characterMinimum) {
            props.pagerStatus(true)
        } else {
            props.pagerStatus(false)
        }
        props.changeAdditionalInput(e, fieldName)
    }
    

    let templateString = props.exSubstep.text;
    if(props.exSubstep.images) {
        Object.keys(props.exSubstep.images).map(imgName => {
            const imgSrc = `../../files/exercises/${props.ex}/${props.exSubstep.images[imgName]}`;
            const imgDom = `<img src="${imgSrc}" alt="${imgName}" />`;
            templateString = templateString.replaceAll("<"+imgName+">", imgDom);
            templateString = templateString.replaceAll("<"+imgName+"_left>",
                imgDom.replaceAll('/>',`class='${classes.LeftImg}' />`));
            templateString = templateString.replaceAll("<"+imgName+"_right>",
                imgDom.replaceAll('/>',`class='${classes.RightImg}' />`));
            templateString = templateString.replaceAll("<"+imgName+"_big>", 
                imgDom.replaceAll('/>',`class='${classes.BigImg}' />`));
            templateString = templateString.replaceAll("<"+imgName+"_full>", 
                imgDom.replaceAll('/>',`class='${classes.FullImg}' />`));
            templateString = templateString.replaceAll("<"+imgName+"_right_30pct>", 
                imgDom.replaceAll('/>',`class='${classes.RightImg30Pct}' />`)); 
            templateString = templateString.replaceAll("<"+imgName+"_right_60pct>", 
                imgDom.replaceAll('/>',`class='${classes.RightImg60Pct}' />`));            
            templateString = templateString.replaceAll("<"+imgName+"_left_30pct>", 
                imgDom.replaceAll('/>',`class='${classes.LeftImg30Pct}' />`));   
            templateString = templateString.replaceAll("<"+imgName+"_left_50pct>", 
                imgDom.replaceAll('/>',`class='${classes.LeftImg50Pct}' />`));            
            templateString = templateString.replaceAll("<"+imgName+"_left_60pct>", 
                imgDom.replaceAll('/>',`class='${classes.LeftImg60Pct}' />`));            
                
        });
    }

    if(props.exSubstep.videos) {
        Object.keys(props.exSubstep.videos).map(vidName => {
            const videoSrc = `../../files/exercises/${props.ex}/${props.exSubstep.videos[vidName]}`;
            const videoDom = `<video src="${videoSrc}" alt="${vidName}" controls></video>`;
            templateString = templateString.replaceAll("<"+vidName+">", videoDom);
            templateString = templateString.replaceAll("<"+vidName+"_left>",
                videoDom.replaceAll('controls',`class='${classes.LeftVideo}' controls`));
            templateString = templateString.replaceAll("<"+vidName+"_right>",
                videoDom.replaceAll('controls',`class='${classes.RightVideo}' controls`));
            templateString = templateString.replaceAll("<"+vidName+"_big>", 
                videoDom.replaceAll('controls',`class='${classes.BigVideo}' controls`));
            templateString = templateString.replaceAll("<"+vidName+"_full>", 
                videoDom.replaceAll('controls',`class='${classes.FullVideo}' controls`));
        });
    }

    const checkPagerStatus = (count, status) => {
        let conditions = conditionValidate;
        conditions = {...conditions, ['c'+count]: status}
        setConditionValidate(conditions)       
    }

    let substep = (
        <>
            <div
                onClick={props.clickFunction}
                className={classes.LoadedText} 
                dangerouslySetInnerHTML={{__html: templateString}}>
            </div>
            {props.exSubstep.conditions?.map((c, i) => {
               return <Condition key={i} 
                    pagerStatus={(count, status) => checkPagerStatus(count, status)} 
                    conditions={c} 
                    count={i}
                    /> 
            })}
        </>
    )
    
    let inputs = null;
    inputs = props.exSubstep.inputs?.map(field => {
        return <Input
                    elementConfig={{"placeholder": field.placeholder}}
                    changed={(e) => onAdditionalInputChange(e, field.name)}
                    value={props.additionalInput[field.name] ? props.additionalInput[field.name] : ""} 
                    elementType={field.type} 
                    //characterCount={characterCount}
                    //characterMinimum={characterMinimum}
                    name={field.name} />
    })
    return (
        <div>
            {substep}
            {props.exSubstep.component &&
                <div className={classes.PlanningContainer}>
                    <SampleSolution 
                        exSelected={props.ex}
                        exUserPlanData={props.exUserPlanData} 
                        solutionData={props.exSampleSolution} />   
                </div>
            }
            {inputs}
        </div>
    )
}

export default ExerciseStep
