import api from '../axios-api';
import { getFormData } from './utility';

export const uploadImage = (image, path, name) => {
    const data = { 
        image,
        path,
        name
    }

    let dataToSend = getFormData(data);
    return api.post('backend/upload_file.php', dataToSend, {
        headers: {
        'Content-Type': 'multipart/form-data'
        }
    }).then((response) => {
            console.log("[image upload]", response.data)
        })
}

export const closeAllHelp = (exID) => {
    const data = {
        exID
    }
    let dataToSend = getFormData(data);
    return api.post('/api/close_help_all.php', dataToSend)
        .then(response => {
            if(response.data) {
               return response.data
            } else {
                console.log("All remaining open helps closed by the force")
            }
        }).catch(err => {
            console.log("Error when using close_help_all.php", err)
    })
}

export const getDataFromTable = async (table, conditionField, condition) => {       
    const data = {
        table, 
        conditionField,
        condition
    }
    let dataToSend = getFormData(data);
    return api.post('/api/fetch_data.php', dataToSend)
        .then(response => {
            if(response.data) {
               return response.data
            } else {
                console.log("No field found with " + data.condition)
            }
        }).catch(err => {
            console.log("Error when using fetch_data.php", err)
    })
}

export const insertDataToTable = async (table, fieldsObj) => {       
    const data = {
        table, 
        fieldsObj: JSON.stringify(fieldsObj)
    }
    //console.log(JSON.stringify(fieldsObj));
    let dataToSend = getFormData(data);
    return api.post('/api/insert_to_table.php', dataToSend)
        .then(response => {
            if(response.data) {
               return response.data
            } else {
                console.log("No field found with " + data.condition)
            }
        }).catch(err => {
            console.log("Error when using fetch_data.php", err)
    })
}

export const insertPrePost = async (userID, prePostAType, fieldsObj) => {       
    const data = {
        userID,
        prePostAType,
        fieldsObj: JSON.stringify(fieldsObj)
    }
    //console.log(JSON.stringify(fieldsObj));
    let dataToSend = getFormData(data);
    return api.post('/api/insert_prePost_step.php', dataToSend)
        .then(response => {
            if(response.data) {
               return response.data
            } else {
                console.log("No field found with " + data.condition)
            }
        }).catch(err => {
            console.log("Error when using fetch_data.php", err)
    })
}

export const endPrePost = async (userID, mode) => {       
    const data = {
        userID,
        mode,
    }
    //console.log(JSON.stringify(fieldsObj));
    let dataToSend = getFormData(data);
    return api.post('/api/end_prePost.php', dataToSend)
        .then(response => {
            if(response.data) {
               return response.data
            } else {
                console.log("No field found with " + data.condition)
            }
        }).catch(err => {
            console.log("Error when using end_prePost.php", err)
    })
}

export const insertExQA = async (userID, exID, fieldsObj) => {       
    const data = {
        userID,
        exID,
        fieldsObj: JSON.stringify(fieldsObj)
    }
    //console.log(JSON.stringify(fieldsObj));
    let dataToSend = getFormData(data);
    return api.post('/api/insert_exQA_step.php', dataToSend)
        .then(response => {
            if(response.data) {
               return response.data
            } else {
                console.log("No field found with " + data.condition)
            }
        }).catch(err => {
            console.log("Error when using insert_exQA_step.php", err)
    })
}