import React, { useState, useEffect } from 'react'
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai'
//import HelpGroupContent from './HelpGroupContent/HelpGroupContent'
import Slideshow from '../../../../../components/UI/Slideshow/Slideshow'
import ImgToChange from '../../../ImageUpload/ImgToChange'
import { AiOutlineSetting } from 'react-icons/ai'

const DataExSteps = ({type, data, node}) => {
    const [extended, setExtended] = useState(null)
    const [imgHash, setImgHash] = useState(Date.now())
    
    const [imgSteps, setImgSteps] = useState(null)
    const [imgStatic, setImgStatic] = useState(null)

    useEffect(() => {
        setImgSteps(data.imgSteps.map(img => img.replace('files/', `${process.env.REACT_APP_FILE_FOLDER}/`) + "?" + Date.now()))
        setImgStatic(data.imgStatic.map(img => img.replace('files/', `${process.env.REACT_APP_FILE_FOLDER}/`) + "?" + Date.now()))
    }, [imgHash]);

    const [edit, setEdit] = useState(false)
    const activateEdit = (mode) => {
        edit === mode ? setEdit(null)
        : setEdit(mode);
    }
    const changeHash = () => {
        setImgHash(Date.now())
    }
    return (
        <div className="border-bottom mb-4 pb-4">
            <div className="row" style={{cursor: 'pointer'}} onClick={() => setExtended(!extended)}>
                <div className="col-10">
                    <strong><span>{extended ? <AiOutlineMinus/> : <AiOutlinePlus/>}</span>{data.title}</strong>
                </div>
                {/* <div className="col-2">
                    <img width="100%" src={data.imgThumb} />
                </div> */}
            </div>
            {extended &&
                <>
                    <div className="row">
                        <div className="col-6 text-center">
                            <div className="border d-inline-block pl-1 pr-1 mb-2">
                            <strong>Erste Öffnung</strong>
                            <AiOutlineSetting 
                                style={{fontSize: '25px', cursor: 'pointer', marginLeft: '15px'}}
                                onClick={() => activateEdit(1)} />
                            </div>
                            <Slideshow slides={imgSteps} 
                                textPrev='<' 
                                textNext='>' 
                                number />
                        </div>
                        <div className="col-6 text-center">
                            <div className="border d-inline-block pl-1 pr-1 mb-2">
                                <strong>Nach erster Öffnung</strong>
                                <AiOutlineSetting 
                                    style={{fontSize: '25px', cursor: 'pointer', marginLeft: '15px'}}
                                    onClick={() => activateEdit(2)} />
                            </div>
                            <Slideshow 
                                slides={imgStatic} 
                                textPrev='<' 
                                textNext='>' 
                                number />
                        </div>
                    </div>
                    {edit && <div className="row">
                        {(edit === 1 ? data.imgSteps : data.imgStatic)
                            .map((img, i) => {
                            return <ImgToChange 
                                parentHashChange={(hash)=>changeHash(hash)} 
                                key={i} 
                                name="" 
                                nr={i+1} 
                                path={img.replace('files/', `${process.env.REACT_APP_FILE_FOLDER}/`)}/>
                        })}
                    </div>}
                </>
                
            }
            {/* {data && extended && data.map((group, i) => {
                console.log(node)
                return <HelpGroupContent key={i} node={node +"/"+ group} data={group} nr={i} />
            })} */}
        </div>
    )
}

export default DataExSteps
