import * as actionTypes from './actionTypes';
import api from '../../axios-api';
import { getFormData } from '../../shared/utility';

export const planFirstVisit = () => {
    const token = localStorage.getItem('token');
    const data = {userID: token}
    let dataToSend = getFormData(data);
    
    api.post("/api/start_planing.php", dataToSend)
    .then(response => {
      console.log("start planing", response);
    }).catch(err=> {
        console.log("error with api call to start planing", err);
    });

    return {
        type: actionTypes.PLAN_FIRST_VISIT,
    };
}

export const closeHelpTutorial = () => {
    return {
        type: actionTypes.CLOSE_HELP_TUTORIAL,
    };
}

export const addVar = () => {
    return {
        type: actionTypes.PLAN_ADD_VAR,
    };
}

export const removeVar = (id) => {
    return {
        type: actionTypes.PLAN_REMOVE_VAR,
        id: id
    }
}

export const addTube = () => {
    return {
        type: actionTypes.PLAN_ADD_TUBE,
    }
}

export const removeTube = (index) => {
    return {
        type: actionTypes.PLAN_REMOVE_TUBE,
        index: index
    }
}

export const checkResult = () => {
    return {
        type: actionTypes.PLAN_CHECK_RESULT,
    }
}

export const planChangeValue = (event, id, which) => {
    var dataset = event.target.options[event.target.selectedIndex].dataset;
    return {
        type: actionTypes.PLAN_CHANGE_VALUE,
        idToChange: id,
        typeToChange: which,
        newValue: event.target.value,
        newGroup: dataset.group,
        unitCorrect: dataset.correct,
        unitPreset: dataset.preset
    }
}

export const planChangeTubeValue = (event, index, varId) => {
    return {
        type: actionTypes.PLAN_CHANGE_TUBE_VALUE,
        tubeIndex: index,
        varId: varId,
        newValue: event.target.value
    }
}

export const planChangeRepetitionCount = (event) => {
    return {
        type: actionTypes.PLAN_CHANGE_REPETITION_COUNT,
        newValue: event.target.value
    }
}

export const planChangeRepetitionDesc = (event) => {
    return {
        type: actionTypes.PLAN_CHANGE_REPETITION_DESC,
        newValue: event.target.value
    }
}

export const setPlanData = (dataString) => {
    return {
        type: actionTypes.SET_PLAN_DATA,
        planData: dataString
    }
}

export const getPlanData = (id) => {
    return dispatch => {
        const data = { exID: id }
        let dataToSend = getFormData(data);
        
        api.post("api/get_plan_data.php", dataToSend)
            .then(response => {
                if(response.data) {
                    dispatch(setPlanData(response.data));
                }
            })
    }   
}

export const resetPlanData = (template=1) => {
    return {
        type: actionTypes.RESET_PLAN_DATA,
        template
    }
}