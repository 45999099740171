import React, {useState, useEffect} from 'react'
import Input from '../../../../components/UI/Input/Input'

const Experiments = ({changeStep, data}) => {
    const [inputData, setInputData] = useState(null);
    const [allFilled, setAllFilled] = useState(false);

    useEffect(() => {
        setInputData(null)
    }, [data]);

    const changeInputData = (e) => {
        if(e.target.value.length > 30) {
            setAllFilled(true)
        } else {
            setAllFilled(false)
        }
        setInputData({[data.input]: e.target.value})
    }

    useEffect(() => {
        setInputData('')
        setAllFilled(false)
    }, [data]);

    return (
        <div>
            <h2>Experimente planen</h2>
            <div dangerouslySetInnerHTML={{ __html: data?.description}}></div>
            <Input elementType={"textarea"} changed={changeInputData} value={inputData ? inputData[data.input] : ''}/>
            <button className="btn btn-secondary float-right" disabled={localStorage.getItem('userAuthCode').includes("debug") ? false : !allFilled} onClick={() => changeStep(inputData)}>Weiter</button>
        </div>
    )
}

export default Experiments
