import React, {useState, useEffect} from 'react'
import RadioRow from '../../RadioRow/RadioRow'

const MultipleChoice = ({data, changeStep}) => {
    const [inputData, setInputData] = useState({})
    const [allTouched, setAllTouched] = useState(false)

    useEffect(() => {
        setInputData(null)
        setAllTouched(false)
    }, [data]);

    useEffect(() => {
        if(inputData) {
            if(Object?.keys(inputData).length === data?.questions.length) {
               setAllTouched(true);
             }
         }
    }, [inputData]);

    const changeRadio = (e) => {
        const newInputData = {...inputData, [e.target.name]: e.target.value}
        setInputData(newInputData)
    }
    return (
        <div>
            <div style={{marginBottom: '20px'}} dangerouslySetInnerHTML={{__html: data?.description}}></div>
            {data?.questions.map((q, i) => 
                <RadioRow key={i} question={q} changeRadio={changeRadio} selectedValue={inputData ? inputData[q.name] : undefined}/>
            )}
            <button 
                disabled={localStorage.getItem('userAuthCode').includes("debug") ? false: !allTouched} 
                className="btn btn-secondary float-right" onClick={() => changeStep(inputData)}>Weiter</button>
        </div>
    )
}

export default MultipleChoice
