import Input from '../../../../../components/UI/Input/Input'
import { useMemo, useCallback } from 'react';
const containerStyle = {marginRight: '100px'};

const Button = ({reviewButtonClicked, pos, answer, selected}) => {
    console.log("selected", selected)
    const onClick = useCallback((e) => reviewButtonClicked(pos+1), [pos, reviewButtonClicked])
    return (
        <button 
            onClick={onClick}
            className={(selected ? 'active ' : '') + `btn btn-secondary m-1 btn-block text-left`}
            value={pos+1}>
            <div dangerouslySetInnerHTML={useMemo(() => ({__html: answer}), [answer])}></div>
        </button>
    )
}

const HelpReviewButtons = (props) => {

    const buttons = useMemo(() => props.answers.map(
        (answer, i) => <Button selected={props.selectedValue === i+1} key={i} pos={i} answer={answer} reviewButtonClicked={props.reviewButtonClicked} />
        ), 
        [props.answers, props.reviewButtonClicked]
        );

    return (
        <div className="d-flex flex-column" style={containerStyle}>
            {buttons}    
            <div className="w-100"> 
                <Input placeholder="Sonstiges" className="col" elementType='input' changed={props.onReviewInputChanged} elementConfig={{placeholder: '... Sonstiges:'}}/>
            </div>
            {props.hasButton && <>
                <hr />
                <button
                    className="float-right btn btn-secondary m-1" 
                    onClick={props.onNextStep}
                    disabled={!props.enableNext} >
                Weiter
                </button></>}
        </div>
    )
}

export default HelpReviewButtons
