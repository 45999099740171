import React, {useState, useEffect} from 'react';
import classes from './Lightbox.module.sass';
import Modal from '../Modal/Modal';
import Pager from '../../../containers/Exercises/Exercise/Pager/Pager';

const Lightbox = (props) => {
    const [imagePos, setImagePos] = useState(0);
    
    useEffect(() => {
        if(imagePos >= props.imgSrc.length) {
            props.close()
            setImagePos(0)
        }
    }, [imagePos]);

    return (
        <Modal show={props.show} modalClosed={props.required ? null : props.close} noframe>
            <img src={`../../${props.imgSrc[imagePos]}`} />
            <Pager nextPage={() => setImagePos(imagePos+1)}
                prevPage={() => setImagePos(imagePos-1)}
                pagerActive
                prevActive={imagePos>0} 
                textPrev="Zurück" 
                textNext={imagePos+1==props.imgSrc.length ? "Ok" : "Weiter"} />
        </Modal>
    );
}

export default Lightbox;