import React, {useEffect, useState} from 'react'
import classes from './ExerciseStepCondition.module.sass'

const ExerciseStepCondition = (props) => {
    const [clickedCondition, setClickedCondition] = useState(null)
    
    useEffect(() => {
        props.pagerStatus(false)
    }, []);

    const onCheckCondition = (e, answ) => {
        
        if(answ === props.conditions.correct) {
            props.pagerStatus(props.count, true)
            setClickedCondition([answ, classes.Right])
        } else {
            setClickedCondition([answ, classes.Wrong])
            props.pagerStatus(props.count, false)
        }
    }

    let conditonDom = ( 
            <div className={classes.Conditions}>
                <div>{props.conditions.question}</div>
                <div>
                    {props.conditions.answers.map((answ, i) => {
                        return <button 
                            key={i} 
                            onClick={(e) => onCheckCondition(e, i+1)}
                            className={'btn btn-primary btn-sm m-1 '
                                + (clickedCondition && clickedCondition[0] == i+1 ? clickedCondition[1] : '')}
                            >
                                {answ}
                            </button>
                    })}
                </div>
            </div>
        )

    return (
        <div>
            {conditonDom}
        </div>
    )
}

export default ExerciseStepCondition
