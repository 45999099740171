import React, { useEffect, useState } from 'react'

import Moment from 'react-moment'
import classes from './PanelExercises.module.sass'
import api from '../../../../axios-api'
import { getFormData } from '../../../../shared/utility'
import PlanningTable from '../../../Exercises/Exercise/Planning/PlanningTable/PlanningTable'
import planingData from '../../../../data/planningData.json'
import Exercise from './PanelExercise/PanelExercise'


const PanelExercises = (props) => {
    const [exercises, setExercises] = useState([])
    const [exMode, setExMode] = useState();

    useEffect(() => {
        const data = { 
            table: 'TUserExercises', 
            conditionField: 'userID', 
            condition: props.userID
        }    
        
        let dataToSend = getFormData(data);
        api.post('/api/fetch_all_data.php', dataToSend)
        .then(response => {
            console.log(response.data)
            if(response.data) {
                setExercises(response.data)
            }
        }).catch(err => {
            console.log("error when fetching users in the backend", err)
        })
    }, [])

    return (
        <div className={classes.Exercise + " col-12"}>
            {exercises.map(ex => {
                return (
                    <Exercise clicked={props.clicked} ex={ex}/>
                )}
            )}
        </div>
    )
}

export default PanelExercises
