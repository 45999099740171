import { useState, useEffect, useRef } from "react";
import api from '../../../axios-api';
import useFb from '../../../hooks/use-fb';

import classes from './HelpManager.module.sass';
// import helpData from '../../../data/helpCenterData.json';
// import helpTutorialData from '../../../data/helpTutorialGroups.json';

import { getFormData } from '../../../shared/utility';
import Lightbox from '../../../components/UI/Lightbox/Lightbox';


const HelpManager = (props) => {
    //const api = axios.create({baseURL: 'http://localhost:8888'});
    //const api = axios.create({baseURL: 'https://kajetansom.ch/diluna-2'});
    // const [helpManagerGroup, setHelpManagerGroup] = useState(null);
    const [showHelpLightbox, setShowHelpLightbox] = useState(true);
    const [showHiddenText, setShowHiddenText] = useState(false);
    //const [helpManagerGroupData, setHelpManagerGroupData] = useState(helpTutorialData.EG3);
    const thumbImg = useRef()
    let [helpType, setHelpType] = useState('overview');
    let helpInfo = null;
    
    const [helpData, setHelpData] = useState(null)
    const [helpManagerGroupData, setHelpManagerGroupData] = useState(null)
    
    const {loader: loader1, error: e1, sendRequest: getHelpCenter} = useFb()
    
    const userGroup = localStorage.group === '0' ? 'KG1' 
        : localStorage.group === '1' ? 'KG1'
        : localStorage.group === '2' ? 'EG1'
        : localStorage.group === '3' ? 'EG2'
        : localStorage.group === '4' ? 'EG3' : 'EG3'
    const {loader: loader2, error: e2, sendRequest: getTutorialGroups} = useFb()
    

    useEffect(() => {
        console.log("HELP MANAGER PROPS: ", props)
        //getHelpCenter(`/helpCenterData.json`, setHelpData)
        getHelpCenter(`/exerciseData/exercises/${props.exPos-1}/exHelpCenterData.json`, setHelpData)
        
        getTutorialGroups(`/helpTutorialGroups/${userGroup}.json`, setHelpManagerGroupData)
    }, [getHelpCenter, getTutorialGroups]);

    //console.log("this groups data: ", helpTutorialData['EG3'])
    // console.log(`/helpTutorialGroups/${userGroup}.json`)
    // console.log("all Group data: ", helpManagerGroupData)

    const openHelpTutorial = () => {
        console.log(helpManagerGroupData);
        // need to load the thumb image manually.. otherwise it won't always show
        thumbImg.current.src = '../../' + helpManagerGroupData.imgThumb
        setShowHelpLightbox(true)
        
        if(props.tracking) {
            const token = localStorage.getItem('token');
            const data = {
                userID: token
            }
            const dataToSend = getFormData(data);
            
            api.post("/api/change_help_tut_opened.php", dataToSend)
                .then(response => {
                    console.log(`Added Tutorial Used count.`);
                    console.log(response)
                }).catch(error => {
                    console.log("error when adding help Tutorial count, change_help_tut_opened.php");
                    console.log(error);
                });
        }
    }

    const onCloseHelpTutorial = () => {
        props.onCloseHelpTutorial()
        setShowHelpLightbox(false)
    }

    const onCloseHelp = () => {
        //props.changeReviewOn('helpType')
        //props.displayHelpReview(true)
        if(props.tracking) {
            const token = localStorage.getItem('token');
            const data = {
                userID: token
            }
            const dataToSend = getFormData(data);
            api.post("/api/close_help.php", dataToSend)
            .then(response => {
                console.log(`Help Session closed`);
                console.log(response.data)
            }).catch(error => {
                console.log("error when closing help with api, close_help.php");
                console.log(error);
            });
        }

        setShowHiddenText(false)
        props.onCloseHelpManager()
    }

    const onCloseHelpDirect = () => {
        props.changeReviewOn('helpTypeDirect')
        props.tracking && props.displayHelpReview(true)
        setShowHiddenText(false)
        props.onCloseHelpManager()
    }

    const onHelpOverview = () => {
        props.tracking && props.displayHelpReview(true) // ENABLE THIS LINE TO ACTIVATE THE HELPREVIEW
        props.changeReviewOn('helpType')
        setShowHiddenText(false)
        setHelpType('overview')
    }

    const onOpenHelpType = (type, subType) => {
        console.log("HELP TYPE", subType)
        props.displayHelpReview(true)

        onChangeHelpType(type, subType)
    }

    const onChangeHelpType = (type, subType) => {
        props.changeReviewOn('helpType')
        setHelpType([type, subType]);

        if(props.tracking) {
            // open new help session and save timestamp
            const token = localStorage.getItem('token');
            const data = {
                userID: token,
                helpType: type,
                helpSubType: subType
            }
            let dataToSend = getFormData(data);
            
            api.post("/api/open_help_type.php", dataToSend)
                .then(response => {
                    console.log(`new Help Type Added to the Session: ${type}->${subType}`);
                    console.log(response.data)
                    // ADD Review of Bedarfsorientierung
                    props.changeReviewOn('bedarf')
                    props.displayHelpReview(true)
                    
                }).catch(error => {
                    console.log("error when selecting a help type");
                    console.log(error);
                });
        }
    }

    if (helpType === 'overview' && helpData && helpManagerGroupData) {
        helpInfo = (
            <>
                <h4>Infos zu den Hilfen</h4>
                {Object.keys(helpData).map(type => {
                    const typeData = helpData[type];
                    return (
                        <div key={type} className="row zi-middle">
                            <div className="col-6">
                                <span className={classes.LoadedText} dangerouslySetInnerHTML={{__html: typeData.desc}}></span>
                            </div>
                            <div className="col-6">
                                <button onClick={() => onChangeHelpType(type, 'hint')} className="btn btn-primary btn-sm m-1">Hinweis</button>
                                <button onClick={() => onChangeHelpType(type, 'example')} className="btn btn-primary btn-sm m-1">Beispiellösung</button>
                                <button onClick={() => onChangeHelpType(type, 'solution')} className="btn btn-primary btn-sm m-1">Lösung</button>
                            </div>
                        </div>
                    );
                })}
                <div onClick={openHelpTutorial} className={classes.HelpGuideButton}>
                    <img ref={thumbImg} src={'../../' + helpManagerGroupData.imgThumb} />
                </div>
                <button onClick={onCloseHelp} className="btn btn-primary btn-sm m-3">
                    Hilfen schliessen</button>
            </>
        )
    } else if(helpData && helpManagerGroupData) {
        let selectedHelp = helpData[helpType[0]][helpType[1]]
        let templateString = selectedHelp.text;
        if(showHiddenText && selectedHelp.hiddenText) {
            templateString += `<div class='${classes.HiddenText}'>${selectedHelp.hiddenText}</div>`
        }
        if(selectedHelp.images) {
            Object.keys(selectedHelp.images).map(imgName => {
                const imgSrc = `../../files/exercises/${props.exercise}/helpCenter/${helpType[0]}/${selectedHelp.images[imgName]}`;
                const imgDom = `<img src="${imgSrc}" alt="${imgName}" />`;
                templateString = templateString.replaceAll("<"+imgName+">", imgDom);
                templateString = templateString.replaceAll("<"+imgName+"_left>",
                    imgDom.replaceAll('/>',`class='${classes.LeftImg}' />`));
                templateString = templateString.replaceAll("<"+imgName+"_big>", 
                    imgDom.replaceAll('/>',`class='${classes.BigImg}' />`));
                templateString = templateString.replaceAll("<"+imgName+"_full>", 
                    imgDom.replaceAll('/>',`class='${classes.FullImg}' />`));
            })
        }
        
        let type = null;
        switch(""+helpType[1]) {
            case 'hint':
                type = "Hinweis: " 
                break;
            case 'example':
                type = "Beispiellösung: " 
                break;
            case 'solution':
                type = "Lösung: "
                break; 
            default:
                type = ""
        }

        helpInfo = (
            <div className="">
                <h4>{(helpType[1]==="hint" ? "Hinweis: " 
                 :  helpType[1]==="example" ? "Beispiellösung: " 
                 :  helpType[1]==="solution" ? "Lösung: " : "") + helpData[helpType[0]].title}
                </h4>
                <div className={classes.LoadedText} dangerouslySetInnerHTML={{__html: templateString}}></div>
                {(!showHiddenText && selectedHelp.hiddenText) &&
                    <button className="btn btn-secondary m-4 mx-auto d-block" onClick={() => setShowHiddenText(true)}>Ganze Hilfe anzeigen.</button>
                }
                {(showHiddenText || !selectedHelp.hiddenText) && (
                <div className="row">
                    <button onClick={onHelpOverview} className="btn btn-primary btn-sm m-3">
                        Hilfenübersicht</button>
                    <button onClick={onCloseHelpDirect} className="btn btn-primary btn-sm m-3">
                        Hilfen schliessen</button>
                </div>)}
            </div>
        )
    }

    return (
        <div className={classes.HelpManager}>
            {helpManagerGroupData &&
                <Lightbox show={showHelpLightbox} 
                    required
                    close={onCloseHelpTutorial} 
                    imgSrc={
                        props.helpTutorial 
                        ? helpManagerGroupData.imgSteps 
                        : helpManagerGroupData.imgStatic }
                    />
            }
            {loader2}
            {helpInfo}
        </div>
    )
}

export default HelpManager;