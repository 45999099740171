import React, { useState, useEffect, useRef, memo }  from 'react';
import { connect } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';
import fb from '../../axios-fb';
import * as actions from '../../store/actions'
import ExercisesOverview from './ExercisesOverview/ExercisesOverview';
import Exercise from './Exercise/Exercise';
//import exercisesData from '../../data/exercisesData.json';
import TopicInfo from './TopicInfo/TopicInfo';
import useFullPageLoader from '../../hooks/useFullPageLoader';
import PrePostQ from '../Questions/PrePostQ/PrePostQ';
import { getDataFromTable } from '../../shared/api-calls';


const Exercises = memo( props => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [exercisesData, setExercisesData] = useState(null)
    const [preQ, setPreQ] = useState(false)
    const [postQ, setPostQ] = useState(false)
    const [userExDone, setUserExDone] = useState(0)
    const [allFinished, setAllFinished] = useState(false)
    const [exercisesPreview, setExercisesPreview] = useState([])
    const [exTopicInfo, setExTopicInfo] = useState([])
    const history = useHistory()
    
    useEffect(() => {
        if(props.isAuthenticated) {
            localStorage.getItem('exStep') 
                ?   history.push(`/exercises/${localStorage.getItem('exType')}/${localStorage.getItem('exStep')}`)
                :   history.push(`/exercises`);
        } else {
            history.push(`/login`);
        } 
    }, [props.isAuthenticated]);

    useEffect(() => {
        let unmounted = false;
        // get user data and check if he has to do the pre check
        getDataFromTable('TUsers','userID', localStorage.getItem('token'))
            .then(result => {
                if(!unmounted) {
                    if(result && result[0]) {
                        const userData = result[0];
                        // check pre check
                        if(localStorage.getItem('userTracking')) {    
                        }
                        if(!userData.userPreQ) {
                            if(localStorage.getItem('userTracking') === '1') {
                                setPreQ(true)
                            }
                        } else if(userData.userExDoneCount >= process.env.REACT_APP_EXERCISE_COUNT) {
                            // check if all ex done
                            if(!userData.userPostQ) {
                                // if user Post Q is not done 
                                if(localStorage.getItem('userTracking') === '1') {
                                    setPostQ(true)
                                } else {
                                    setAllFinished(true)
                                }
                            } else {
                                setAllFinished(true)
                            }
                        }
                        setUserExDone(userData.userExDoneCount)
                    }
                }
            });
    }, [userExDone]);

    useEffect(() => {
            let unmounted = false;
            // check for completed exercises
            getDataFromTable('TUserExercises', 'userID', localStorage.getItem('token'))
            .then(userEx => {
                if(!unmounted) { 
                    let completedExercices = '';
                    userEx?.map(ex => {
                        if(ex.exEnd !== '' && ex.exEnd !== undefined && ex.exEnd !== null) {
                            completedExercices += ex.exType + ';'
                        }
                    })
                    localStorage.setItem('completed', completedExercices);
                }
            })
        return () => { unmounted = true };
    }, []);

    const isMountedRef = useRef(false);

    useEffect(() => {
        console.log(isMountedRef);
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        }
    }, []);

    useEffect(() => {
        showLoader();
        fb.get('/exerciseData.json')
            .then(response => {
            
                if(isMountedRef.current) { 
                    setExercisesData(response.data)
                    const exPrev = [];
                    const exFull = [];
                    const exInfo=[];
                
                    response.data.exercises.map(ex => {
                        exPrev.push({
                            type: ex.type,
                            title: ex.title,
                            mainImg: ex.mainImg,
                            active: ex.active,
                            exPos: ex.exPos

                        });
                        exFull.push({
                            type: ex.type,
                            title: ex.title,
                            info: ex.info,
                            steps: ex.steps,
                            sampleSolution: ex.sampleSolution
                        });
                        exInfo.push({
                            type: ex.type,
                            info: ex.topicInformation
                        });
                    });
                    setExercisesPreview(exPrev)
                    setExTopicInfo(exInfo)
                    hideLoader();
                }
            })
    }, [userExDone, props.isAuthenticated]);



    const onNextExCount = () => {
        setUserExDone(userExDone+1)
    }

    const onEndPostQ = () => {
        setAllFinished(true)
        setPostQ(false)
    }

    return (
        <div className="container">
            {loader}
            {preQ ?     <PrePostQ mode="pre" endTest={() => setPreQ(false)}/>
            : postQ ?   <PrePostQ mode="post" endTest={onEndPostQ}/>
            : exercisesData &&
                <Switch>
                    <Route path="/exercises/info/:id">
                        <TopicInfo topicInfo={exTopicInfo} />
                        {/* <TopicInfo topicInfo={exercisesData.topicInformation} /> */}
                    </Route>

                    <Route path="/exercises/:id">
                        <Exercise 
                            //exAll={exercisesFull}
                            selectedEl={props.exSelected} 
                            exInfo={exTopicInfo}
                            onNextExCount={onNextExCount}
                            />
                    </Route>
                    
                    <Route path="/exercises">
                    {/* <Route path="/exercise"> */}
                        <ExercisesOverview 
                            isNextEx={userExDone+1}
                            topicInfo={exTopicInfo} 
                            exercisesPreview={exercisesPreview} 
                            userExDone={userExDone}
                            allFinished={allFinished}
                            />
                    </Route>
                </Switch>        
            }   
        </div>
    )
});

// -> redux
const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        exSelected: state.exercise.exSelected,
        exCompleted: state.exercise.exCompleted
    }
}

// const mapDispatchToProps = dispatch => {
//     return {
//         onSelectExChange: (type, title) => dispatch(actions.selectExercise(type, title))
//     }
// }

export default connect(mapStateToProps, null)(Exercises);
