import React, { useState, useEffect } from 'react'
import ImageUpload from './ImageUpload'

const ImgToChange = ({nr, name, path, parentHashChange=null}) => {
    const [imgHash, setImgHash] = useState(0)
    useEffect(() => {
        console.log(imgHash)
    }, []);
    const changeHash = () => {
        setImgHash(Date.now())
        parentHashChange && parentHashChange(Date.now())
    }
    return (
        <div className="border-bottom pb-2 mb-2 mr-4">
            <div className="d-flex">
                <div className="mr-2">
                {name !=="" ? nr : `Bild ${nr}:`}</div>
                {name !=="" &&<div className="mr-2">Name: <strong>{name.toUpperCase()}</strong></div>}
            </div>
            <em>{path}</em>
            <div className="d-flex">
                <img 
                    className="mr-4"
                    style={{maxHeight: '200px', maxWidth: '700px', height: '100%'}}
                    src={`${path}?${imgHash}`} />
                <ImageUpload path={path} onUpload={changeHash} />
            </div>
        </div>
    )
}

export default ImgToChange
