import React, {useState, useEffect} from 'react'

import PlanningTable from '../../Planning/PlanningTable/PlanningTable'
import classes from './SampleSolution.module.sass'
import useFb from '../../../../../hooks/use-fb'

const SampleSolution = (props) => {
    const [planningData, setPlanningData] = useState(null)
    const {loader, error, sendRequest} = useFb()
    
    useEffect(() => {
        sendRequest(`/planingData/${props.exSelected}.json`, setPlanningData);
    }, [props.exSelected]);
    
    return (
        <>
        <div className={classes.PlanningTable}>
            <div className={classes.UserData}>
            {planningData &&
                    <PlanningTable 
                        planVariables={props.solutionData.planVariables}
                        planTubes={props.solutionData.planTubes}
                        planRepetitionDesc={props.solutionData.repetitionDesc}
                        planRepetitionCount={props.solutionData.repetitionCount}
                        displayOnly={true}
                        planningData={planningData}
                    />
            }
                </div>
        </div>
        </>
    )
}

export default SampleSolution
