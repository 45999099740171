import React, {useState, useEffect} from 'react'
import useFb from '../../../hooks/use-fb'
import { insertPrePost, endPrePost, getDataFromTable } from '../../../shared/api-calls';

import Experiment from './Experiment/Experiment'
import MultipleChoice from './MultipleChoice/MultipleChoice'
import Questions from './Questions/Questions'

const steps = [
    "intro",
    "description",
    "experiments",
    "multiplechoice",
    "questions"
]

const PrePostQ = ({mode, endTest}) => {
    const [questionairyData, setQuestionairyData] = useState(null);
    const [step, setStep] = useState(0);
    const [substep, setSubstep] = useState(0);
    const {loader, error, sendRequest} = useFb();
    const [dataInput, setDataInput] = useState(null);
    const [prePostPos, setPrePostPos] = useState(0);

    useEffect(() => {
            sendRequest(`/questionairy/${mode}.json`, setQuestionairyData);             
    }, []);

    useEffect(() => {
        getDataFromTable('TUsers', 'userID', localStorage.getItem('token'))
            .then(resp => {
                if(resp[0]?.userPrePostQPos) {
                    setPrePostPos(resp[0]?.userPrePostQPos)
                }
            })
            
            // make sure we are on the right step
            if(questionairyData && prePostPos) {
                const exL = questionairyData[steps[2]].length;
                const multL = questionairyData[steps[3]].length;
                const quL = questionairyData[steps[4]].length;

                if(prePostPos < exL) {
                    console.log(steps[2])
                    setStep(2)
                    setSubstep(prePostPos)
                    console.log("substeps:", prePostPos)
                    return;
                }

                if(prePostPos < exL + multL) {
                    console.log(steps[3])
                    console.log("substeps:", prePostPos-exL)
                    setStep(3)
                    setSubstep(prePostPos - exL)
                    return;
                }

                if(prePostPos < exL + multL + quL) {
                    console.log(steps[4])
                    console.log("substeps:", prePostPos - multL - exL)
                    setStep(4)
                    setSubstep(prePostPos - multL - exL)
                    return;
                }
            }
    }, [questionairyData, prePostPos]);


    const finishQ = () => {
        endPrePost(localStorage.getItem('token'), mode);
        endTest()
    }

    const saveDataToDB = async (data) => {
        return await insertPrePost(localStorage.getItem('token'), mode, data);
    }
    
    const changeSubstep = async (inputData) => {
        await saveDataToDB(inputData)
        if(substep+1 < questionairyData[steps[step]].length) {
            setSubstep(substep+1)
        } else {
            if(step+1<=steps.length-1) {
                setStep(step+1)
                setSubstep(0)
            } else {
                // end test and confim it in DB
                finishQ()
            }
        }
    }

    return (
        <div>
            {loader}
            {questionairyData && 
            <>
                {steps[step] === 'intro' ? 
                    <>
                        <div dangerouslySetInnerHTML={{ __html: questionairyData.intro}}></div>
                        <button className="btn btn-secondary float-right" onClick={() => setStep(step+1)}>Weiter</button>
                    </>
                : steps[step] === 'description' ?
                    <>
                        <div dangerouslySetInnerHTML={{ __html: questionairyData.description}}></div>
                        <button className="btn btn-secondary float-right" onClick={() => setStep(step+1)}>Weiter</button>
                    </>
                :   steps[step] === 'experiments' ? 
                    <Experiment data={questionairyData.experiments[substep]} dataInput={dataInput} changeStep={changeSubstep}/>
                :   steps[step] === 'multiplechoice' ? 
                    <MultipleChoice data={questionairyData.multiplechoice[substep]} changeStep={changeSubstep}/>
                
                :   <Questions data={questionairyData.questions[substep]} substep={substep} changeStep={changeSubstep}/> 
                    //:   <Questions data={questionairyData.questions} substep={substep} changeStep={changeSubstep}/> 
                }
            </>}
            {localStorage.getItem('userAuthCode').includes("debug") && <button onClick={finishQ}>SKIP</button>}
        </div>
    )
}

export default PrePostQ
