import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initState = {
    exSelected: null,
    exTitle: null,
    exPos: null,
    exStep: null,
    exStepsVisited: {},
    userResult: '',
    userInterpretation: '',
    userExplenation: '',
    exPlaningCompleted: [],
    exCompleted: [],
    exSuccess: false,
    exAdditionalInput: {},
    topicInfoVisible: false
}

const showTopicInfo = (state, action) => {
    return updateObject(state, { 
        topicInfoVisible: action.topicInfoVisible
    });    
}

const selectExercise = (state, action) => {
    localStorage.setItem('currExercise', action.exSelected)
    return updateObject(state, { 
        exSelected: action.exSelected,
        exTitle: action.exTitle,
        exPos: action.exPos,
        exStep: action.exStep,
    });        
} 

const loadActiveExercise = (state, action) => {
    return updateObject(state, { 
        //exId: action.exId,
        exSelected: action.exSelected,
        exPos: action.exPos,
        exStep: action.exStep,
        // exStepsVisited: {},
        userResult: action.exUserResult,
        userInterpretation: action.exUserInterpret,
        userExpl: action.exUserExpl,
    });        
}

const changeExerciseStep = (state, action) => {
    return updateObject(state, {
        exStep: action.exStep,
        exStepsVisited: {...state.exStepsVisited, [action.exStep]: Date.now()}
        //exStepsVisited: action.exStep === 'reset' ? null : {...state.exStepsVisited, [action.exStep]: Date.now()}
    });
}

const changeResult = (state, action) => {
    return updateObject(state, {
        userResult: action.value,
    });
}

const changeInterpretation = (state, action) => {
    return updateObject(state, {
        userInterpretation: action.value,
    });
}

const changeExplenation = (state, action) => {
    return updateObject(state, {
        userExplenation: action.value,
    });
}

const changeAdditionalInput = (state, action) => {
    return updateObject(state, {
        exAdditionalInput: {...state.exAdditionalInput, [action.name]: action.value}
    });
}

const completePlaning = (state, action) => {
    
    return updateObject(state, {
        exPlaningCompleted: state.exPlaningCompleted.concat(action.exercise),
        exSuccess: action.success
    });
}

const completeExercise = (state, action) => {
    return updateObject(state, {
        exCompleted: state.exCompleted.concat(action.exercise),
        exStepsVisited: null,
        exSelected: null,
        exStep: null,
        userResult: '',
        userInterpretation: '',
        userExplenation: '',    
        exAdditionalInput: {},
    });
}

const reducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.SELECT_EXERCISE: return selectExercise(state, action);
        case actionTypes.CHANGE_EXERCISE_STEP: return changeExerciseStep(state, action);
        case actionTypes.CHANGE_RESULT: return changeResult(state, action);
        case actionTypes.CHANGE_INTERPRETATION: return changeInterpretation(state, action);
        case actionTypes.CHANGE_EXPLENATION: return changeExplenation(state, action);
        case actionTypes.COMPLETE_PLANING: return completePlaning(state, action);
        case actionTypes.COMPLETE_EXERCISE: return completeExercise(state, action);
        case actionTypes.CHANGE_ADDITIONAL_INPUT: return changeAdditionalInput(state, action);
        case actionTypes.SHOW_TOPIC_INFO: return showTopicInfo(state, action);
        case actionTypes.LOAD_ACTIVE_EXERCISE: return loadActiveExercise(state, action);
        default: return state;
    }
}

export default reducer;