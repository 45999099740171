import React from 'react';
import { Route, useHistory } from "react-router-dom";

import { RiArrowGoBackLine } from 'react-icons/ri';
import classes from './TopicInfos.module.sass';

const TopicInfo = (props) => { 
    const history = useHistory();
        
    const routes = (
        props.topicInfo.map((topicInfo, i) => {
            const info = topicInfo.info
            let templateString = info.info.text;

            if(info.info.images?.length > 0) {
                
                info.info.images.map(img => {
                    const imgSrc = `../../files/exercises/${topicInfo.type}/topicInformation/${img.path}`;
                    const imgDom = `<figure><img src="${imgSrc}" alt="${img.path}" /><figcaption>${img.caption}</figcaption></figure>`;
                    
                    templateString = templateString.replaceAll("<"+img.name+"_left>",
                        imgDom.replaceAll('<figure>',`<figure class='${classes.LeftImg}'>`));
                    templateString = templateString.replaceAll("<"+img.name+"_big>", 
                        imgDom.replaceAll('<figure>',`<figure class='${classes.BigImg}'>`));
                    templateString = templateString.replaceAll("<"+img.name+"_full>", 
                        imgDom.replaceAll('<figure>',`<figure class='${classes.FullImg}'>`));
                    templateString = templateString.replaceAll("<"+img.name+"_caption_right>", 
                        imgDom.replaceAll('<figure>',`<figure class='${classes.CaptionRightSide}'>`));
                    templateString = templateString.replaceAll("<"+img.name+"_right_30pct>", 
                        imgDom.replaceAll('<figure>',`<figure class='${classes.Right30Pct}'>`));
                })
            }

            if(info.info.videos?.length > 0) {
                info.info.videos.map(vid => {
                    const vidSrc = `../../files/exercises/${topicInfo.type}/topicInformation/${vid.path}`;
                    const imgDom = `<figure><video style="width: 100%;" ${vid.attr} src="${vidSrc}" alt="${vid.path}" /><figcaption>${vid.caption}</figcaption></figure>`;
                    
                    templateString = templateString.replaceAll("<"+vid.name+"_left>",
                        imgDom.replaceAll('<figure>',`<figure class='${classes.LeftImg}'>`));
                    templateString = templateString.replaceAll("<"+vid.name+"_big>", 
                        imgDom.replaceAll('<figure>',`<figure class='${classes.BigImg}'>`));
                    templateString = templateString.replaceAll("<"+vid.name+"_full>", 
                        imgDom.replaceAll('<figure>',`<figure class='${classes.FullImg}'>`));
                    templateString = templateString.replaceAll("<"+vid.name+"_caption_right>", 
                        imgDom.replaceAll('<figure>',`<figure class='${classes.CaptionRightSide}'>`));
                    templateString = templateString.replaceAll("<"+vid.name+"_right_30pct>", 
                        imgDom.replaceAll('<figure>',`<figure class='${classes.Right30Pct}'>`));
                })
            }

            return (
                <Route path={"/exercises/info/"+topicInfo.type} key={i} exact>
                    <div>
                        <h1 dangerouslySetInnerHTML={{__html: info.title}} className="mt-5"></h1>
                        <h3>{info.subtitle ? info.subtitle : "Informationen" }</h3>
                        <div dangerouslySetInnerHTML={{__html: templateString}}></div>
                        <div className="row">
                            <div className="col"></div>
                            <button 
                                onClick={() => history.push("/exercises")}
                                className="btn btn-primary btn-sm m-3">
                                    Zurück zur Übersicht
                            </button>
                        </div>
                    </div>
                </Route>
            )
        })
    );

    return(
        <div className={classes.ExerciseBody}>
            <span 
                className={classes.Backlink} 
                onClick={()=>history.push("/exercises")}>
                    <RiArrowGoBackLine style={{fontSize: '30px'}} />
            </span>
            {routes}
        </div>
    )
}

export default TopicInfo;
