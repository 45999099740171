import { Link } from 'react-router-dom';

import ProjectLogo from '../../assets/logo.svg';
import classes from './Logo.module.sass';

const Logo = (props) => (
    <div className={classes.Logo}>
        <Link to="/"><img src={ProjectLogo} alt="diLuna Lernaufgaben" /></Link>
    </div>
);

export default Logo;