
import React from 'react'
import HelpContentSubSub from '../HelpContentSubSub/HelpContentSubSub'

const HelpContentTypes = ({data, node, type, category}) => {
    return (
        <div>
            <h6 className="mt-4">{type}</h6>
            {data.text && 
                <HelpContentSubSub 
                    name="Text"
                    category={category}
                    type="text"
                    node={node} 
                    data={data.text} images={data.images}/>
            }     
            {data.hiddenText && 
                <HelpContentSubSub 
                    name="Versteckter Text"
                    category={category}
                    type="hiddenText"
                    node={node} 
                    data={data.hiddenText} images={data.images}/>
            }        
        </div>
    )
}

export default HelpContentTypes


