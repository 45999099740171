import React, { useEffect, useState } from 'react'
import fb from '../../../axios-fb'
import DataExercise from './DataExercise/DataExercise';


const DataOverview = () => {
    const [data, setData] = useState(null)

    useEffect(() => {
        fb.get(".json")
        .then(response => {
            console.log(response.data)
            setData(response.data)
        })    
    }, []);

    return (
        <div>
            {data && (Object.keys(data.exerciseData.exercises).map(ex => {
                return <DataExercise 
                        key={ex}
                        node={"exerciseData/exercises/"+ex}
                        data={data.exerciseData.exercises[ex]}
                        />
                    
            }))}
        </div>
    )
}

export default DataOverview
