import React from 'react'
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions'

import classes from './Menu.module.sass';
import MenuItem from './MenuItem/MenuItem';

const NavigationItems = (props) => {

    const openTopicinformation = () => {
        props.close()
        props.onShowTopicInfo(!props.topicInfoVisible)

    }

    let menuItems = (
        <>
            <MenuItem link="/" >Was ist diLuna</MenuItem>
            <MenuItem link="/login" >Login</MenuItem>
            <MenuItem link="/contact" >Kontaktdaten</MenuItem>
            {/* <MenuItem link="/admin" >Admin</MenuItem> */}
        </>
    )

    if(props.isAuth) {
        menuItems = (
            <>
                <MenuItem link="/exercises" >Aufgaben</MenuItem>
                {/* <MenuItem link="/info/enzyme" >Infos zu Enzymen</MenuItem> */}
                {(localStorage.getItem('exType') !== undefined) && 
                <li className={classes.NormalLink}><a onClick={openTopicinformation} >Infos zu Thema</a></li>
                }
                <MenuItem link="/infos" >Was ist diLuna</MenuItem>
                <MenuItem link="/logout">Logout</MenuItem>
            </>
        )
    }
    
    return (
        <ul className={classes.Menu}>
            {menuItems}
        </ul>
    );
}

// -> redux
const mapStateToProps = state => {
    return {
        topicInfoVisible: state.exercise.topicInfoVisible
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowTopicInfo: (status) => dispatch(actions.showTopicInfo(status)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationItems);