import React from 'react'
import {PDFDownloadLink, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';


const styles = StyleSheet.create({
    title: {
      fontWeight: 400,
      fontSize: 13,
      marginTop: '30px',
      flexDirection: 'row',
      backgroundColor: '#FDE70E',
      padding: '13px 20px'
    },
    page: {
      //flexDirection: 'row',
      backgroundColor: '#fff'
    },
    center: {
      width: '90%',
      margin: '0 5%'
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    },
    row: {
      flexDirection: 'row',
    },
    font: {
      fontSize: 10
    },
    col: {
      flex: '1',
      padding: '0px 10px 10px 0'
    },
    fontBold: {
      fontSize: 10,
      marginTop: 10,
      fontWeight: 'bold'
    },
    innerWrapper: {
      padding: 20,
      backgroundColor: '#fffce4',
    },
    marginBottom: {
      fontSize: 10,
    }
  });

const PDFDocument = (props) => {
  
  let def = props.planVars.map((v,i) => {
     console.log(v);
      return (
          <View style={styles.row}>
            <View style={styles.col}>
              <Text style={styles.font}>{v.type}</Text>
            </View>
            <View style={styles.col}>
              <Text style={styles.font}>{v.option}</Text>
            </View>
            <View style={styles.col}>
              <Text style={styles.font}>{v.unit}</Text>
            </View>
            <View style={styles.col}>
            </View>
              {props.tubes.map(tube => {
                console.log("tube", tube);
                return <View style={styles.col}><Text style={styles.font}>{tube[v.id]}</Text></View>
              })}
            
          </View>
      )
  })
  
    return (
        <Document>
            <Page size="A4" orientation={'landscape'} style={styles.page}>
              <View style={styles.center}>
                <View style={styles.title}>
                  <Text>diLuna Planung - {props.title}</Text>
                </View>
                <View style={styles.innerWrapper}>
                  {def}
                  <View style={styles.row}>
                    <Text style={styles.fontBold}>
                      Wie oft soll das Experiment wiederholt werden?</Text></View>
                  <View style={styles.row}>
                    <Text style={styles.font}>{props.repetitionC}</Text>
                    </View>
                  <View style={styles.row}>
                    <Text style={styles.fontBold}>Beschreibe, wie du die Wiederholungen umsetzt:</Text>
                    </View>
                  <View style={styles.row}>
                    <Text style={styles.font}>{props.repetitionD}</Text>
                  </View>
                </View>
              </View>
            </Page>
        </Document>
    )
}

export default PDFDocument
