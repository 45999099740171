import Modal from '../UI/Modal/Modal';

const Contact = props => (
    <>
        <h1>Kontakt</h1>
        <p>
            Fachhochschule Nordwestschweiz FHNW<br/>
            FHNW Campus Muttenz<br/>
            Hofackerstrasse 30<br/>
            4132 Muttenz<br/>
        </p><p>
            T: +41 61 228 55 55<br/>
            <a href="mailto:andreadenise.luescher@fhnw.ch">andreadenise.luescher@fhnw.ch</a>
        </p>
    </>
);

export default Contact;