
// combines an input Object with a new Objects
export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    }
}

// validation for forms
export const checkValidity = (value, rules, compare) => {
    let isValid = true;
    if (!rules) {
        return true;
    }
    
    if (rules.required) {
        isValid = value.trim() !== '' && isValid;
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if (rules.minValue) {
        isValid = parseInt(value) >= rules.minValue && isValid
    }

    if (rules.maxValue) {
        isValid = parseInt(value) <= rules.maxValue && isValid
    }

    if (rules.isEmail) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test(value) && isValid
    }

    if (rules.isSameAs) {
        isValid = value === compare;
        console.log("compared with: ", compare)
    }


    if (rules.isNumeric) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid
    }
    if (rules.isNotValue) {
        isValid = !(rules.isNotValue == value)
    }

    if (rules.isValue) {
        isValid = (rules.isValue == value)
    }

    if(rules.isToken) {
        // let string = "";
        // for(var i=1; i<=10; i ++) {
        //  let code = cyrb53(`SG00${i}21`);
        //  string += `${code}, // SG00${i}21\n`;
        // }
        isValid = rules.isToken.includes(cyrb53(value))
    }

    return isValid;
}

// Verschlüsselung 
export const cyrb53 = function(str, seed = 0) {
    let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1>>>16), 2246822507) ^ Math.imul(h2 ^ (h2>>>13), 3266489909);
    h2 = Math.imul(h2 ^ (h2>>>16), 2246822507) ^ Math.imul(h1 ^ (h1>>>13), 3266489909);
    return 4294967296 * (2097151 & h2) + (h1>>>0);
};

export const getFormData = (dataObj) => {
    let formData = new FormData();
    for (var key in dataObj) {
      formData.append(key, dataObj[key])
    }
    return formData;
}


export const prepSendData = (dataObj) => {
    let formData = new FormData();
    for (var key in dataObj) {
      formData.append(key, dataObj[key])
    }
    return formData;
}

export const convertPlanData = (planVars) => {
    let convertedPlanVars = [...planVars].map(pv => {
        return  {...pv}
    })
    
    console.log("CONVERT PLAN VARS:", planVars)
    
    let newOrder = [[],[],[]];
    
    for(let i=0; i< planVars.length; i++) {
        switch (convertedPlanVars[i].type) {
            case "dist":
                convertedPlanVars[i].type = 'Störvariable';
                break;
            case "undef":
                convertedPlanVars[i].type = '-';
                break;
            case "messure":
                convertedPlanVars[i].type = 'Messzeiten';
                break;
            case "abh":
                convertedPlanVars[i].type = 'abhängige Variable';
                break;
            case "unabh":
                convertedPlanVars[i].type = 'unabhängige Variable';
                break;
            default:
                break;
        }
    
        switch (convertedPlanVars[i].option) {
            case "enzymakt":
                convertedPlanVars[i].option =  "Enzymaktivität"
                break;
            case "fetts":
                convertedPlanVars[i].option = "Fettsäure"
                break;
            case "lipase":
                convertedPlanVars[i].option = "Lipaselösung"
                break;
            case "kondens":
                convertedPlanVars[i].option = "Kondensmilch"
                break;
            case "temp":
                convertedPlanVars[i].option = "Temperatur"
                break;
            case "glyc":
                convertedPlanVars[i].option = "Gylcerin"
                break;
            case "water":
                convertedPlanVars[i].option = "Wasser"
                break;
            case "start":
                convertedPlanVars[i].option = "Startpunkt der Messung"
                break;
            case "dauer":
                convertedPlanVars[i].option = "Dauer der Messung"
                break;
            case "interval":
                convertedPlanVars[i].option = "Intervalle der Messung"
                break;

            case "urease":
                convertedPlanVars[i].option = "Enzyme (Ureaselösung)"
                break;
            case "kohlenstoff":
                convertedPlanVars[i].option = "Kohlenstoffdioxid"
                break;
            case "harnstoff":
                convertedPlanVars[i].option = "Harnstofflösung"
                break;
            case "bromthymolblau":
                convertedPlanVars[i].option = "Bromthymolblau (pH-Indikator)"
                break;
            case "ammoniak":
                convertedPlanVars[i].option = "Aktivierungsenergie (Ammoniak)"
                break;
            case "stkonz":
                convertedPlanVars[i].option = "Stärkekonzentration"
                break;
            case "stloesung":
                convertedPlanVars[i].option = "Stärkelösung"
                break;
            case "hemmstoff":
                convertedPlanVars[i].option = "Hemmstoff (Arcabose)"
                break;
            case "amylase":
                convertedPlanVars[i].option = "Amylaselösung"
                break;
            case "lugol":
                convertedPlanVars[i].option = "Lugol'sche Lösung"
                break;
            case "maltose":
                convertedPlanVars[i].option = "Maltose"
                break;
            case "glucose":
                convertedPlanVars[i].option = "Glucose"
                break;
            case "phwertloesung":
                convertedPlanVars[i].option = "pH-Wert der Lösung"
                break;
            case "kartoffel":
                convertedPlanVars[i].option = "Kartoffelsaft (Katalase)"
                break;
            case "sauerstoff":
                convertedPlanVars[i].option = "Sauerstoff"
                break;
            case "wasserstoff":
                convertedPlanVars[i].option = "Wasserstoffperoxid-Lösung"
                break;
            default:
                break;
        }
        newOrder[convertedPlanVars[i].group].push({...convertedPlanVars[i]});
    }
    console.log("NEW ORDERD ARRAY", newOrder)
    return newOrder.flat();
    //return convertedPlanVars;
}


export const convertNames = (name) => {
    switch(name) {
        case "abh":
            return "Abhängige Variable"
        case "unabh":
            return "Unabhängige Variable"
        case "dist":
            return "Störvariable"
        case "messure":
            return "Messzeiten"
        case "repetition":
            return "Wiederholungen"
        case "hint":
            return "Hinweis"
        case "example":
            return "Beispiellösung"
        case "solution":
            return "Lösung"
        case "temperature":
            return "Temperatur"
        case "1":
            return "KG1"
        case "2":
            return "EG1"
        case "3":
            return "EG2"
        case "4":
            return "EG3"
        default:
            return name
    }
}

export const parseText = (txt) => {
    const newArray = []
    const ownHtml = [
        {start: "<div ", end: ">", placeholder: "##DIV##"},
        {start: "</div", end: ">", placeholder: "##/DIV##"},
        {start: "<img", end: ">", placeholder: "##IMG##"},
        {start: "<figcaption", end: ">", placeholder: "##Caption##"},
        {start: "</figcaption", end: ">", placeholder: "##/Caption##"},
    ]
    
    for(let i=0; i<ownHtml.length; i++) {
        const start = ownHtml[i].start
        const end = ownHtml[i].end
        //let placeholder = ownHtml[i].placeholder
        var reg = new RegExp(start,"g");
        let count = (txt.match(reg) || []).length
        console.log("NewArray", newArray)
        for(let i2=0; i2<count; i2++) {
            let iStart = txt.indexOf(start);
            if(iStart != -1) {
                let iEnd = txt.indexOf(end, iStart+1)
                const placeholder = ownHtml[i].placeholder.replace(new RegExp('##$'), i2+'##');
                newArray.push({
                    placeholder: "<p>"+placeholder+"</p>",
                    html: txt.substring(iStart, iEnd+1)});
                txt = txt.substring(0, iStart) + "<p>"+placeholder+"</p>" + txt.substring(iEnd+1);
            }
        }  
    }
    return {txt, newArray}
}

