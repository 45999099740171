import React, { useEffect, useState } from 'react'

import Moment from 'react-moment'
import classes from './PanelExercise.module.sass'
import api from '../../../../../axios-api'
import { getFormData, convertNames } from '../../../../../shared/utility'
import PlanningTable from '../../../../Exercises/Exercise/Planning/PlanningTable/PlanningTable'
import planingData from '../../../../../data/planningData.json'
import PanelHelpUsed from '../../PanelHelpSession/PanelHelpSession'


const PanelExercise = ({ex, clicked}) => {
    const [exMode, setExMode] = useState();
    const [exCorrect, setExCorrect] = useState(null)

    const exButtons = [
        {name: "Planung", value: "p"},
        {name: "Hilfen", value: "h"}
    ]
   
    const changeMode = (mode) => {
        setExMode(mode);
    }
    
    const planData = JSON.parse(ex.exUserPlanData)
    console.log(planData)
    return (
        <div key={ex.exID} 
            className={"row " + classes.RowExercise}
            onClick={clicked}>
            <div className={"row col-12 " + classes.Exercise}>
                <div className="col-2">
                    Aufgaben ID: {ex.exID}
                </div>
                <div className="col-2">
                    Typ: {convertNames(ex.exType)}  <br/><br/>
                </div>
                <div className="col-2">
                    <strong>Dauer:</strong> <Moment duration={ex.exStart}>{ex.exEnd}</Moment><br/>
                    <strong>Start:</strong> <Moment format={"H:m:s"}>{ex.exStart}</Moment><br />
                    <strong>End:</strong> <Moment format={"H:m:s"}>{ex.exEnd}</Moment>
                </div>
                <div className="col-4">
                    {exButtons.map(b => {
                        let buttonClass = [classes.LetterButton]
                        if(ex.exSuccess === 'ja' && b.value === 'p') { 
                            buttonClass.push(classes.Success)}
                        exMode === b.value && buttonClass.push(classes.Active)

                        return <button 
                            onClick={() => changeMode(b.value)} 
                            className={buttonClass.join(" ")}>
                                {b.name}</button>
                        }

                    )}
                </div>
            </div>
            <div className="col-12">
                {exMode === "h" && 
                    <PanelHelpUsed exID={ex.exID} />
                }
                {exMode === "s" && <><h2>Geöffnete Hilfen</h2>Kommt bald...</>}
                {exMode === "p" 
                    && (
                    planData
                        ?   <><h2>Planung</h2><PlanningTable 
                                planVariables={planData.planVariable}
                                planTubes={planData.planTubes}
                                planRepetitionDesc={planData.planRepetition.repetitionDesc}
                                planRepetitionCount={planData.planRepetition.repetitionCount}
                                displayOnly={true}
                                planingData={planingData}
                            /></>
                        : <h2>Keine Planung vorhanden</h2> 
                )}

            </div>   
        </div>
    )
}

export default PanelExercise
