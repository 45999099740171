import React, { useRef, useEffect } from 'react';

import classes from './Input.module.sass';

const Input = (props) => {
    let inputElement = null;
    const inputEl = useRef(null);
    const inputClasses = [classes.InputElement];
    const wrapperClasses = [classes.Input];

    useEffect(() => {
        if(!props.pasteable && inputEl.current) {
            inputEl.current.onpaste = e => e.preventDefault();
        }
    }, [inputEl.current]);

    if(props.invalid && props.shouldValidate && props.touched) {
        inputClasses.push(classes.Invalid);
    }
    if(props.hidden) {
        wrapperClasses.push(classes.Hidden);
    }

    switch (props.elementType) {
        case ('input'):
            inputElement = <input ref={inputEl}
                className={inputClasses.join(' ') + " form-control"} 
                {...props.elementConfig} 
                value={props.value} onChange={props.changed} />;
            break;
        case ('check'):
            inputElement = 
                <label className="checkbox_container" >
                    <span dangerouslySetInnerHTML={{__html: props.label}} />
                    <input ref={inputEl}
                    className={inputClasses.join(' ') +" " + classes.Check + " form-check-control"} 
                    {...props.elementConfig} 
                    value={props.value} 
                    onChange={props.changed}
                    id={props.for ? props.for : null}
                    />
                <span className="checkmark"></span>
            </label>

            
            break;
        case ('textarea'):
            inputElement = <><textarea 
                className={inputClasses.join(' ')+ " "+classes.Textarea+" form-control"} 
                {...props.elementConfig} 
                value={props.value} onChange={props.changed}/>
                {props.characterMinimum && 
                    <span className={classes.CharacterCounter  + " " + ((props.characterMinimum - props.characterCount <= 0) ? classes.Valid : classes.Invalid) }
                    >{props.characterCount} / {props.characterMinimum}+</span> 
                }
                </>;
            break;
        case ('select'):
            inputElement = (
                <select
                    className={classes.InputElement + " form-control"} 
                    {...props.elementConfig} 
                    value={props.value} onChange={props.changed}>
                    {props.elementConfig.options.map(option => (
                        <option 
                            key={option.value} 
                            value={option.value}>
                                {option.displayValue}
                        </option>
                    ))}
                </select>);
            break;
        default:
            inputElement = <input ref={inputEl}
                className={classes.InputElement + " form-control"} 
                {...props.elementConfig}
                value={props.value}/>;    
    }
    return (
        <div className={wrapperClasses.join(" ")}>
            {/* {props.label &&
                <label htmlFor={props.for ? props.for: null} className={classes.Label}>
                    <span dangerouslySetInnerHTML={{__html: props.label}} />
                    {inputElement}
                </label>
            } */}
            {inputElement}
            {/* {!props.label && inputElement } */}
        </div>
    )
}
    
export default Input;