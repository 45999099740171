import React, {useState, useEffect} from 'react'
import fb from '../../../axios-fb'
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {DiCodeBadge} from 'react-icons/di';
import {BiSave} from 'react-icons/bi';
import {parseText} from '../../../shared/utility';
import ImageUpload from '../ImageUpload/ImageUpload';
import ImgToChange from'../ImageUpload/ImgToChange';

const TextBlockUpdate = ({type, node, text}) => {
    const [textData, setTextData] = useState("")
    const [replaceArr, setReplaceArr] = useState([])
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [showHtml, setShowHtml] = useState(false)
    const [textChanged, setTextChanged] = useState(false)
    
    useEffect(() => {
        setTextData(text)  
        //const {parsedText, parsedArray} = parseText(text) 
        const contentBlock = htmlToDraft(parseText(text));

        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState)
        }
    }, []);

    const handleTextChange = (e) => {
        setTextData(e.target.value)
    }

    const saveData = () => {    
        let saveText = reverseParseText(draftToHtml(convertToRaw(editorState.getCurrentContent())))
        saveText = saveText.replace(/\n/g, '')
        //fb.patch(node+".json", {text: saveText})
        console.log("PUT NODE: ", node);
        //fb.patch(node+".json", saveText)
        fb.patch(node+".json", {[type]: saveText})
            .then(response => {
                setTextChanged(false)
            }).catch(err => console.log("error saving text", err))
    }

    const parseText = (txt) => {
        const ownHtml = [
            {start: "<div ", end: ">", placeholder: "##DIV##"},
            {start: "</div", end: ">", placeholder: "##/DIV##"},
            {start: "<img", end: ">", placeholder: "##IMG##"},
            {start: "<figcaption", end: ">", placeholder: "##Caption##"},
            {start: "</figcaption", end: ">", placeholder: "##/Caption##"},
        ]

        const newArray = []
        for(let i=0; i<ownHtml.length; i++) {
            const start = ownHtml[i].start
            const end = ownHtml[i].end
            //let placeholder = ownHtml[i].placeholder
            var reg = new RegExp(start,"g");
            let count = (txt.match(reg) || []).length
            // console.log("NewArray", newArray)
            for(let i2=0; i2<count; i2++) {
                let iStart = txt.indexOf(start);
                if(iStart != -1) {
                    let iEnd = txt.indexOf(end, iStart+1)
                    const placeholder = ownHtml[i].placeholder.replace(new RegExp('##$'), i2+'##');
                    newArray.push({
                        placeholder: "<p>"+placeholder+"</p>",
                        html: txt.substring(iStart, iEnd+1)});
                    txt = txt.substring(0, iStart) + "<p>"+placeholder+"</p>" + txt.substring(iEnd+1);
                    
                   // console.log("NEW TEXT:", txt)
                }
            }  
        }
        setReplaceArr(newArray)
        return txt
    }

    const reverseParseText = (txt) => {
        for(let i=0; i<replaceArr.length; i++) {
            txt = txt.replace(replaceArr[i].placeholder, replaceArr[i].html)
        }
        return txt
    }

    const onEditorStateChange = (editorState) => {
        setTextChanged(true)
        setEditorState(editorState)
    };

    const onShowHtml = () => {
        setTextData(reverseParseText(draftToHtml(convertToRaw(editorState.getCurrentContent()))))
        setShowHtml(!showHtml)
    }

    return (        
                <div className="ml-4">
                    <div>
                        <strong>{type}:</strong> Angezeigter Text: <DiCodeBadge onClick={onShowHtml}/>
                        {showHtml && <textarea 
                            className="form-control" style={{height: '150px'}}  
                            onChange={handleTextChange} 
                            value={textData}>
                        </textarea>}
                        <Editor
                            //toolbarHidden
                            editorState={editorState}
                            toolbar= {{
                                options: [
                                    'inline', 'fontSize', 'colorPicker', 'list', 'link', 'history'
                                ]
                            }}
                            toolbarClassName="editorToolbar"
                            wrapperClassName="editorWrapper"
                            editorClassName="editorClass"
                            onEditorStateChange={onEditorStateChange}
                        />
                    </div>
                    
                    <button 
                        className="btn btn-secondary btn-sm "  
                        onClick={saveData}>
                        {textChanged && <BiSave/> } Save
                    </button>
            </div>
    )
}

export default TextBlockUpdate
