import React, { useEffect, useState } from 'react';
import { Redirect, useRouteMatch, useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import { RiArrowGoBackLine } from 'react-icons/ri';
import { MdEmail } from 'react-icons/md';
import PDFDownloadButton from '../../../components/PDFDocument/PDFDownloadButton/PDFDownloadButton'
import { convertPlanData, getFormData } from '../../../shared/utility'
import api from '../../../axios-api'
import classes from './Exercise.module.sass';
import Pager from './Pager/Pager';
import Steps from './Steps/Steps';

import {c_temperature, c_phvalue, c_activation_energy, c_inhibition} from '../../../shared/validatePlanning';

import HelpManager from '../../Exercises/HelpManager/HelpManager';
import HelpReview from '../../Exercises/HelpManager/HelpReview/HelpReview';
import Planning from './Planning/Planning';

import Modal from '../../../components/UI/Modal/Modal';
import Spinner from '../../../components/UI/Spinner/Spinner';
import ExerciseStep from './ExerciseStep/ExerciseStep';

import useFb from '../../../hooks/use-fb';
import { closeAllHelp } from '../../../shared/api-calls';
import ExQuestions from '../../Questions/ExQuestions/ExQuestions';

import TopicInfoReader from '../TopicInfo/TopicInfoRead';
import success from '../../../assets/icons/success.jpg';
import fail from '../../../assets/icons/fail.jpg';


const Exercise = (props) => { 
    const [nextActive, setNextActive] = useState(true);
    const [prevActive, setPrevActive] = useState(false);
    const [pagerActive, setPagerActive] = useState(true);
    const [modalCompletePlaning, setModalCompletePlaning] = useState(false);
    const [modalCompleteExercise, setModalCompleteExercise] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showPlaningInfo, setShowPlaningInfo] = useState(false);
    const [helpManagerActive, setHelpManagerActive] = useState(false);
    const [helpReview, setHelpReview] = useState(false);
    const [reviewOn, setReviewOn] = useState('exercise');
    const [currSubstep, setCurrSubstep] = useState(1);
    const [substepCount, setSubstepCount] = useState(1);
    const [exQ, setExQ] = useState(false);
    const [helpUsed, setHelpUsed] = useState(false);
    const [deactivatedSteps, setDeactivatedSteps] = useState([]);
    const [finalStep, setFinalStep] = useState(false);
    const [exContent, setExContent] = useState(null);
    const {loader, error, sendRequest} = useFb()
    const [mailingStatus, setMailingStatus] = useState(false);
    
    const history = useHistory();
    const { url } = useRouteMatch();
    
    useEffect(() => {
        props.planingCompleted 
            ? setDeactivatedSteps(['01_intro', '05_planing'])
            : setDeactivatedSteps(['01_intro'])
        
        const currExId = localStorage.getItem('exID');
        if (currExId && !props.exSelected) {
            props.onExLoadActiveEx(currExId)
        }


        if(!props.exSelected || !props.exPos ) {
            return <Redirect to="/" />
        }

        sendRequest(`/exerciseData/exercises/${props.exPos-1}.json`, setExContent);
        props.onChangeStep('01_intro')
        localStorage.getItem('exStep') === '05_planing' && closeAllHelp(localStorage.getItem('exID'))

    }, [props.onChangeStep]);
  
    useEffect(() => {
        setDeactivatedSteps([])
    }, [props.exPos]);

    useEffect(() => {
        exContent && checkPrevNext(localStorage.getItem('exStep'));
    }, [exContent]);

    if(!props.exSelected ) {
        return <Redirect to="/" />
    }

    const sendMailPlanning = () => {        
        const rawPlanData = {
            planVariable: convertPlanData(props.planVariables), 
            planTubes: props.planTubes,
            planRepetition: {
                repetitionCount: props.planRepetitionCount,
                repetitionDesc: props.planRepetitionDesc
            }
        }
        let userInputData = JSON.stringify(rawPlanData)
        const email = localStorage.getItem('email');
        const data = {userEmail: email, planData: userInputData}
        let dataToSend = getFormData(data);
        api.post('/api/send_planning_mail.php', dataToSend)
            .then(response => {
                if(response.data) {
                    console.log("Mail Sent")
                    setMailingStatus(true);
                    setTimeout(() => {
                        setMailingStatus(false);
                    }, 1000)
                } else {
                    console.log("Mail not sent")
                }
            }).catch(err => {
                console.log("Error when sending mail over api", err)
            })
    }

    const changeVisitedPage = (step) => {
        props.onChangeStep(step);
        setSubstepCount(exContent.steps[step].substeps.length);
        setCurrSubstep(1);
        setHelpManagerActive(false);
        checkPrevNext(step);

        // save current step to DB
        const token = localStorage.getItem('token');
        const data = {userID: token, exStep: step}
        let dataToSend = getFormData(data);
        api.post("/api/change_ex_step.php", dataToSend)
            .then(response => {
                console.log('changed EX Step to:', response.data);
                localStorage.setItem('exStep', step);
            }).catch(error => {
                console.log("error when changing Ex step", error);
            });
    }        

    const checkPrevNext = (step) => {
        const stepValues = Object.keys(exContent.steps); // all steps
        const pos = stepValues.indexOf(step);
     
        if (deactivatedSteps.includes(stepValues[pos-1]) ) {
            setPrevActive(false)
            setNextActive(true)
            setFinalStep(false)
        } else if (deactivatedSteps.includes(stepValues[pos+1])) {
            setNextActive(false)
            setPrevActive(true)
            setFinalStep(false)
        }
        if(!deactivatedSteps.includes(stepValues[pos-1]) 
            && !deactivatedSteps.includes(stepValues[pos+1])) {
            setNextActive(true)
            setPrevActive(true)
            setFinalStep(false)
        }
        if(pos+1 === stepValues.length) {
            setNextActive(false)
            setPrevActive(true)
            setFinalStep(true)
        } 
        if(pos===0) {
            setNextActive(true)
            setFinalStep(false)
            setPrevActive(false)
        }
    }

    const goToNextPage = (anyway=false) => {
        
        if(props.activeStep === "05_planing" && !anyway) {
            setModalCompletePlaning(true)
        } else {
            if(substepCount === currSubstep) {
                const stepValues = Object.keys(exContent.steps); // all steps
                const pos = stepValues.indexOf(props.activeStep);
                let nextStep = props.activeStep;

                nextStep = stepValues[pos+1];
                changeVisitedPage(nextStep);
                history.push(url+"/"+nextStep)
            } else {
                setCurrSubstep(currSubstep+1)
            }
        }
    }

    const goToPrevPage = () => {
        if(currSubstep <= 1) {
            const stepValues = Object.keys(exContent.steps); // all steps
            const pos = stepValues.indexOf(props.activeStep);
            let nextStep = props.activeStep;
            nextStep = stepValues[pos-1]
            changeVisitedPage(nextStep);
            history.push(url+"/"+nextStep)
        } else {
            setCurrSubstep(currSubstep-1)
        }
    }

    const onHidePlanInfos = () => {
        setShowPlaningInfo(false);
        props.onPlanFirstVisit(true);
        props.onRemoveVar('sample');
    }

    const onShowHelpManager = () => {
        setHelpManagerActive(true)
        setHelpUsed(true)
        if(localStorage.getItem('userTracking') === '1') {
            // open new help session and save timestamp
            const token = localStorage.getItem('token');
            const data = {userID: token}
            let dataToSend = getFormData(data);
            api.post("/api/open_help.php", dataToSend)
                .then(response => {
                    console.log('new Help session added', response.data);
                }).catch(error => {
                    console.log("error when open new help session", error);
                });
        }
    }

    const onCloseHelpManager = () => {
        setHelpManagerActive(false)
    }

    const checkHelpUsed = () => {   
        if(localStorage.getItem('userTracking') === '1') { 
            const data = {
                table: 'TUserExercises', 
                conditionField: 'exID',
                condition: localStorage.getItem('exID')
            }
            let dataToSend = getFormData(data);
            api.post('/api/fetch_data.php', dataToSend)
                .then(response => {
                    if(response.data) {
                        if(response.data[0].exHelpUsed > 0) {
                            setReviewOn('exercise')
                            setHelpReview(true)
                            setModalCompletePlaning(false);
                        } else {
                            setReviewOn('notUsed')
                            setHelpReview(true)
                            setModalCompletePlaning(false);
                        } 
                    } else {
                        console.log("No field found with " + data.condition)
                    }
                }).catch(err => {
                    console.log("Error when checking if HELP WAS USED over fetch_data.php", err)
            })
        } else {
            // Tracking is not active -> directly complete planing
            completePlaning()
        }
    }

    const completePlaning = () => {
        // validate planing
        setHelpReview(false)
        const planSuccess = validatePlaning()        
        const userInputData = {
            planVariable: props.planVariables, 
            planTubes: props.planTubes,
            planRepetition: {
                repetitionCount: props.planRepetitionCount,
                repetitionDesc: props.planRepetitionDesc
            }
            
        }
        props.onCompletePlaning(props.exSelected, planSuccess, userInputData);
        closeAllHelp(localStorage.getItem('exID')) // close all helps that aren't closed by the user 
        setModalCompletePlaning(false);
        goToNextPage(true);
        setDeactivatedSteps(['01_intro', '02_phenomenon', '03_question', '04_hypotheses', '05_planing']);
        setPrevActive(false)
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1500)
    }

    const validatePlaning = () => {
        
        switch(props.exSelected) {
            case "temperature":
                return c_temperature(props.planVariables, props.planTubes);
            case "phvalue":
                return c_phvalue(props.planVariables, props.planTubes);
            case "activation_energy":
                return c_activation_energy(props.planVariables, props.planTubes);
            case "inhibition":
                return c_inhibition(props.planVariables, props.planTubes);
            default:
                break;
        }
    }

    const completeExercise = (event) => {
        props.onNextExCount(); // set next step so next ex is enabled
        props.onCompleteExercise(props.exSelected, props.exAdditionalInput);
        console.log("ex selvetd:", props.exSelected)
        //props.onResetPlanData(props.exSelected === 'phvalue' ? 2 : 1);
        localStorage.removeItem('exStep');
        localStorage.removeItem('exID');
        history.replace("/exercises");
    }

    //!!! const exContent = props.exAll.filter(exEl => exEl.type === props.exSelected)[0];
    
    let exerciseSteps = null
    if(exContent) {
        exerciseSteps = (<ExerciseStep 
            setNextActive={setNextActive}
            clickFunction={props.activeStep === '05_planing' ? onShowHelpManager : null}
            pagerStatus={(status) => setPagerActive(status)}
            stepContent={exContent.steps[props.activeStep ? props.activeStep : '01_intro' ]} 
            ex={props.exSelected}
            additionalInput={props.exAdditionalInput}
            changeAdditionalInput={(e, name) => props.onChangeAdditionalInput(e, name)}
            exSubstep={exContent.steps[props.activeStep ? props.activeStep : '01_intro' ].substeps[currSubstep-1]}
            exSampleSolution={exContent.sampleSolution}
            exUserPlanData={{planVariables: props.planVariables, planTubes: props.planTubes, planRepetitionCount: props.planRepetitionCount, planRepetitionDesc: props.planRepetitionDesc}}
            />)
    }

    let exerciseAdditionalDom = null;
    switch (props.activeStep) {
        case '05_planing':
            exerciseAdditionalDom =  ( 
                <Planning 
                    showPlanInfo={showPlaningInfo}
                    exTitle={exContent?.title?.replace('&shy;', '')}
                    helpManager={helpManagerActive}
                    onShowHelpManager={onShowHelpManager}
                    onShowPlanInfo={() => setShowPlaningInfo(true)}
                    onHidePlanInfo={onHidePlanInfos} 
                />
            )
            break;
        default: exerciseAdditionalDom = null;  break;
    }
   
    let modalAndInfo = (
        <>
            <Modal show={helpReview} width='70%' modalClosed={() => null}>
            {localStorage.getItem('userAuthCode').includes("debug") && 
                    <div>dev mode: change to<br/>
                        <button onClick={() => setReviewOn('notUsed')}>help not used</button>
                        <button onClick={() => setReviewOn('exercise')}>help used</button>
                        <hr />
                    </div>
            }
            <HelpReview 
                    reviewOn={reviewOn}
                    presetMode={!helpUsed && 'no'}
                    closeReviewEndPlaning={() => completePlaning()}
                    closeReview={() => setHelpReview(false)} 
                />
            </Modal> 
            <Modal show={props.topicInfoVisible} width='80%' modalClosed={() => props.onShowTopicInfo(false)}>
               {exContent && <TopicInfoReader topicInfo={exContent.topicInformation} type={exContent.type} /> }
               <hr />
               <button onClick={() => props.onShowTopicInfo(false)} className="float-right btn btn-secondary m-2 ">schliessen</button>
            </Modal> 
            {helpManagerActive && props.activeStep === "05_planing"  &&
                <HelpManager 
                    changeReviewOn={(where) => setReviewOn(where)}
                    displayHelpReview={() => setHelpReview(true)}
                    onCloseHelpManager={onCloseHelpManager} 
                    exercise={props.exSelected} 
                    exPos={props.exPos}
                    helpTutorial={props.helpTutorial}
                    onCloseHelpTutorial={props.onCloseHelpTutorial}
                    tracking={localStorage.getItem('userTracking') === '1'}
                    />}
                                            
            {loading && <Spinner />}
            {(props.activeStep === "06_test" && !loading) && 
                [props.exSuccess ? <div className={classes.SuccessMsg}>Die Planung dieses Experiments ist dir gelungen.<img alt="Die Planung war korrekt." src={success} /></div>
                : <div className={classes.FailMsg}>Die Planung dieses Experiments ist dir leider nicht gelungen. <img alt="Die Planung war leider nicht korrekt." src={fail} /></div>]}
        </>
    )

    return (
        <div>            
            {exContent && ( 
                <>
                <h3 dangerouslySetInnerHTML={{__html: exContent.title }}></h3>
                <h5>{exContent.subTitle ? exContent.subTitle : "Aufgabe" }</h5>
                <span className={classes.Backlink} onClick={()=>history.push("/")}><RiArrowGoBackLine style={{fontSize: '30px'}} /></span>
                <Steps 
                    steps={exContent.steps} 
                    helpActive={helpManagerActive} // !!! 
                    deactivatedSteps={deactivatedSteps} 
                    visitedSteps={props.exVisitedSteps} 
                    click={changeVisitedPage} 
                    infoClicked={() => setShowPlaningInfo(true)} />
                <div className={classes.ExerciseBody}>
                    {!helpManagerActive && exerciseSteps}
                    {modalAndInfo}
                    {exerciseAdditionalDom}
                </div>
                </>)}
             
           
           <Pager 
                pagerActive={pagerActive}
                textNext={!finalStep ? "Weiter" : "Aufgabe abschliessen"}
                textPrev={"Zurück"}
                nextPage={!finalStep ? () => goToNextPage() : () => setModalCompleteExercise(true)} 
                prevPage={()=>goToPrevPage()}
                nextActive={nextActive}
                prevActive={prevActive} />
            {modalCompletePlaning && 
                <Modal show={modalCompletePlaning} modalClosed={()=>setModalCompletePlaning(false)}>
                    <div>
                        <p>Ist deine Planung komplett? Sobald du «Planung abschliessen» klickst, kannst du die Planung nicht mehr ändern.</p>
                        <button onClick={()=>setModalCompletePlaning(false)} className="btn btn-secondary m-2 ">
                            Planung nochmals überprüfen</button>
                        <button onClick={()=>checkHelpUsed()} className="btn btn-secondary m-2 ">Planung abschliessen</button>
                    </div>
                </Modal>
            }
            {modalCompleteExercise && 
                <Modal 
                    show={modalCompleteExercise} width="80%" 
                    modalClosed={!exQ ? () => setModalCompleteExercise(false) : null}>
                    <div>
                        {!exQ ?
                            <><h4>Aufgabe abgeschlossen</h4>
                            <p>Juhu, die {props.exPos===1 ? 'erste' : props.exPos===2 ? 'zweite' : props.exPos===3 ? 'dritte' : props.exPos===4 ? 'vierte' : '' } Experimentieraufgabe hast du nun abgeschlossen.</p>
                            <p>Du kannst dir deine Planung herunterladen oder per E-Mail zusenden.</p>
                            <PDFDownloadButton 
                                documentName={'diLuna_Planung_' + localStorage.getItem('exType')+'.pdf'}
                                title={exContent.title}
                                planVariables={props.planVariables}
                                planTubes={props.planTubes}
                                planRepetitionCount={props.planRepetitionCount}
                                planRepetitionDesc={props.planRepetitionDesc}
                            />
                            <MdEmail 
                                className={classes.MailButton + ' ' + (mailingStatus && classes.Saving)} 
                                onClick={sendMailPlanning} />
                            <button 
                                onClick={
                                    localStorage.getItem('userTracking') === '1' ?
                                    () => setExQ(true) : completeExercise} 
                                className="btn btn-secondary float-right m-3">
                                    Weiter
                                </button>
                            </>
                        :
                            <ExQuestions closeTest={()=>setExQ(false)} exPos={props.exPos-1} mode={'emb'} onFinish={completeExercise} />
                        }
                    </div>
                </Modal>
            }
             {/* debug planing  */ 
             localStorage.getItem('userAuthCode').includes("debug") && 
                <button onClick={validatePlaning}>Dev: VALIDATE PLANNING</button>
            }
            { /* Debugging */ 
                localStorage.getItem('userAuthCode').includes("debug") && 
                    <button onClick={completeExercise}>DEV: END EXERCISE</button>
            }
        </div>
    )
}

// -> redux
const mapStateToProps = state => {
    return {
        exSelected: state.exercise.exSelected,
        exPos: state.exercise.exPos,
        activeStep: state.exercise.exStep,
        exVisitedSteps: state.exercise.exStepsVisited,
        exAdditionalInput: state.exercise.exAdditionalInput,
        planingCompleted: state.exercise.exPlaningCompletet,
        exCompleted: state.exercise.exCompleted,
        planVariables: state.planning.planVariables,
        planTubes: state.planning.tubes,
        planRepetitionCount: state.planning.repetitionCount,
        planRepetitionDesc: state.planning.repetitionDesc,
        exSuccess: state.exercise.exSuccess,
        helpTutorial: state.planning.helpTutorial,
        topicInfoVisible: state.exercise.topicInfoVisible
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowTopicInfo: (status) => dispatch(actions.showTopicInfo(status)),
        onExLoadActiveEx: (exId) => dispatch(actions.loadActiveExercise(exId)),
        onChangeStep: (step) => dispatch(actions.changeExerciseStep(step)),
        onChangeResult: (event) => dispatch(actions.changeResult(event)),
        onChangeInterpretation: (event) => dispatch(actions.changeInterpretation(event)),
        onChangeExplenation: (event) => dispatch(actions.changeExplenation(event)),
        onChangeAdditionalInput: (e, name) => dispatch(actions.changeAdditionalInput(e, name)),
        onCompletePlaning: (ex, success, input) => dispatch(actions.completePlaning(ex, success, input)),
        onCompleteExercise: (ex, addiInput) => dispatch(actions.completeExercise(ex, addiInput)),
        onPlanFirstVisit: () => dispatch(actions.planFirstVisit()),
        onCloseHelpTutorial: () => dispatch(actions.closeHelpTutorial()),
        onRemoveVar: (id) => dispatch(actions.removeVar(id)),
        onResetPlanData: (template) => dispatch(actions.resetPlanData(template)) 
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Exercise);
