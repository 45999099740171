import React from 'react';
import classes from './RangeSlider.module.sass';

const RangeSlider = (props) => {
    return (
        <div className={classes.RangeSlider}>
            <input type="range" 
                className={"custom-range " + (props.moved && "moved")}
                min={props.min} 
                max={props.max} 
                value={props.value}
                onChange={props.change}
                step={props.steps ? props.steps : 1} 
                />
            <div className={classes.LabelNote}>
                <span>überhaupt nicht {props.desc}</span>
                <span>sehr {props.desc}</span>
            </div>
            <hr />
        </div>
    )
}

export default RangeSlider
