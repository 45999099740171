import { NavLink, useRouteMatch } from "react-router-dom";

import classes from './Steps.module.sass';

const Steps = (props) => {
    const { url } = useRouteMatch();
    return (
        <>
        <div className={classes.Steps}>
            {
                Object.keys(props.steps).map((key) => {
                    const step = props.steps[key]
                    return (
                        <NavLink 
                            key={key} 
                            className={(!props.visitedSteps.hasOwnProperty(key) || props.deactivatedSteps.includes(key) || props.helpActive ? classes.Disabled : "") + 
                            " btn btn-primary btn-sm m-1 " + classes.Step}
                            to={url + "/" + key}
                            onClick={() => props.click(key)}
                            activeClassName={classes.InfoButtonActive + " active"}
                            >
                            {step.title}
                        </NavLink>)
                })
            }
            {props.helpActive && <span className={classes.StepsWarning}>Bitte zuerst die Hilfe schliessen.</span>}
        </div>
        
        </>
    )
}

export default Steps;