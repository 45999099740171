import Modal from '../UI/Modal/Modal'
import Slideshow from '../UI/Slideshow/Slideshow'

const slides = [
    "files/about/Einblick_in_die_Experimentieraufgabe2.jpg",
    "files/about/Einblick_in_die_Experimentieraufgabe3.jpg",
    "files/about/Einblick_in_die_Experimentieraufgabe4.jpg",
    "files/about/Einblick_in_die_Experimentieraufgabe5.jpg",
]

const Infos = params => (
    <>
        <h1>Studienteilnehmerinnen und -teilnehmer gesucht</h1>
        <p>
        Unterrichten Sie Schulklassen Ende Sek I / Anfang Sek II? Dann habe ich ein spannendes Projekt für Sie. <br />Mehr Infos hier: <a href="files/about/Einblick_in_die_digitale_Experimentieraufgabe.pdf">Einblick in das Projekt</a>
        </p>
        <Slideshow
            slides={slides}
            textPrev="<"
            textNext=">"
        />
        <div className="row">
            <div className="col-5">
                <img width="100%" src="files/about/Webauftritt_Akquise_small.jpg" />
                <figcaption>Andrea Lüscher</figcaption>
            </div>
            <div className="col-7">
                <p>
                    Habe ich Ihr Interesse geweckt? Ich freue mich auf Ihre Kontaktaufnahme:<br/>
                    <a href="mailto:andreadenise.luescher@fhnw.ch">andreadenise.luescher@fhnw.ch</a> oder 061 228 53 43
                </p>
            </div>
        </div>
    </>
)

export default Infos;